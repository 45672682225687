<template>
  <a-modal
    :open="showModal"
    :title="null"
    :footer="null"
    :closable="false"
    width="100%"
    destroy-on-close
    wrap-class-name="full-modal"
    class="edit-timings-modal"
    :bodyStyle="{ display: 'flex', padding: 0, flexDirection: 'column' }"
  >
    <EditStepCycle @close="handleSaveSegments" :videoUrl="masterCycleFileUrl" />
  </a-modal>

  <div class="load-center" v-if="loading && !isDemoOngoing">
    <a-spin size="large" />
  </div>

  <a-row v-else-if="isTimeStudyFailed">
    <a-col span="24" class="loading" style="gap: 0px; align-items: start">
      <a-typography-title class="mt-2 text-center" :level="4">
        Our AI models couldn't process this video. Please contact support with the Study ID.
      </a-typography-title>
      <div style="width: 100%" className="d-flex justify-content-center align-items-center mt-3">
        <a-button @click="handleCloseModal" type="primary" size="medium" className="mx-auto">
          <span>Close</span>
        </a-button>
      </div>
    </a-col>
  </a-row>

  <a-row class="w-100" v-else-if="!loading && areProcessesRunning">
    <a-col
      span="24"
      class="loading"
      style="gap: 0px; align-items: start"
      v-if="areProcessesRunning"
    >
      <a-typography-title class="mt-2" :level="4">
        Hang Tight! We are analyzing your video(s).
      </a-typography-title>

      <span class="mt-4"></span>

      <div class="d-flex flex-column" style="gap: 2em">
        <div v-for="status in progressStatuses" :key="status.title" class="progress-item">
          <a-progress type="circle" :percent="parseInt(status.progress)" :size="50" />
          <a-typography-title
            :level="5"
            class="d-flex align-items-center"
            style="text-wrap: nowrap; margin-left: 1em"
          >
            {{ status.title }}
          </a-typography-title>
        </div>
      </div>
    </a-col>
  </a-row>

  <a-row v-else id="sd-adjust-screen" class="w-100">
    <a-col span="6" class="d-flex flex-column py-2">
      <a-list
        bordered
        :data-source="!isDemoOngoing ? steps : stationDesignDummyObject.stepsAnalysis"
        size="large"
        class="master-steps-list mx-3"
      >
        <template #header>
          <span class="list-header py-3">Step Analysis</span>
        </template>
        <template #renderItem="{ item, index }">
          <a-list-item
            class="step-item m-2"
            :class="{
              disable: currentStep === index,
              muted: currentStep === index && isUpdatingTherbligAssociation
            }"
            @click="handleClickStep(index)"
          >
            <a-list-item-meta>
              <template #title>
                <a-typography-text
                  class="w-100 station-design-list-item-header"
                  :class="{
                    disable: currentStep === index,
                    muted: currentStep === index && isUpdatingTherbligAssociation
                  }"
                >
                  {{ item.name ? item.name : `Step ${index + 1}` }}
                  <small class="d-flex w-100">{{ getStepsMeta(item.step_index) }}</small>
                </a-typography-text>
              </template>
              <template #description>
                <a-space direction="vertical" v-if="item.step_action_association.length">
                  <span class="station-design-list-item-header">
                    {{
                      !isDemoOngoing
                        ? getValueAssessment(item.step_action_association, item.step_index)
                        : 'Value-add'
                    }}
                    :
                    <span>({{ getStepActions(item.step_action_association) }})</span>
                  </span>
                </a-space>
              </template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>
    </a-col>

    <a-col span="18" class="d-flex flex-column py-2">
      <div class="suggestions-container">
        <div class="step-container">
          <div class="step-info-container mb-1 mx-1">
            <div>
              <a-typography-text v-if="!isDemoOngoing" strong>
                Step {{ steps[currentStep]?.step_index + 1 }} &nbsp;&nbsp;
              </a-typography-text>
              <a-typography-text v-else strong>
                Step
                {{ stationDesignDummyObject.stepsAnalysis[currentStep]?.step_index + 1 }}
              </a-typography-text>

              <a-input
                size="large"
                placeholder="Enter step name"
                v-if="isRenaming"
                v-model:value="stepName"
                :loading="true"
                @pressEnter="handleRenameStepName"
              >
                <template #suffix>
                  <a-spin size="small" v-if="renameLoading" />
                  <a-tooltip v-else title="Rename">
                    <EnterOutlined class="cursor-pointer" @click="handleRenameStepName" />
                  </a-tooltip>
                </template>
              </a-input>

              <a-typography-title v-else :level="3" class="m-0">
                {{
                  (isDemoOngoing
                    ? stationDesignDummyObject.stepsAnalysis[currentStep]?.name
                    : steps[currentStep]?.name) || 'Untitled'
                }}
              </a-typography-title>

              <a-space class="step-operations mt-4">
                <a-button @click="handleClickPrevious" :disabled="currentStep <= 0">
                  <template #icon><BackwardOutlined /></template>
                  Previous
                </a-button>
                <a-button @click="handleClickRename" :disabled="!steps[currentStep]?.name">
                  <template #icon><EditOutlined /></template>
                  Rename
                </a-button>
                <a-button
                  @click="showModal = true"
                  :disabled="renameLoading || suggestionProcessInProgress"
                >
                  <template #icon><ClockCircleOutlined /></template>
                  Edit Step Timing
                </a-button>
                <a-button @click="handleClickNext" :disabled="currentStep === steps.length - 1">
                  <template #icon><ForwardOutlined /></template>
                  Next
                </a-button>
              </a-space>
            </div>
            <a-typography-text strong class="d-block text-left my-3">
              Which motion elements are present in this steps?
            </a-typography-text>

            <a-space class="step-actions-wrapper">
              <a-checkbox-group
                :disabled="isDemoOngoing"
                ref="checkboxes"
                :value="selectedActions"
                name="checkedActions"
                @change="handleSelectAction"
              >
                <a-checkbox v-for="action in actions" :key="action.value" :value="action.value">
                  <a-tooltip>
                    <template #title>
                      <span>{{ actionNameToDescMap[action.label] }}</span>
                    </template>
                    <span>{{ action.label }}</span>
                  </a-tooltip>
                </a-checkbox>
              </a-checkbox-group>
            </a-space>

            <a-radio-group
              :value="selectedStepValueType"
              :loading="isUpdatingTherbligAssociation"
              class="my-3"
              button-style="solid"
              @change="handleValueAssessmentChange"
            >
              <a-radio-button :value="valueAssessment.VA" :disabled="isUpdatingTherbligAssociation"
                >Value-add</a-radio-button
              >
              <a-radio-button :value="valueAssessment.NVA" :disabled="isUpdatingTherbligAssociation"
                >Non value-add</a-radio-button
              >
              <a-radio-button
                :value="valueAssessment.NNVA"
                :disabled="isUpdatingTherbligAssociation"
                >Necessary non-value add</a-radio-button
              >
            </a-radio-group>

            <a-typography-text v-if="steps.length" strong class="d-block text-left mt-2">
              Parts consumed in this step (optional; press enter after each part)
            </a-typography-text>
            <div class="tag-input" v-if="steps.length">
              <span
                v-for="part in steps[this.currentStep]?.parts"
                :key="part"
                class="tag-input__tag"
              >
                <span class="tag_close_icon" @click="removeParts(part.id)"><b>x</b></span>
                {{ part.name }}
              </span>
              <input
                v-model="newPart"
                type="text"
                placeholder="Enter parts assembled"
                class="tag-input__text"
                name="parts"
                :style="{ pointerEvents: loadingParts ? 'none' : 'initial' }"
                @keydown="onAddPartsAndTools"
              />
              <a-spin size="small" v-if="loadingParts" />
              <a-tooltip placement="bottom" v-else title="Press Enter to add part.">
                <EnterOutlined />
              </a-tooltip>
            </div>

            <a-typography-text v-if="steps.length" strong class="d-block text-left mt-2">
              Tools used in this step (optional; press enter after each tool)
            </a-typography-text>
            <div class="tag-input" v-if="steps.length">
              <span
                v-for="tool in steps[this.currentStep]?.tools"
                :key="tool"
                class="tag-input__tag"
              >
                <span class="tag_close_icon" @click="removeTools(tool.id)"><b>x</b></span>
                {{ tool.name }}
              </span>

              <input
                v-model="newTool"
                type="text"
                placeholder="Enter tools"
                class="tag-input__text"
                name="tools"
                :style="{ pointerEvents: loadingTools ? 'none' : 'initial' }"
                @keydown="onAddPartsAndTools"
              />
              <a-spin size="small" v-if="loadingTools" />
              <a-tooltip placement="bottom" v-else title="Press Enter to add tool.">
                <EnterOutlined />
              </a-tooltip>
            </div>

            <a-typography-text v-if="steps.length" strong class="d-block text-left mt-2">
              Quality self-check (optional; press enter at the end)
            </a-typography-text>
            <div class="textarea-container" v-if="steps.length">
              <a-textarea
                v-if="steps[currentStep]"
                v-model:value="steps[currentStep].description"
                placeholder="Step Description"
                :rows="2"
                :disabled="loadingDesc"
                @pressEnter="saveDescription"
              />
              <div class="icon-container">
                <a-spin size="small" v-if="loadingDesc" />
                <a-tooltip v-else title="Press Enter to add description.">
                  <EnterOutlined class="cursor-pointer" @click="saveDescription" />
                </a-tooltip>
              </div>
            </div>
          </div>

          <div class="position-relative d-flex flex-column">
            <a-typography-text class="d-block position-relative">
              {{ currStepObj?.work_cycle?.name }}
              <span v-if="currStepObj?.work_cycle?.is_master"> (Master) </span>
            </a-typography-text>
            <div class="videoWrapper">
              <SegmentVideo
                v-for="(cycle, index) in selectedStepCycles"
                :key="cycle.id"
                :video-url="!isDemoOngoing ? cycle.url : stationDesignDummyObject.url"
                :segment-play="segmentPlay"
                :duration-time="segmentPlay.duration"
                :playback-rate="segmentPlay?.playbackRate"
                :frame-per-sec="cycle.videoFPS"
                :styles="{ width: '300px', maxHeight: '260px' }"
                :wrapperStyles="{
                  display: currCycle === index ? 'flex !important' : 'none !important'
                }"
                :allowFullScreen="true"
                @updatePlaybackRate="updatePlaybackRate"
              />
            </div>

            <div class="mt-2 w-100 position-relative" v-if="selectedStepCycles?.length">
              <span class="d-flex align-items-center gap-2 position-absolute start-50 translate-middle-x">
                <LeftOutlined
                  class="cycle-nav-icon"
                  :class="{ disabled: currCycle <= 0 }"
                  @click="handleCycleChange(-1)"
                />
                <span class="lh-1 fs-5">
                  {{ currCycle + 1 }} / {{ selectedStepCycles?.length }}
                </span>
                <RightOutlined
                  :class="{ disabled: currCycle === selectedStepCycles?.length - 1 }"
                  class="cycle-nav-icon"
                  @click="handleCycleChange(1)"
                />
              </span>

              <span
                role="button"
                class="position-absolute start-100 translate-middle"
                v-if="!currStepObj?.work_cycle?.is_master"
                @click="onDeleteCycleConfirm()"
              >
                <DeleteOutlined class="fs-6 mt-3" style="color: red" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import { valueAssessment } from '../config'
import {
  ForwardOutlined,
  BackwardOutlined,
  EditOutlined,
  ClockCircleOutlined,
  EnterOutlined,
  LeftOutlined,
  RightOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons-vue'
import { mapActions, mapState } from 'pinia'
import { useClassificationStore } from 'src/stores/classification'
import { useStationStore } from 'src/stores/station'
import { useUserStore } from 'src/stores/user'
import { useSegmentationStore } from 'src/stores/segmentation'
import { useSegmentMapingStore } from 'src/stores/segmentMapping'
import {
  flattenSegmentSuggestionandAnswers,
  getAssessmentBasedOnPrecedence,
  getStepCyclesTime,
  getMeanAndStandardDeviation
} from 'src/utils/helpers'
import { h } from 'vue'
import { Modal } from 'ant-design-vue'
import { processStatus } from '../config'
import { useToast } from 'vue-toastification'
import { toLower } from 'src/utils/helpers'
import eventBus from 'src/utils/eventBus'
import EditStepCycle from './EditStepCycle.vue'
import SegmentVideo from './SegmentVideoControlled.vue'

export default {
  inject: ['nextTab', 'showError', 'showInfo'],
  components: {
    ForwardOutlined,
    BackwardOutlined,
    EditOutlined,
    ClockCircleOutlined,
    EnterOutlined,
    LeftOutlined,
    RightOutlined,
    DeleteOutlined,
    EditStepCycle,
    SegmentVideo
  },
  setup() {
    return {
      toast: useToast(),
      valueAssessment,
      processStatus
    }
  },
  data() {
    return {
      desc: '',
      newPart: '',
      newTool: '',
      steps: [],
      currentStep: 0,
      selectedActions: [],
      selectedStepValueType: valueAssessment.VA,
      isRenaming: false,
      stepName: null,
      loading: true,
      showModal: false,
      //  segment play
      isPlayingSegment: false,
      segmentPlay: {
        start: 0,
        end: 0,
        playbackRate: 1
      },
      //   ------
      processStatusPolling: null,
      isPolled: false,
      isTimeStudyFailed: false,
      isUpdatingTherbligAssociation: false,
      // step cycle
      selectedStepCycles: [],
      currCycle: 0,
      currStepObj: null,
      deleteCycleLoading: false,
      renameLoading: false,
      loadingTools: false,
      loadingParts: false,
      loadingDesc: false,
      segmentsCountUpdate: false,
      isChainedServices: false
    }
  },
  computed: {
    ...mapState(useUserStore, ['isDemoOngoing']),
    ...mapState(useClassificationStore, [
      'actions',
      'actionNameToDescMap',
      'idToActionObj',
      'idToActionName'
    ]),
    ...mapState(useStationStore, [
      'stationDesignDummyObject',
      'newStudyId',
      'newStudy',
      'isCell',
      'studyVideoUrl',
      'segmentationStatus',
      'classificationStatus',
      'mappingStatus',
      'areProcessesRunning',
      'numSegments',
      'numberOfSteps',
      'selectedMasterCycleId',
      'workCycles',
      'masterCycle',
      'masterCycleFileUrl',
      'timeStudyProcessCompleted',
      'suggestionProcessInProgress',
      'stepNameRecommendationStatus',
      'updateStation'
    ]),
    ...mapState(useSegmentationStore, ['segmentationData', 'stepToCyclesSegmentation']),
    progressStatuses() {
      const timeStudyStatus = [
        {
          progress: this.segmentationStatus?.progress || 0,
          isRunning: this.segmentationStatus?.name !== processStatus.Finished,
          title: 'Discovering assembly steps.'
        },
        {
          progress: this.mappingStatus?.progress || 0,
          isRunning: this.mappingStatus?.name !== processStatus.Finished,
          title: 'Matching steps across cycles.'
        }
      ]
      if (this.newStudy?.station?.type === 'station') {
        timeStudyStatus.splice(1, 0, {
          progress: this.classificationStatus?.progress || 0,
          isRunning: this.classificationStatus?.name !== processStatus.Finished,
          title: 'Identifying action types.'
        })
      }
      if (this.newStudy?.processes?.find((p) => p.process?.name === 'Step Name Recommendation')) {
        timeStudyStatus.push({
          progress: this.stepNameRecommendationStatus?.progress || 0,
          isRunning: this.stepNameRecommendationStatus?.name !== processStatus.Finished,
          title: 'Identifying names for the steps.'
        })
      }
      return timeStudyStatus
    },

    isAllStepsNotNamed() {
      if (!this.steps) return false
      return this.steps.some((s) => s.name === null || s.name === '')
    }
  },

  watch: {
    segmentationData(segments) {
      this.steps = segments
        .filter((segment) => segment.work_cycle.id === this.selectedMasterCycleId)
        ?.sort((a, b) => a.step_index - b.step_index)

      this.updateSelectedActions()
      this.handleStepChange(this.currentStep)
    },

    currentStep(value) {
      if (typeof value === 'number') this.handleStepChange(value)
    },

    isAllStepsNotNamed(value) {
      if (value) {
        this.isRenaming = true
        const index = this.steps.findIndex((s) => s.name === null || s.name === '')
        if (index >= 0) this.currentStep = index
      }
    },

    // masterCycle(cycle) {
    //   if (!cycle || !Object.keys(cycle)?.length) return
    //   this.getCycleSegments(cycle.cycle_no)
    // },

    isTimeStudyFailed(failed) {
      if (failed) {
        this.loading = false
        this.clearIntervals()
      }
    }
  },

  methods: {
    ...mapActions(useStationStore, [
      'fetchStudy',
      'setAreProcessesRunning',
      'fetchWorkCyles',
      'runTimeStudy',
      'updateStudy',
      'setStepsDataLoading',
      'deleteWorkCycle'
    ]),
    ...mapActions(useSegmentationStore, [
      'startSegmentation',
      'fetchAllSteps',
      'updateSegment',
      'createSegmentActionAssociation',
      'removeSegmentActionAssociation',
      'getCycleSegments',
      'updateSegmentInList',
      'addNewTools',
      'addNewParts',
      'removeStepTool',
      'removeStepPart'
    ]),
    ...mapActions(useClassificationStore, [
      'startClassification',
      'assignTherbligsToSegments',
      'getTherbligs',
      'assignTherbligFromStepName'
    ]),
    ...mapActions(useSegmentMapingStore, ['startSegmentationMapping', 'startSegmentRemaping']),

    updatePlaybackRate(value) {
      this.segmentPlay.playbackRate = value
    },

    async onAddPartsAndTools(event) {
      const inputName = event.target.name
      if (event.key === 'Enter' || event.key === ',') {
        event.preventDefault()
        if (inputName === 'parts') await this.addParts(event)
        else await this.addTools(event)
      }
    },

    async addParts(event) {
      const step = this.steps[this.currentStep]
      if (!this.newPart) return
      if (step.parts?.some((p) => toLower(p.name) === toLower(this.newPart))) {
        this.showInfo('This part already added in this step!')
        return
      }

      this.loadingParts = true
      const data = await this.addNewParts(this.steps[this.currentStep].id, {
        step: step.id,
        name: this.newPart
      })
      this.loadingParts = false
      if (!data) return

      const newparts = [...step.parts, { ...data }]
      this.updateSegmentInList(step.id, { parts: newparts })
    },

    async removeParts(partId) {
      if (!partId) return
      const step = this.steps[this.currentStep]
      const data = await this.removeStepPart(partId)
      if (!data) return
      const newparts = step.parts.filter((s) => s.id !== partId)
      this.updateSegmentInList(step.id, { parts: newparts })
    },

    async addTools(event) {
      event.preventDefault()
      const step = this.steps[this.currentStep]
      if (!this.newTool) return
      if (step.tools?.some((t) => toLower(t.name) === toLower(this.newTool))) {
        this.showInfo('This tool already added in this step!')
        return
      }

      this.loadingTools = true
      const data = await this.addNewTools(this.steps[this.currentStep].id, {
        step: step.id,
        name: this.newTool
      })
      this.loadingTools = false
      if (!data) return

      const newtools = [...step.tools, { ...data }]
      this.updateSegmentInList(step.id, { tools: newtools })
    },

    async removeTools(toolId) {
      if (!toolId) return
      const step = this.steps[this.currentStep]
      const data = await this.removeStepTool(toolId)
      if (!data) return
      const newtools = step.tools.filter((s) => s.id !== toolId)
      this.updateSegmentInList(step.id, { tools: newtools })
    },

    async saveDescription(event) {
      event.preventDefault()
      const step = this.steps[this.currentStep]
      this.loadingDesc = true
      const res = await this.updateSegment(step.id, { description: step.description })
      if (!res) return
      this.updateSegmentInList(step.id, { description: step.description })
      this.loadingDesc = false
    },

    handleCloseModal() {
      this.$router.push({ name: 'studies' })
    },

    handleClickNext() {
      if (this.isDemoOngoing) return
      if (this.currentStep < this.steps.length - 1) {
        this.currentStep++
      }
    },

    handleClickPrevious() {
      if (this.isDemoOngoing) return
      if (this.currentStep > 0) {
        this.currentStep--
      }
    },

    getStepsMeta(stepIndex) {
      if (!this.steps.length || !this.steps[stepIndex]) return
      if (!this.stepToCyclesSegmentation || !Object.keys(this.stepToCyclesSegmentation)?.length)
        return

      const cycle_seg = this.stepToCyclesSegmentation[stepIndex]
      const segCycleTime = getStepCyclesTime(cycle_seg)
      const { mean, std } = getMeanAndStandardDeviation(segCycleTime)
      return `Time: ${mean} ± ${Number(std.toFixed(2))} (s)`
    },

    getStepActions(step_action_association) {
      if (!step_action_association.length) return null
      const actions = step_action_association
        ?.map((el) => el.action)
        .map((actionId) => this.idToActionName[actionId])
      return actions.join(', ')
    },

    handleClickStep(index) {
      if (this.renameLoading) return
      this.currentStep = index
    },

    handleStepChange(index) {
      if (!this.steps[index] || this.isUpdatingTherbligAssociation || this.deleteCycleLoading) {
        return
      }
      this.currentStep = index
      this.currCycle = 0
      this.newPart = ''
      this.newTool = ''
      // segment video
      this.setSelectedStepCycles(index)
      this.handleCycleChange()
      // segment actions
      const segment = this.steps[index]
      this.updateSelectedActions()
      this.selectedStepValueType = this.getValueAssessment(segment.step_action_association, index)
      this.stepName = this.steps[this.currentStep].name
      if (!this.isAllStepsNotNamed) this.isRenaming = false
    },

    setSelectedStepCycles(currStepIndex) {
      this.selectedStepCycles = this.stepToCyclesSegmentation[currStepIndex] || []
    },

    handleCycleChange(count = 0) {
      this.currCycle = this.currCycle + count
      const segment = this.selectedStepCycles[this.currCycle]
      if (!segment) return
      this.currStepObj = segment
      this.setCurrentSegment(segment)
    },

    setCurrentSegment(segment) {
      if (!segment) return
      const { segment_start, segment_end } = segment
      this.segmentPlay = {
        start: segment_start / segment.videoFPS,
        end: segment_end / segment.videoFPS,
        duration: Number(((segment_end - segment_start) / segment.videoFPS).toFixed(2)),
        playbackRate: this.segmentPlay?.playbackRate
      }
    },

    onDeleteCycleConfirm() {
      if (this.isUpdatingTherbligAssociation) return
      Modal.confirm({
        title: 'Confirmation',
        icon: h(ExclamationCircleOutlined),
        content: 'This will delete all data of this cycle, Are you sure you want to delete?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        centered: true,
        onOk: async () => {
          await this.handleRemoveStepCycle()
        }
      })
    },

    async handleRemoveStepCycle() {
      this.deleteCycleLoading = true
      const stepCycleIndex = this.currCycle
      const obj = this.selectedStepCycles[stepCycleIndex]
      if (this.currCycle === this.selectedStepCycles.length - 1) {
        this.currCycle = this.currCycle - 1
      }
      this.selectedStepCycles.splice(stepCycleIndex, 1)
      this.handleCycleChange()
      await this.deleteWorkCycle(obj.work_cycle.id)
      const oldNoOfCycles = this.newStudy.station.no_of_work_cycles - 1
      await this.updateStation(this.newStudy.station.id, {
        no_of_work_cycles: oldNoOfCycles || 0
      })
      await this.fetchAllSteps()
      this.deleteCycleLoading = false
    },

    // Rename step
    handleClickRename() {
      if (this.isDemoOngoing) return
      this.stepName = this.steps[this.currentStep].name
      this.isRenaming = true
    },

    async handleRenameStepName() {
      const stepNameValue = this.stepName
      const stepIndex = this.currentStep
      const stepId = this.steps[stepIndex]?.id

      if (this.steps[stepIndex]?.name === stepNameValue) {
        if (!this.isAllStepsNotNamed) this.isRenaming = false
        return
      }

      let msg = ''
      if (!stepNameValue) msg = 'Steps name can not be Empty!'

      if (msg) {
        this.showError(msg)
        return
      }

      this.renameLoading = true
      await this.handleSaveRenameStep(stepId, stepIndex, stepNameValue)
      this.renameLoading = false
      if (!this.isAllStepsNotNamed) {
        this.isRenaming = false
      } else if (this.currentStep < this.steps?.length - 1) {
        this.stepName = null
        this.currentStep = this.currentStep + 1
      }
    },

    async handleSaveRenameStep(stepId, stepIndex, stepNameValue) {
      await this.updateSegment(stepId, {
        name: stepNameValue
      })
      const updatedTherbligs = await this.assignTherbligFromStepName({
        step_name: stepNameValue,
        step_id: stepId
      })

      const updatedStepObj = flattenSegmentSuggestionandAnswers({
        ...this.steps[stepIndex],
        name: stepNameValue,
        step_action_association: [...updatedTherbligs]
      })
      this.updateSegmentInList(stepId, updatedStepObj)
    },

    // Update Actions
    updateSelectedActions() {
      if (!this.steps[this.currentStep]) return
      const { step_action_association } = this.steps[this.currentStep]
      this.selectedActions = step_action_association?.map((el) => el.action) || []
    },

    async handleSelectAction(newVal) {
      this.isUpdatingTherbligAssociation = true
      const oldVal = this.selectedActions
      const selected = newVal.filter((a) => !oldVal.includes(a))
      const unselected = oldVal.filter((a) => !newVal.includes(a))
      this.selectedActions = newVal
      await this.handleAssociateAction(selected)
      await this.handleDissociateAction(unselected)
      this.isUpdatingTherbligAssociation = false
      const segment = this.steps[this.currentStep]
      this.selectedStepValueType = this.getValueAssessment(
        segment.step_action_association,
        this.currentStep
      )
    },

    async handleAssociateAction([actionId]) {
      if (!actionId) return
      const payload = {
        step: this.steps[this.currentStep].id,
        action: actionId,
        value_type: this.idToActionObj[actionId].default_assesment
      }
      await this.createSegmentActionAssociation(payload)
    },

    async handleDissociateAction([actionId]) {
      if (!actionId) return
      const { id } = this.steps[this.currentStep]
      await this.removeSegmentActionAssociation(id, actionId)
    },

    // Value type
    async handleValueAssessmentChange(e) {
      if (this.isDemoOngoing) return
      const { value } = e.target
      const { id } = this.steps[this.currentStep]
      this.selectedStepValueType = value
      const obj = { value_type: value, value_type_by_user: true }
      await this.updateSegment(id, obj)
      this.updateSegmentInList(id, obj)
    },

    getValueAssessment(step_action_association, stepIndex = 0) {
      if (!this.steps?.length) return

      let step = this.isDemoOngoing
        ? this.stationDesignDummyObject.stepsAnalysis[stepIndex]
        : this.steps[stepIndex]

      let actionType = valueAssessment.VA
      if (step && step.value_type && step.value_type_by_user) {
        actionType = step.value_type
      } else if (step_action_association?.length) {
        const temp = step_action_association.map((el) => el.value_type)
        actionType = getAssessmentBasedOnPrecedence(temp)
      }
      return actionType
    },

    // Services
    async startChainedServices() {
      this.loading = true
      if (!this.numSegments || this.timeStudyProcessCompleted) return
      const [error] = await this.runTimeStudy(this.numSegments)
      if (error) {
        this.toast.error('Error occurred in starting models.')
      }
      this.listenProcessesStatus()
    },

    listenProcessesStatus() {
      if (this.processStatusPolling) {
        clearInterval(this.processStatusPolling)
      }
      this.getProcessesStatus()
      this.processStatusPolling = setInterval(this.getProcessesStatus, 5000)
    },

    async getProcessesStatus() {
      if (this.isPolled) return

      this.isPolled = true
      await this.fetchStudy(this.newStudyId)
      this.isPolled = false

      const {
        segmentationStatus,
        classificationStatus,
        mappingStatus,
        stepNameRecommendationStatus
      } = this
      const processes = [segmentationStatus.name, mappingStatus?.name]
      if (this.newStudy?.station?.type === 'station') {
        processes.push(classificationStatus.name)
      }
      if (this.newStudy.processes?.find((p) => p.process.name === 'Step Name Recommendation')) {
        processes.push(stepNameRecommendationStatus?.name)
      }
      this.loading = false
      this.setAreProcessesRunning(true)
      const areProcessesCompleted = processes.every((el) => el === processStatus['Finished'])
      this.isTimeStudyFailed = processes.some((el) => el === processStatus['Failed'])
      if (areProcessesCompleted || this.isTimeStudyFailed) {
        clearInterval(this.processStatusPolling)
        this.isChainedServices = false
        this.setAreProcessesRunning(false)
        if (!this.isTimeStudyFailed) await this.loadAllData()
      }
    },

    async handleAdjust() {
      const isAssociated = this.steps.every((s) => !!s.step_action_association.length)
      if (!this.steps.length || !this.actions.length) return
      if (this.isAllStepsNotNamed) {
        this.showError('Please assign name to all steps.')
      } else if (!isAssociated) {
        this.showError('Please associate atleast one motion element to each step.')
      } else {
        this.nextTab()
      }
    },

    async handleSaveSegments(startHandTrack = false) {
      this.showModal = false
      if (startHandTrack) {
        this.loading = true
        this.currentStep = 0
        if (this.newStudy.station?.type === 'station') await this.assignTherbligsToSegments()
        await this.fetchAllSteps()
        this.listenProcessesStatus()
      }
    },

    async loadAllData() {
      this.loading = true
      this.setStepsDataLoading(true)
      if (!this.isCell) await this.assignTherbligsToSegments()
      await this.fetchWorkCyles()
      await this.fetchAllSteps()
      await this.getTherbligs()
      this.setStepsDataLoading(false)
      this.loading = false
    },

    setEvents() {
      eventBus.$on('steps-next-page', this.handleAdjust)
    },

    clearIntervals() {
      clearInterval(this.processStatusPolling)
    }
  },

  async created() {
    if (this.isDemoOngoing) return
    if (this.numSegments && this.numSegments !== this.numberOfSteps) this.segmentsCountUpdate = true

    const statuses = Object.values(processStatus)
    this.setEvents()
    // run All models when no steps or processes are not completed after estimated time
    if (!this.numberOfSteps && !statuses.includes(this.segmentationStatus.name)) {
      this.isChainedServices = true
      await this.startChainedServices()
    } else if (this.numberOfSteps && this.newStudy.progress) {
      this.loading = true
      await this.startSegmentationMapping()
      if (this.newStudy.progress) await this.updateStudy({ progress: 0 }, false)
      this.listenProcessesStatus()
    } else {
      this.listenProcessesStatus()
    }
    // else if (this.segmentsCountUpdate && this.numberOfSteps) {
    // when video already processed and segments updated
    // await this.startProcesses()
    // TODO: add separate call
    // this.isChainedServices = true
    // await this.startChainedServices()
    // }

    if (!this.timeStudyProcessCompleted && this.newStudy.status !== processStatus.Failed) {
      setTimeout(() =>
        this.showInfo(
          'Your video will be processed in some time. Feel free to close the tab and check back in a bit.'
        )
      )
    }
  },

  beforeUnmount() {
    eventBus.$off('steps-next-page', this.handleAdjust)
    this.isChainedServices = false
    this.currentStep = 0
    this.setAreProcessesRunning(false)
    this.clearIntervals()
  }
}
</script>

<style>
.textarea-container {
  position: relative;
}

.icon-container {
  position: absolute;
  bottom: 8px;
  right: 8px;
  display: flex;
  align-items: center;
}

.disable .ant-list-item-meta .ant-list-item-meta-title .ant-typography {
  color: white !important;
}

.disable .ant-list-item-meta .ant-list-item-meta-description .station-design-list-item-header {
  color: white !important;
  font-weight: 500;
}

.station-design-list-item-header {
  font-weight: 600;
  font-size: 12.8px;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.progress-item {
  /* width: 50%; */
  display: flex;
}

.step-actions-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.step-actions-wrapper .ant-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  row-gap: 0.8em;
}

.step-actions-wrapper .ant-checkbox-wrapper {
  border: 1px solid #ccc;
  padding: 0.8em;
  border-radius: 4px;
}

.master-steps-list {
  height: 68vh;
  display: flex;
  flex-direction: column;
}

.master-steps-list > .ant-list-header {
  /* background: lightgray !important;
  font-weight: 600 !important; */
  background: #fafafa !important;
  font-weight: 600 !important;
  padding: 10px 10px 10px 10px !important;
  font-size: 14px;
  color: #0b072d;
  text-align: left;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.master-steps-list > .ant-spin-nested-loading {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.master-steps-list > .ant-spin-nested-loading > .ant-spin-container {
  flex-grow: 1;
  height: 1px;
  overflow-y: auto;
}

.step-item {
  cursor: pointer;
  background: #f8f9fa;
  border-radius: 5px;
}

.step-item.disable {
  background-color: #1890ff;
  color: white;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.step-item.muted {
  background-color: lightgray !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.step-container {
  display: flex;
  justify-content: space-between;
}

.step-info-container {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.step-operations button {
  display: flex;
  align-items: center;
  width: 100%;
}

.load-center {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cycle-nav-icon {
  cursor: pointer;
}

.cycle-nav-icon.disabled {
  color: #828282;
  pointer-events: none;
}

.station-design-list-item-header .disabled {
  font-weight: 600;
  font-size: 12.8px;
  color: white;
}

.videoWrapper {
  position: relative;
  overflow: hidden;
  width: 300px;
}

.tag-input {
  width: 100%;
  border: 1px solid rgb(243, 240, 240);
  border-radius: 6px;
  font-size: 0.9em;
  box-sizing: border-box;
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;
}

.tag-input__tag {
  margin-right: 10px;
  margin-bottom: 6px;
  background-color: #446ff0;
  padding: 0 6px;
  border-radius: 5px;
  color: #fff;
  display: flex;
  align-items: center;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tag_close_icon {
  cursor: pointer;
  opacity: 0.75;
  color: #fff;
  padding: 0 5px;
}

.tag_close_icon:hover {
  opacity: 1;
}

.tag-input__text {
  border: none;
  outline: none;
  font-size: 1em;
  background: none;
  flex-grow: 1;
  min-width: 150px;
  margin-bottom: 10px;
  min-height: 30px;
  resize: none;
  overflow: hidden;
}
</style>
