<template>
  <div class="d-flex justify-content-center w-100 h-100">
    <a-card
      :loading="isLoading"
      class="w-50 d-flex flex-column"
      title="Kaizen Notes"
      size="small"
      :bodyStyle="{ flexGrow: 1, height: '1px', overflowY: 'auto' }"
    >
      <template #actions>
        <div class="text-end">
          <a-button
            :disabled="isLoading || !hasResponses"
            style="margin-right: 1em"
            type="primary"
            @click="onFinish"
          >
            Submit
          </a-button>
        </div>
      </template>

      <a-form layout="vertical">
        <a-row :gutter="[24]" class="p-2 pt-3">
          <a-col v-for="input in handleKaizenNotes()" span="24" :key="input.id">
            <a-form-item :label="input.question">
              <a-input v-if="input.type === 'input'" v-model:value="input.response" />

              <a-textarea v-if="input.type === 'text_area'" v-model:value="input.response" />

              <a-radio-group v-if="input.type === 'radio_button'" v-model:value="input.response">
                <a-radio v-for="option in input.options" :key="option" :value="option">
                  {{ option }}
                </a-radio>
              </a-radio-group>

              <!-- <a-upload
                v-if="input.type === 'file'"
                :fileList="[]"
                accept="text/plain"
                name="file"
                :max-count="1"
                :beforeUpload="handleNotesUpload"
                @change="(file) => handleUploadFile(file, input)"
              >
                <a-button class="d-flex align-items-center">
                  <template #icon>
                    <upload-outlined />
                  </template>
                  Upload Text file
                </a-button>
              </a-upload> -->
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-card>
  </div>
</template>
<script>
import { mapActions, mapState } from 'pinia'
import { useStationStore } from 'src/stores/station'
import { useUserStore } from 'src/stores/user'

import { message, Upload } from 'ant-design-vue'
import eventBus from 'src/utils/eventBus'

export default {
  data() {
    return {
      isLoading: false,
      files: [],
      kaizenNotesResponses: []
    }
  },

  computed: {
    ...mapState(useStationStore, [
      'newStudyId',
      'kaizenNotesQuestions',
      'stationDesignDummyObject'
    ]),
    ...mapState(useUserStore, ['isDemoOngoing']),
    hasResponses() {
      return this.kaizenNotesResponses.some(input => input.response && input.response.trim() !== '');
    }
  },

  watch: {
    kaizenNotesQuestions(value) {
      this.kaizenNotesResponses = JSON.parse(JSON.stringify(value))
    }
  },

  methods: {
    ...mapActions(useStationStore, ['fetchKaizenNotesQuestions', 'updateKaizenNotesResponse']),
    handleDone() {
      this.$router.push({ name: 'studies' })
    },

    handleKaizenNotes() {
      if (this.isDemoOngoing) {
        return this.stationDesignDummyObject.kaizenNotesResponses
      }
      return this.kaizenNotesResponses.filter((input) => input.type !== 'file')
    },

    async onFinish() {
      if (JSON.stringify(this.kaizenNotesQuestions) === JSON.stringify(this.kaizenNotesResponses))
        return
      this.isLoading = true
      await this.updateKaizenNotesResponse(this.newStudyId, this.kaizenNotesResponses)
      this.isLoading = false
      this.$router.replace({ name: 'studies' })
    },

    handleUploadFile(file, input) {
      input.response = [file.file]
    },

    handleNotesUpload(file) {
      if (file.type === 'text/plain') return false
      message.error('Only Text file is allowed')
      return Upload.LIST_IGNORE
    }
  },

  async created() {
    if (this.isDemoOngoing) return
    this.isLoading = true
    await this.fetchKaizenNotesQuestions(this.newStudyId)
    this.kaizenNotesResponses = JSON.parse(JSON.stringify(this.kaizenNotesQuestions))
    this.isLoading = false
    eventBus.$on('notes-next-page', this.handleDone)
  },

  beforeUnmount() {
    eventBus.$off('notes-next-page', this.handleDone)
  }
}
</script>
<style scoped>
.container {
  max-width: 1024px;
  margin: 20px auto;
  background: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid #ccc;
  border-radius: 6px;
}

input[type='text'],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>
