import { ASSETS } from 'src/constants/cdnConstants';

function getAssetUrl(assetPath) {
  return assetPath.split('.').reduce((o, i) => (o ? o[i] : ''), ASSETS) || assetPath;
}

export default {
  beforeMount(el, binding) {
    const url = getAssetUrl(binding.value);

    if (el.tagName.toLowerCase() === 'img'|| el.tagName.toLowerCase() === 'video') {
      el.src = url;
    } else {
      el.style.backgroundImage = `url(${url})`;
    }
  }
};
