export default [
  {
    title: 'Kaizen Points',
    dataIndex: 'kaizen_point',
    align: 'left',
    width: '40%',
    customCell: (_, index) => {
      return {
        colSpan: _.category ? 1 : 6
      }
    }
  },
  {
    title: 'Category',
    dataIndex: 'category',
    align: 'center',
    customCell: (_, index) => {
      return {
        colSpan: _.category ? 1 : 0
      }
    }
  },
  {
    title: 'Influence On',
    align: 'center',
    children: [
      {
        title: 'Safety',
        dataIndex: 'safety',
        key: 'safety',
        width: 100,
        align: 'center',
        customCell: (_, index) => {
          return {
            colSpan: _.category ? 1 : 0
          }
        }
      },
      {
        title: 'Quality',
        dataIndex: 'quality',
        key: 'quality',
        width: 100,
        align: 'center',
        customCell: (_, index) => {
          return {
            colSpan: _.category ? 1 : 0
          }
        }
      },
      {
        title: 'Productivity',
        dataIndex: 'productivity',
        key: 'productivity',
        width: 150,
        align: 'center',
        customCell: (_, index) => {
          return {
            colSpan: _.category ? 1 : 0
          }
        }
      }
    ]
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    align: 'center',
    customCell: (_, index) => {
      return {
        colSpan: _.category ? 1 : 0
      }
    }
  }
]
