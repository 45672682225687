import auth from './auth.js'
import user from './user.js'
import admin from './admin.js'

// const requireRoute = import.meta.glob('./*.js')

const routes = [
  ...auth,
  ...user,
  ...admin,
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    component: () => import('src/components/Shared/404Page.vue')
  }
]

// for (const fileName of Object.keys(requireRoute)) {
//   if (fileName === './index.js') continue
//   const route = await requireRoute[fileName]()
//   routes.push(...route.default)
// }

export default routes
