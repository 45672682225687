<template>
  <div ref="chartContainer" style="width: 100%; height: 100%"></div>
</template>

<script>
import Highcharts from 'highcharts'
import Heatmap from 'highcharts/modules/heatmap'

Heatmap(Highcharts)

export default {
  name: 'HeatmapChart',
  props: {
    heatmapData: {
      type: Array,
      required: true
    },
    checked: {
      type: Boolean,
      default: true
    },
    max: {
      type: Number,
      default: 0
    }
  },
  watch: {
    heatmapData(newData) {
      if (this.chart) {
        this.chart.series[0].name = this.checked ? 'Moves' : 'Distance'
        this.chart.series[0].setData(newData, true, false, false)
      }
    }
  },
  mounted() {
    this.renderChart(this.heatmapData)
  },
  methods: {
    renderChart(data) {
      const self = this
      const categories = Array.from({ length: self.max }, (v, i) => i + 1)

      this.chart = Highcharts.chart(self.$refs.chartContainer, {
        chart: {
          type: 'heatmap',
          marginTop: 0,
          marginBottom: 80,
          plotBorderWidth: 1
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        xAxis: {
          categories,
          title: {
            text: 'TO'
          }
        },
        yAxis: {
          categories,
          title: {
            text: 'FROM'
          }
        },
        colorAxis: {
          min: 0,
          max: 1,
          minColor: '#FFFFFF',
          maxColor: '#FFFFFF',
          labels: {
            enabled: false // This removes the horizontal scale at the bottom
          }
        },
        legend: {
          enabled: false // This removes the vertical scale on the right side
        },
        series: [
          {
            name: self.checked ? 'Moves' : 'Distance',
            borderWidth: 1,
            data: data,
            dataLabels: {
              enabled: true,
              color: '#000000',
              formatter: function () {
                return this.point.options.value || '-'
              }
            }
          }
        ],
        tooltip: {
          formatter: function () {
            return (
              `<b>From: ${this.point.y + 1}</b><br/>` +
              `<b>To: ${this.point.x + 1}</b><br/>` +
              `<b>${this.series.name}: ${this.point.options.value || 0}</b>`
            )
          }
        },
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              }
              // chartOptions: {
              //   yAxis: {
              //     labels: {
              //       formatter: function () {
              //         return this.value.substr(0, 1)
              //       }
              //     }
              //   }
              // }
            }
          ]
        },
        exporting: {
          enabled: false
        }
      })
    }
  }
}
</script>

<style scoped>
/* Replace deprecated -ms-high-contrast with forced-colors */
@media (forced-colors: active) {
  .some-element {
    forced-color-adjust: none;
    background-color: Canvas;
    color: CanvasText;
  }
}
</style>
