<template>
  <AISuggestionModal />
  <div id="sd-result-work-audit-results">
    <a-typography-title :level="4">Work Station Audit Results</a-typography-title>
    <a-space class="my-3">
      <a-statistic
        title="Workstation Maturity"
        :value="analyzeMaturity"
        :style="{ textAlign: 'center', fontWeight: 500 }"
        :valueStyle="{ fontSize: '28px' }"
      />
      <a-divider type="vertical" :style="{ height: '50px' }" />
      <a-statistic
        title="Kaizen Opportunities"
        :value="31 - workStationMaturity"
        :style="{ textAlign: 'center', fontWeight: 500 }"
        :valueStyle="{ fontSize: '28px' }"
      />
    </a-space>
    <a-row :gutter="[24, 24]">
      <a-col span="24">
        <a-table
          class="ant-table-striped"
          :row-class-name="(_record, index) => (!_record.category ? 'table-striped' : null)"
          bordered
          :columns="columns"
          :data-source="formatAnalyzedData"
          :pagination="false"
          :loading="workStationAuditResponseAnalysisLoader"
          :scroll="{ y: '50vh' }"
        >
          <template #bodyCell="{ column, text, record }">
            <span v-if="column.dataIndex === 'category'">
              {{ workAuditCategoryLabels[record.category] }}
            </span>
            <span
              class="workstation-ai-generated-point"
              v-if="column.dataIndex === 'kaizen_point' && !record.category"
            >
              <a-typography-text type="primary" class="ai-point-description">
                <b>{{ record?.solution }}: </b> {{ record.explanation }}
              </a-typography-text>
              <span
                @click="deleteSuggestion(record.id)"
                class="workstation-ai-generated-point-delete"
                ><CloseCircleOutlined style="font-size: 20px; cursor: pointer"
              /></span>
            </span>
            <span v-if="column.dataIndex === 'action'">
              <a-button @click="handleAiSuggestion(record.kaizen_point)" type="primary"
                >AI Suggest</a-button
              >
            </span>
          </template>
        </a-table>
      </a-col>
    </a-row>
  </div>
  <br />
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useStationStore } from 'src/stores/station'
import { useStationAudit } from 'src/stores/workStationAudit'
import { workAuditCategoryLabels } from '../config'
import { CloseCircleOutlined } from '@ant-design/icons-vue'
import AISuggestionModal from './WorkAuditAISuggestionModal.vue'
import workstationAuditResult from 'src/config/workstation-audit-result-column'

export default {
  setup: () => ({
    workAuditCategoryLabels
  }),
  data() {
    return {
      columns: workstationAuditResult
    }
  },
  components: {
    AISuggestionModal,
    CloseCircleOutlined
  },
  async created() {
    const studyId = this.newStudyId
    if (!studyId) return
    await Promise.all([
      this.fetchWorkStationAuditResponseAnalysis(studyId),
      this.fetchWorkStationAuditResponseResult(studyId)
    ])
  },
  computed: {
    ...mapState(useStationStore, ['newStudyId']),
    ...mapState(useStationAudit, [
      'workStationAuditResponseAnalysis',
      'workStationAuditResponseAnalysisLoader',
      'workStationMaturityLoader',
      'workStationMaturity'
    ]),

    formatAnalyzedData() {
      return this.workStationAuditResponseAnalysis.map((workstation_response) => ({
        id: workstation_response.id || workstation_response.question?.id,
        kaizen_point: workstation_response.question?.improvement,
        solution: workstation_response.question?.solution,
        explanation: workstation_response.question?.explanation,
        category: workstation_response.question?.group,
        productivity: workstation_response.question.impact_productivity && '*',
        quality: workstation_response.question.impact_quality && '*',
        safety: workstation_response.question.impact_safety && '*'
      }))
    },

    analyzeMaturity() {
      if (this.workStationMaturity > 28) {
        return 'Very High'
      } else if (this.workStationMaturity > 22) {
        return 'High'
      } else if (this.workStationMaturity > 15) {
        return 'Moderate'
      } else if (this.workStationMaturity > 10) {
        return 'Low'
      } else {
        return 'Very Low'
      }
    }
  },
  methods: {
    ...mapActions(useStationAudit, [
      'fetchWorkStationAuditResponseAnalysis',
      'fetchWorkStationAuditResponseResult',
      'toggleAiModal',
      'handleDeleteAISuggestion'
    ]),

    async deleteSuggestion(id) {
      await this.handleDeleteAISuggestion(id)
    },
    handleAiSuggestion(point) {
      this.toggleAiModal(point)
    }
  }
}
</script>

<style>
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 10px;
}

.workstation-ai-generated-point {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}
.ai-point-description {
  flex: 1;
}

.workstation-ai-generated-point-delete {
  flex-basis: 3%;
  text-align: center;
  cursor: pointer;
}

.ant-col {
  padding-right: 10px;
}

.arrow-canvas {
  margin: 0;
  padding: 0;
}

.arrow-instruction {
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
}
.workstation-audit-status {
  width: 120px;
  text-align: center;
}
.workstation-audit-status h5 {
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 0;
}
.workstation-audit-status span {
  font-size: 20px;
  font-weight: 600;
  color: #1890ff;
}
.separator {
  width: 2px;
  height: 60px;
  background: lightgray;
}
.ant-table-striped .table-striped td {
  /* background-color: #006400b3 !important; */
  background-color: #c9dbc2 !important;
}
</style>
