<template>
  <div id="sd-result-station-layout-suggestion">
    <a-typography-title :level="4">Station Layout Suggestions</a-typography-title>

    <a-row class="regions-count-header rounded-2 retro-border-1 my-3">
      <a-col span="2" style="text-align: left" class="nav-i">
        <a-tooltip v-if="currentWorkRegion > 0" title="Previous">
          <LeftOutlined @click="handleChangeRegion(-1)" />
        </a-tooltip>
      </a-col>
      <a-col span="20" class="text-center">
        <span class="retro-font-weight-600">Work Region - {{ currentWorkRegion + 1 }}</span>
      </a-col>
      <a-col span="2" style="text-align: right" class="nav-i">
        <a-tooltip
          v-if="currentWorkRegion >= 0 && currentWorkRegion !== noOfWorkRegions - 1"
          title="Next"
        >
          <RightOutlined @click="handleChangeRegion(1)" />
        </a-tooltip>
      </a-col>
    </a-row>

    <a-row :gutter="[24, 24]">
      <a-col span="12">
        <a-card class="h-100">
          <template #cover>
            <a-image
              :preview="{
                onVisibleChange: (visible, prevVisible) => handlePreviewImage(visible, prevVisible)
              }"
              crossorigin="anonymous"
              :src="imageUrl"
              class="image"
            />
          </template>
          Zone 3 and Zone 4 should be avoided as they require individuals to deviate from their
          neutral positions and exert additional effort to access tools or equipment, increasing the
          risk of strain and potential injuries.
        </a-card>
      </a-col>
      <a-col span="12">
        <a-card title="Hand Occupancy Percentages" class="h-100" :bodyStyle="{ padding: 0 }">
          <a-table
            :columns="columns"
            :data-source="!isDemoOngoing ? tableData : stationDesignDummyObject.ergoTableData"
            :pagination="false"
            bordered
          />
        </a-card>
      </a-col>
      <a-col span="24">
        <a-list
          class="w-100"
          style="overflow-x: auto"
          bordered
          item-layout="horizontal"
          :data-source="suggestions"
        >
          <template #header>
            <a-typography-title :level="4">Workstation Layout Suggestions</a-typography-title>
          </template>
          <template #renderItem="{ item, index }">
            <a-list-item v-if="index == 0">
              {{ item }}
            </a-list-item>

            <a-list-item v-if="index != 0" style="display: flex; align-items: center; gap: 1.2em">
              <a-typography-text class="zone-text">{{ item }}</a-typography-text>

              <div class="zones-list">
                <div v-for="(item, ix) in recommendations[index - 1]['source_region']" :key="ix">
                  <div class="d-flex flex-column align-items-center">
                    <strong>Zone {{ regions[item] }}</strong>
                    <a-avatar :size="60" shape="square" style="background-color: #34a8eb">
                      <strong>{{ item }}</strong>
                    </a-avatar>
                    <strong>
                      {{
                        ergonomicsResult[currentWorkRegion].suggestion.percentages[
                          'Zone ' + regions[item]
                        ][item]
                      }}%
                    </strong>
                  </div>
                </div>

                <div>
                  <canvas
                    :ref="`canvas-${index}`"
                    class="arrow-canvas"
                    :class="`canvas-${index}`"
                    :width="canvas.width"
                    :height="canvas.height"
                  ></canvas>
                  <img
                    widht="0px"
                    heigt="0px"
                    :style="{ visibility: 'hidden' }"
                    crossorigin="anonymous"
                    style="width: 0px"
                    alt=""
                    :src="ergonomicsResult[currentWorkRegion].image"
                    :onload="handleMediaLoad"
                  />
                </div>

                <div class="right-zones">
                  <div v-for="(item, ix) in recommendations[index - 1]['dest_region']" :key="ix">
                    <div class="d-flex flex-column align-items-center">
                      <strong>Zone {{ regions[item] }}</strong>
                      <a-avatar :size="60" shape="square" style="background-color: #34a8eb">
                        <strong>{{ item }}</strong>
                      </a-avatar>
                      <strong>
                        {{
                          ergonomicsResult[currentWorkRegion].suggestion.percentages[
                            'Zone ' + regions[item]
                          ][item]
                        }}%
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </a-list-item>
          </template>
        </a-list>
      </a-col>
    </a-row>
    <br />
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useHandTrackingStore } from 'src/stores/handTracking'
import { useStationStore } from 'src/stores/station'
import { useUserStore } from 'src/stores/user'

import { RightOutlined, LeftOutlined } from '@ant-design/icons-vue'

export default {
  props: ['ergonomicsResult'],
  components: {
    RightOutlined,
    LeftOutlined
  },
  data() {
    return {
      regions: {
        A: 1,
        B: 1,
        C: 1,
        D: 2,
        E: 2,
        F: 2,
        G: 3,
        H: 3,
        I: 3,
        J: 4,
        K: 4,
        L: 4,
        '': 1
      },
      recommendations: [],
      suggestions: [],
      potentialSavingSuggestion: [],
      tableData: this.createTableData(0),
      canvas: {
        canvasWidth: null,
        canvasHeight: null
      },
      imageUrl: null,
      columns: [
        {
          title: 'Zone',
          dataIndex: 'zone',
          align: 'center',
          customRender: ({ text, record, index }) => {
            if (index == 0 || record.zone != this.tableData[index - 1].zone) {
              return {
                children: text,
                props: {
                  rowSpan: this.getRowspan(record.zone)
                }
              }
            } else {
              return {
                children: text,
                props: {
                  rowSpan: 0
                }
              }
            }
          }
        },
        {
          title: 'Region',
          dataIndex: 'region',
          align: 'center'
        },
        {
          title: 'Percentage',
          dataIndex: 'percentage',
          align: 'center',
          customRender: ({ text }) => {
            return text + ' %'
          }
        },
        {
          title: 'Accumulated Percentage',
          dataIndex: 'percentage',
          align: 'center',
          customRender: ({ text, record, index }) => {
            if (index == 0 || record.zone != this.tableData[index - 1].zone) {
              return {
                children: this.getTotalPercentage(record.zone) + ' %',
                props: {
                  rowSpan: this.getRowspan(record.zone)
                }
              }
            } else {
              return {
                children: text,
                props: {
                  rowSpan: 0
                }
              }
            }
          }
        }
      ],
      currentWorkRegion: 0
    }
  },

  computed: {
    ...mapState(useHandTrackingStore, ['potentialSavingsForLayoutSuggestions']),
    ...mapState(useStationStore, ['noOfWorkRegions', 'stationDesignDummyObject']),
    ...mapState(useUserStore, ['isDemoOngoing'])
  },

  watch: {
    currentWorkRegion(region_no) {
      this.getWorkRegionData()
      this.createTableData(region_no)
    }
  },

  methods: {
    getZone(letter) {
      return this.regions[letter]
    },

    getRowspan(zone) {
      const regionsInZone = this.tableData.filter((item) => item.zone === zone)
      return regionsInZone.length
    },

    handleChangeRegion(factor) {
      this.currentWorkRegion = this.currentWorkRegion + factor
    },

    getTotalPercentage(zone) {
      let total = 0
      let data = this.ergonomicsResult[this.currentWorkRegion].suggestion.percentages
      for (let item in data) {
        if (item === zone) {
          for (let region in data[item]) {
            total += data[item][region]
          }
        }
      }
      return total.toFixed(1)
    },

    createTableData(region) {
      let data = this.ergonomicsResult[region].suggestion.percentages
      let _tableData = []
      for (let zone in data) {
        for (let region in data[zone]) {
          _tableData.push({
            zone: zone,
            region: region,
            percentage: data[zone][region].toFixed(1)
          })
        }
      }
      this.tableData = _tableData
      return _tableData
    },

    drawArrows() {
      const items = this.recommendations // Replace with your item data

      for (let index = 1; index < items.length + 1; index++) {
        const canvas = this.$refs[`canvas-${index}`]
        const ctx = canvas?.getContext('2d')
        if (!ctx) return
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        // Draw the arrow
        // Modify the arrow drawing code here based on your requirements
        ctx.beginPath()
        ctx.moveTo(30, 80)
        ctx.lineTo(280, 80)
        ctx.lineTo(265, 70)
        ctx.moveTo(280, 80)
        ctx.lineTo(265, 90)
        ctx.strokeStyle = 'black'
        ctx.lineWidth = 2
        ctx.stroke()

        ctx.font = '14px Arial'
        ctx.textAlign = 'center'
        ctx.fillText('move to', 160, 100)
        this.canvas.canvasWidth = ctx.canvas.width
        this.canvas.canvasHeight = ctx.canvas.height
      }
      this.addPotentialSavingText()
    },

    addPotentialSavingText() {
      const savingSuggestions = this.potentialSavingSuggestion
      if (!savingSuggestions || !savingSuggestions.length) return

      savingSuggestions.forEach((el, idx) => {
        if (!el) return
        const canvas = this.$refs[`canvas-${idx + 1}`]
        if (!canvas) return
        const ctx = canvas.getContext('2d')

        ctx.textAlign = 'right'
        ctx.font = '11px Arial'
        ctx.letterSpacing = '0.4px'
        ctx.fillText(el.text, 255, 70)
      })
    },

    handlePreviewImage(visible, prevVisible) {
      setTimeout(() => {
        const els = document.getElementsByClassName('ant-image-preview-img')
        for (let i = 0; i < els.length; i++) {
          const el = els[i]
          el.setAttribute('crossorigin', 'anonymous')
        }
      })
    },

    handleMediaLoad() {
      this.drawArrows()
    },

    getWorkRegionData() {
      this.potentialSavingSuggestion =
        this.potentialSavingsForLayoutSuggestions?.[this.currentWorkRegion]
      const regionData = this.ergonomicsResult[this.currentWorkRegion]
      if (!regionData) return
      this.imageUrl = regionData.image
      this.recommendations = [...regionData.suggestion.recommendations]
      this.suggestions = regionData.suggestion.suggestion.split(' | ').filter((item) => item)
    }
  },

  mounted() {
    this.getWorkRegionData()
  }
}
</script>

<style>
.two-column-layout {
  display: flex;
  align-items: center;
}

.left-column {
  margin: 0 auto;
  width: 40%;
}

.right-column {
  width: 50%;
}

.image {
  border-radius: 5px;
  border: 1px solid lightgray;
  width: 100%;
  height: auto;
}

.nested-card {
  width: 100%;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.subtitle {
  margin-bottom: 10px;
  color: #1890ff;
}

.zone-text {
  margin-bottom: 10px;
  color: #555;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 10px;
}

.ant-col {
  padding-right: 10px;
}

.arrow-canvas {
  margin: 0;
  padding: 0;
}

.arrow-instruction {
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
}

.regions-count-header {
  width: 100%;
  padding: 10px 20px;
  background-color: #fafafa;
  color: #000000;
  font-size: 20px;
  margin-bottom: 10px;
}

.nav-i {
  transform: translateY(-40%);
  cursor: pointer;
  font-size: 16.4px;
}

.nav-i .anticon {
  transform: translateY(50%);
}

.zones-list {
  display: flex;
  gap: 1em;
  align-items: center;
}

.zone-text {
  flex-basis: 40%;
  /* text-align: justify; */
  word-spacing: 2px;
  display: flex;
}

.right-zones {
  flex-basis: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1em;
}
</style>
