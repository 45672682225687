import ApiHelper from './index'

const getErgAssessmentList = async (params) => {
  return await ApiHelper.get('ergonomics/run/', params)
}

const getErgonomicAssessmentData = async (id, param) => {
  return await ApiHelper.get(`ergonomics/getresult/${id}/${param}`)
}

const getHandErgonomicsData = async (id) => {
  return await ApiHelper.get(`ergonomics/hand_strain/${id}/records/`)
}

const updateErgonomicRecord = async (id, payload) => {
  return await ApiHelper.patch(`ergonomics/run/${id}`, payload, false)
}

const createErgonomicRecord = async (payload) => {
  return await ApiHelper.post('ergonomics/run/', payload, false)
}

const getErgonomicRecord = async (id) => {
  return await ApiHelper.get(`ergonomics/run/${id}`)
}

const deleteErgonomicRecord = async (id) => {
  return await ApiHelper.remove(`ergonomics/run/${id}`)
}

const addHighRiskIntervals = async (id, payload) => {
  return await ApiHelper.post(`ergonomics/addposture/${id}`, payload)
}

const addHandErgonomicsInstance = async (id, payload) => {
  return await ApiHelper.post(`ergonomics/hand_strain/${id}/records/`, payload)
}

const deleteHandErgonomicsInstance = async (id) => {
  return await ApiHelper.remove(`ergonomics/hand_strain_record/${id}/`)
}

const deleteInterval = async (id, key) => {
  return await ApiHelper.remove(`ergonomics/posture/${id}/${key}`)
}

const updateIntervalScores = async (param, payload) => {
  return await ApiHelper.patch(`ergonomics/posture/${param.id}/${param.key}`, payload)
}

const nioshInputsUpdate = async (params, payload) => {
  const { id, key, LIKey } = params
  return await ApiHelper.post(`ergonomics/nioshupdate/${id}/${key}/${LIKey}`, payload)
}

const snookInputUpdate = async (params, payload) => {
  const { id, key, LIKey } = params
  return await ApiHelper.post(`ergonomics/snookupdate/${id}/${key}/${LIKey}`, payload)
}

const snookNoteInputUpdate = async (id, payload) => {
  return await ApiHelper.patch(`ergonomics/add_note/${id}`, payload)
}

const handErgonomicsInputUpdate = async (id, payload) => {
  return await ApiHelper.patch(`ergonomics/hand_strain_record/${id}/`, payload)
}

const validateSnookNioshAssessmentName =async (payload) => {
  return await ApiHelper.get(`ergonomics/unique_project_name/${encodeURIComponent(payload.project_name)}`)
}

const getRecommendation =  async (id, payload) => {
  return await ApiHelper.get(`ergonomics/v1/generate_recommendations/${id}`, payload)
}

export default {
  validateSnookNioshAssessmentName,
  getErgAssessmentList,
  getErgonomicAssessmentData,
  createErgonomicRecord,
  addHighRiskIntervals,
  deleteInterval,
  getErgonomicRecord,
  updateIntervalScores,
  deleteErgonomicRecord,
  nioshInputsUpdate,
  snookInputUpdate,
  snookNoteInputUpdate,
  getHandErgonomicsData,
  handErgonomicsInputUpdate,
  addHandErgonomicsInstance,
  deleteHandErgonomicsInstance,
  updateErgonomicRecord,
  getRecommendation
}