import ApiHelper from './index'

const getErgonomicSnookPushingSheet = async (studyId) => {
  return await ApiHelper.post(`ergonomics/excelreport/${studyId}/SNOOK_Pushing`)
}

const getErgonomicSnookPullingSheet = async (studyId) => {
  return await ApiHelper.post(`ergonomics/excelreport/${studyId}/SNOOK_Pulling`)
}

const getErgonomicSnookCarryingSheet = async (studyId) => {
  return await ApiHelper.post(`ergonomics/excelreport/${studyId}/SNOOK_Carrying`)
}

const getErgonomicSnookLiftingSheet = async (studyId) => {
  return await ApiHelper.post(`ergonomics/excelreport/${studyId}/SNOOK_Lifting`)
}

const getErgonomicNioshSheet = async (studyId) => {
  return await ApiHelper.post(`ergonomics/excelreport/${studyId}/NIOSH`)
}

const getErgonomicRebaSheet = async (studyId) => {
  return await ApiHelper.post(`ergonomics/excelreport/${studyId}/Reba_Intervals`)
}

const getErgonomicRulaSheet = async (studyId) => {
  return await ApiHelper.post(`ergonomics/excelreport/${studyId}/Rula_Intervals`)
}

const getErgonomicHandGripSheet = async (studyId) => {
  return await ApiHelper.post(`ergonomics/excelreport/${studyId}/Hand_Grip_Insertion_Force`)
}

const getStationDesignSheet = async (studyId) => {
  return await ApiHelper.get(`station_improvement/station-design/excel_export/${studyId}`)
}

const getWorkInstructionSheet = async (studyId) => {
  return await ApiHelper.get(`station_improvement/work-instruction/excel_export/${studyId}`)
}

const getCycleTimeSheet = async (studyId) => {
  return await ApiHelper.get(`station_improvement/station-design/excel_export_cycle_time_measurement/${studyId}`)
}

const getOperatorBalanceSheet = async (id, payload) => {
  return await ApiHelper.post(`line_balancing/line/${id}/export_excel/`, payload)
}

const getSWCTChartSheet = async (id, payload) => {
  return await ApiHelper.post(`line_balancing/line/${id}/export_excel/swct/`, payload)
}

const getCycleTimeDiagramSheet = async (id, payload) => {
  return await ApiHelper.post(`line_balancing/line/${id}/forvia_ctd_export_excel/`, payload)
}

export default {
  getErgonomicSnookPushingSheet,
  getErgonomicSnookPullingSheet,
  getErgonomicSnookCarryingSheet,
  getErgonomicSnookLiftingSheet,
  getErgonomicRulaSheet,
  getErgonomicHandGripSheet,
  getErgonomicNioshSheet,
  getStationDesignSheet,
  getErgonomicRebaSheet,
  getWorkInstructionSheet,
  getCycleTimeSheet,
  getOperatorBalanceSheet,
  getSWCTChartSheet,
  getCycleTimeDiagramSheet,
}
