<template>
  <div v-if="loading" class="center p-2">
    <a-spin size="large" />
  </div>

  <div v-else class="container">
    <div class="content-wrapper">
      <div class="text-container">
        <p v-if="timeStudyProcessCompleted" class="mb-3">
          Segments can not be updated once processed.
        </p>
        <p v-else class="mb-3">
          We will automatically divide each cycle into the number of tasks or steps you specify.
        </p>
      </div>

      <div
        id="sd-divide-screen-work-area"
        class="automatic-segment-wrapper"
        v-if="currentSegmentOption === 'automatically'"
      >
        <p class="mx-1">How many steps are there in this operation?</p>

        <div class="segment-buttons-wrapper mb-4">
          <button
            v-for="option in segmentOptions"
            :key="option"
            :class="{
              active: isDemoOngoing
                ? stationDesignDummyObject.selectedSegment === option
                : selectedSegment === option,
              disabled: isStudyInProgress && !this.numberOfSteps
            }"
            @click="handleClickSegmentButton(option)"
          >
            {{ option }}
          </button>
        </div>

        <div class="p-2" style="display: flex; flex-direction: column">
          <a-input
            :maxlength="2"
            size="large"
            placeholder="Type a number"
            :value="!isDemoOngoing ? segmentInputValue : stationDesignDummyObject.selectedSegment"
            :disabled="
              Boolean(numberOfSteps && !timeStudyProcessCompleted) ||
              timeStudyProcessCompleted ||
              isStudyInProgress
            "
            style="flex: 1; margin-right: 8px; width: calc(100% - 24px)"
            @change="(e) => handleChangeInput(e)"
          >
          </a-input>
          <a-typography-text class="pt-2" v-if="!newStudy?.station?.no_of_steps">
            Based on cycle length, we guess its about :
            <strong>{{ estimatedSteps }}</strong></a-typography-text
          >
        </div>
      </div>

      <div v-else class="manually-segement-wrapper text-center">
        <p>Watch the video and click <i>New step</i> each time a new step begins.</p>

        <video
          crossorigin="anonymous"
          ref="video"
          muted
          controls
          class="m-auto"
          :src="serveVideoUrl + '?t=192380192415665'"
          style="
            height: 480px;
            width: 640px;
            object-fit: fill;
            border-radius: 5px;
            border: 1px solid lightgray;
          "
        ></video>

        <div class="d-flex mt-3 justify-content-center">
          <a-button>Undo</a-button>
          <a-button class="mx-2">Redo</a-button>
          <a-button type="primary" class="d-flex align-items-center" @click="handleAddNewStep">
            <template #icon><PlusOutlined /></template>
            New Step
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { PlusOutlined } from '@ant-design/icons-vue'
import { mapState, mapActions } from 'pinia'
import { useStationStore } from 'src/stores/station'
import { useUserStore } from 'src/stores/user'
import { useToast } from 'vue-toastification'
import eventBus from 'src/utils/eventBus'
import { useSegmentationStore } from 'src/stores/segmentation'

export default {
  components: { PlusOutlined },
  inject: ['nextTab', 'showError', 'showInfo'],
  setup: () => ({ toast: useToast() }),
  data() {
    return {
      segmentOptions: Array.from({ length: 20 }, (_, i) => i + 1),
      selectedSegment: null,
      segmentInputValue: null,
      loading: true,
      estimatedSteps: 0,
      // manual step definition
      currentSegmentOption: 'automatically',
      framePerSecond: 30,
      steps: [],
      serveVideoUrl: null
    }
  },
  computed: {
    ...mapState(useStationStore, [
      'stationDesignDummyObject',
      'numberOfSteps',
      'numSegments',
      'newStudy',
      'timeStudyProcessCompleted',
      'isStudyInProgress'
    ]),
    ...mapState(useUserStore, ['isDemoOngoing']),

    isStudyFailed() {
      return this.newStudy.status === 'Failed'
    }
  },

  watch: {
    numberOfSteps(value) {
      if (!value) return
      this.selectedSegment = value
    },

    segmentInputValue(value) {
      this.selectedSegment = value ? Number(value) : null
      this.updateRouteCheck()
    }
  },

  methods: {
    ...mapActions(useStationStore, ['setNumSegments', 'setSegmentsSelected']),
    ...mapActions(useSegmentationStore, ['getEstimatedNoOfSteps']),

    handleAddNewStep() {
      const frame = this.$refs.video?.currentTime?.toFixed(2) * this.framePerSecond
      this.steps.push(frame)
    },

    updateRouteCheck() {
      const isSelected = this.selectedSegment || this.segmentInputValue
      if (isSelected) this.setSegmentsSelected(true)
      else this.setSegmentsSelected(false)
    },

    handleChangeInput(e) {
      if (this.isDemoOngoing) return
      const { value } = e.target
      if (Number.isNaN(Number(value)) || Number(value) <= 0) {
        this.segmentInputValue = ''
        e.target.value = ''
        return
      }
      this.segmentInputValue = value
      this.updateRouteCheck()
    },

    handleClickSegmentButton(option) {
      if (this.isDemoOngoing) return
      if (this.timeStudyProcessCompleted || this.isStudyFailed) return
      if (this.numberOfSteps && !this.timeStudyProcessCompleted) {
        this.showInfo('Segmentation is already running, Please try later!')
        return
      }
      let reset = false
      if (this.selectedSegment === option) {
        this.selectedSegment = null
        if (this.segmentInputValue <= 20) this.segmentInputValue = null
        reset = true
      }
      if (!reset) {
        this.segmentInputValue = option
        this.selectedSegment = option
      }
      this.updateRouteCheck()
    },

    async handleDivide() {
      if (this.isStudyInProgress) {
        this.nextTab()
        return
      }

      if (!this.segmentInputValue || !this.selectedSegment) {
        this.showError('Please select no of segments!')
        return
      }
      this.setNumSegments(this.selectedSegment || this.segmentInputValue)
      this.nextTab()
    }
  },

  async created() {
    if (this.isDemoOngoing) return
    eventBus.$on('divide-next-page', this.handleDivide)
    if (this.numberOfSteps) {
      this.selectedSegment = this.numberOfSteps
      this.segmentInputValue = this.numSegments ? this.numSegments : this.numberOfSteps
      this.loading = false
    }
    this.updateRouteCheck()

    if (!this.numberOfSteps) {
      this.estimatedSteps = await this.getEstimatedNoOfSteps()
      this.loading = false
    }
  },

  beforeUnmount() {
    eventBus.$off('divide-next-page', this.handleDivide)
  }
}
</script>
<style scoped>
.content-wrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-container {
  width: 800px;
  padding: 0 3px;
  text-align: left;
}

.automatic-segment-wrapper {
  padding: 10px 0 10px 0;
  max-width: 800px;
}

.manually-segement-wrapper {
  padding: 1px 0 25px 0;
  max-width: 800px;
}

.automatic-segment-wrapper p {
  margin-bottom: 20px;
  font-weight: bold;
  color: #333;
}

.segment-buttons-wrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  align-items: center;
  justify-content: center;
}
.segment-buttons-wrapper button {
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  background: transparent;
  width: 70px;
  height: 70px;
}

.segment-buttons-wrapper button.active {
  background: #007bff;
  color: #fff;
}

.segment-buttons-wrapper button.disabled {
  background: #f2f2f2;
  pointer-events: none;
}

.processing {
  font-size: xxx-large;
  text-align: start;
  color: #0d6efd;
}
</style>
