import { createPinia } from 'pinia'
import { createApp } from 'vue'

import App from './App.vue'
import router from './router'

import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/reset.css'
import 'bootstrap/dist/css/bootstrap.css'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import VueKonva from 'vue3-konva'

// Highcharts
import * as Sentry from '@sentry/vue'
import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'
import HighchartsMore from 'highcharts/highcharts-more'
import AccessibilityModule from 'highcharts/modules/accessibility'
import BoostModule from 'highcharts/modules/boost'
import StockCharts from 'highcharts/modules/drilldown'
import ExportDataModule from 'highcharts/modules/export-data'
import { default as ExportingModule, default as StockCharts2 } from 'highcharts/modules/exporting'
import Gantt from 'highcharts/modules/gantt'
import Heatmap from 'highcharts/modules/heatmap'
import OrgChart from 'highcharts/modules/organization'
import PatternFill from 'highcharts/modules/pattern-fill'
import Sankey from 'highcharts/modules/sankey'
import Stock from 'highcharts/modules/stock'

import cdnDirectives from './directives/cdnDirectives'
import './config/firebase'

Stock(Highcharts)
StockCharts(Highcharts)
StockCharts2(Highcharts)
Sankey(Highcharts)
OrgChart(Highcharts)
Gantt(Highcharts)
Heatmap(Highcharts)
PatternFill(Highcharts)
HighchartsMore(Highcharts)
ExportingModule(Highcharts)
ExportDataModule(Highcharts)
AccessibilityModule(Highcharts)
// BoostModule(Highcharts)

const app = createApp(App)

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.MODE,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,

  tracePropagationTargets: [],

  replaysSessionSampleRate: import.meta.env.MODE === 'production' ? 0.1 : 0.5,

  replaysOnErrorSampleRate: 1.0
})

app.directive('cdn', cdnDirectives)

app.use(createPinia())
app.use(router)
app.use(Antd)
app.use(VueKonva)
app.use(Toast)
app.use(HighchartsVue)
app.mount('#app')
