<template>
  <div
    class="position-relative d-flex flex-column align-items-center"
    :style="{ ...wrapperStyles }"
  >
    <div class="video-container" ref="videoContainer">
      <video
        ref="video"
        muted
        crossorigin="anonymous"
        disablePictureInPicture
        class="preview-segment-video"
        :class="[isFullscreen ? 'preview-video-fullscreen' : 'preview-video-minimize']"
        :style="!isFullscreen && { ...styles }"
        :src="videoUrl"
        :key="videoUrl"
        :playbackRate="playbackRate ? playbackRate : 1"
        @seeked="onVideoSeeked"
        @loadedmetadata="onVideoMetaLoaded"
        @playing="isPlayingSegment = true"
      ></video>

      <div
        v-if="videoVisible && (playbackRate || allowFullScreen)"
        :class="['video-playback-controls', isFullscreen ? 'video-fullscreen-controls' : '']"
      >
        <a-flex class="w-100" align="center" justify="space-between">
          <span class="video-fullscreen-time">
            <pause-outlined v-if="isPlayingSegment" @click="pauseSegment" />
            <caret-right-outlined v-else @click="playSegment" />
            <small class="ms-4"> {{ segmentStartTime }}</small>
            <small> / </small>
            <small>{{ segmentPlay.end ? getIntervalTime(segmentPlay.end * 1000) : 0 }}</small>
          </span>

          <a-space>
            <a-tooltip
              v-if="playbackRate && !isFullscreen && !isPlayingSegment"
              placement="left"
              title="Playback speed"
            >
              <a-select
                :value="playbackRate"
                class="playback-speed-dropdown"
                popupClassName="playback-speed-dropdown-options"
                size="medium"
                placement="topRight"
                :showArrow="false"
                :options="playbackRatesOptions"
                @change="handlePlaybackRateChange"
              />
            </a-tooltip>

            <expand-outlined
              v-if="!isFullscreen"
              @click="toggleFullscreen"
              class="cursor-pointer fs-6 text-white"
            />
            <compress-outlined
              v-else
              @click="toggleFullscreen"
              class="cursor-pointer fs-6 text-white"
            />
          </a-space>
        </a-flex>

        <div
          v-if="isFullscreen"
          ref="fullScreenTimeline"
          class="video-timeline fullscreen-progress-bar"
          @click="(e) => handleClickTimeline(e, 'fullScreenTimeline')"
        >
          <div
            class="h-100 current-progress"
            :style="{ width: (progress / (segmentPlay.end - segmentPlay.start)) * 100 + '%' }"
          >
            <span :class="['seeker', progress > 0.1 ? 'visible' : '']"></span>
          </div>
        </div>
      </div>
    </div>

    <a-flex class="w-100" justify="space-between">
      <a-flex style="width: 50px">
        {{ segmentStartTime }}
      </a-flex>

      <a-flex v-if="videoVisible" class="player-icons" gap="small">
        <step-backward-outlined @click="handleMoveBackward" />
        <pause-outlined v-if="isPlayingSegment" @click="pauseSegment" />
        <caret-right-outlined v-else @click="playSegment" />
        <step-forward-outlined @click="handleMoveForward" />
      </a-flex>

      <a-flex style="width: 50px" justify="flex-end">{{
        segmentPlay.end ? getIntervalTime(segmentPlay.end * 1000) : 0
      }}</a-flex>
    </a-flex>

    <div ref="timeline" class="video-timeline" @click="(e) => handleClickTimeline(e, 'timeline')">
      <div
        class="h-100 current-progress"
        :style="{ width: (progress / (segmentPlay.end - segmentPlay.start)) * 100 + '%' }"
      >
        <span :class="['seeker', progress > 0 ? 'visible' : '']"></span>
      </div>
    </div>
  </div>
</template>
<script>
import { getIntervalTime, getFormattedTime } from 'src/utils/outline'
import {
  CaretRightOutlined,
  PauseOutlined,
  StepForwardOutlined,
  StepBackwardOutlined,
  ExpandOutlined,
  CompressOutlined
} from '@ant-design/icons-vue'

export default {
  props: [
    'styles',
    'videoUrl',
    'segmentPlay',
    'durationTime',
    'framePerSec',
    'wrapperStyles',
    'playbackRate',
    'allowFullScreen'
  ],
  emits: ['selectCycle', 'deleteCycle', 'updatePlaybackRate'],
  components: {
    CaretRightOutlined,
    PauseOutlined,
    StepForwardOutlined,
    StepBackwardOutlined,
    ExpandOutlined,
    CompressOutlined
  },
  setup: () => ({
    getIntervalTime,
    getFormattedTime
  }),
  data() {
    return {
      progress: 0,
      isFullscreen: false,
      isPlayingSegment: false,
      segmentPlayInterval: null,
      videoVisible: false,
      videoDurationTime: 1
    }
  },
  watch: {
    segmentPlay() {
      this.onVideoMetaLoaded()
    }
  },
  computed: {
    playbackRatesOptions() {
      return [1, 2, 4, 8, 16].map((rate) => ({
        value: rate,
        label: `${rate}x`
      }))
    },

    segmentStartTime() {
      if (!this.segmentPlay) return 0
      return this.durationTime < 1
        ? this.getIntervalTime(this.segmentPlay.start * 1000)
        : this.getIntervalTime((this.segmentPlay.start + this.progress) * 1000)
    }
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyDown)
    document.addEventListener('fullscreenchange', this.handleFullScreenChange)
  },

  beforeUnmount() {
    this.pauseSegment()
    this.videoVisible = false
    clearInterval(this.segmentPlayInterval)
    window.removeEventListener('keydown', this.handleKeyDown)
    document.removeEventListener('fullscreenchange', this.handleFullScreenChange)
  },

  methods: {
    handleKeyDown(event) {
      if (event.key === 'Escape') this.toggleFullscreen()
    },

    handlePlaybackRateChange(value) {
      this.$emit('updatePlaybackRate', value)
    },

    handleFullScreenChange() {
      this.isFullscreen = !!document.fullscreenElement
    },

    toggleFullscreen() {
      const videoContainer = this.$refs.videoContainer
      if (!document.fullscreenElement) {
        videoContainer
          .requestFullscreen()
          .then(() => {
            this.isFullscreen = true
          })
          .catch((err) => console.error(`fullscreen err: ${err.message}`))
      } else {
        document.exitFullscreen().then(() => {
          this.isFullscreen = false
          if (this.isPlayingSegment) {
            this.pauseSegment()
          }
        })
      }
    },

    getInitialTime() {
      if (this.$refs.video) {
        return getFormattedTime(this.$refs.video.currentTime)
      } else {
        return getIntervalTime(this.progress * 1000)
      }
    },

    onVideoMetaLoaded() {
      this.progress = 0
      this.videoDurationTime = this.durationTime
      if (!this.$refs.video || !this.segmentPlay) return
      this.$refs.video.currentTime = this.segmentPlay.start
      if (!this.$refs.video.paused && this.isPlayingSegment) {
        this.$refs.video?.pause()
        this.isPlayingSegment = false
      }
      clearInterval(this.segmentPlayInterval)
      this.segmentPlayInterval = null
    },

    onVideoSeeked() {
      if (
        !this.videoVisible &&
        Math.floor(this.$refs.video?.currentTime) == Math.floor(this.segmentPlay?.start)
      ) {
        this.videoVisible = true
      }
    },

    pauseSegment() {
      if (!this.$refs.video) return
      if (!this.$refs.video.paused && this.isPlayingSegment) {
        this.$refs.video.pause()
        this.isPlayingSegment = false
      }
      clearInterval(this.segmentPlayInterval)
    },

    async playSegment() {
      if (!this.videoVisible || !this.$refs.video) return
      if (this.$refs.video.paused && !this.isPlayingSegment) {
        await this.$refs.video?.play()
        this.segmentTimeUpdate()
        if (this.segmentPlayInterval) clearInterval(this.segmentPlayInterval)
        this.segmentPlayInterval = setInterval(this.segmentTimeUpdate, 100 / this.framePerSec)
      }
    },

    segmentTimeUpdate() {
      if (this.videoDurationTime &&  this.progress >= this.videoDurationTime) {
        this.progress = 0
        this.$refs.video.currentTime = this.segmentPlay.start
        this.pauseSegment()
        return
      }
      if (this.$refs.video) {
        this.progress = this.$refs.video?.currentTime - this.segmentPlay.start
      }
    },

    handleMoveForward() {
      if (!this.videoVisible) return
      const { video } = this.$refs
      if (video.currentTime + 3 >= this.segmentPlay.end) {
        video.currentTime = this.segmentPlay.end
        this.progress = 0
        this.pauseSegment()
        return
      }
      this.$refs.video.currentTime = video.currentTime + 3
      this.progress = this.$refs.video?.currentTime - this.segmentPlay.start
    },

    handleMoveBackward() {
      if (!this.videoVisible) return
      const { video } = this.$refs
      if (video.currentTime - 3 <= this.segmentPlay.start) {
        video.currentTime = this.segmentPlay.start
        this.progress = 0
        this.pauseSegment()
        return
      }
      this.$refs.video.currentTime = video.currentTime - 3
      this.progress = this.$refs.video?.currentTime - this.segmentPlay.start
    },

    handleClickTimeline(e, timelineRef) {
      if (!this.$refs.video || !this.videoVisible || e.target.className?.includes('seeker')) return
      const clientX = e.offsetX
      const timeline = this.$refs[timelineRef]
      if (!clientX || !timeline) return
      const timelineWidth = timeline?.getBoundingClientRect()?.width
      const time = (clientX / timelineWidth) * Number(this.videoDurationTime)
      this.$refs.video.currentTime = time + this.segmentPlay.start
      this.progress = this.$refs.video?.currentTime - this.segmentPlay.start
    }
  }
}
</script>

<style scoped>
.preview-segment-video {
  width: 100%;
  border-radius: 0.5em;
  display: block;
  border: 1px solid lightgray;
}

.preview-video-fullscreen {
  border: none;
}

.preview-video-minimize {
  max-height: 350px;
}

.video-container {
  position: relative;
}

.preview-segment-video {
  width: 100%;
  height: auto;
}

:fullscreen .preview-segment-video {
  /* width: 100vw; */
  height: 100%;
}

.player-icons {
  font-size: 20px;
  cursor: pointer;
}

.video-playback-controls {
  display: flex;
  padding: 10px;
  width: 100%;
  justify-content: end;
  align-items: center;
  position: absolute;
  bottom: 0;
  background: linear-gradient(#00000000, #000000); /* Gradient from orange to peach */
}

.video-fullscreen-controls {
  flex-direction: column;
  align-items: end;
  padding: 40px 40px;
}

:deep(.playback-speed-dropdown .ant-select-selector) {
  padding: 0px 20px !important;
  border-radius: 0 !important;
  height: 20px !important;
}

:deep(.playback-speed-dropdown .ant-select-selector .ant-select-selection-item) {
  line-height: 20px !important;
}

.video-timeline {
  height: 8px;
  background: lightgray;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  margin: 4px 0;
  /* overflow: hidden; */
}

.fullscreen-progress-bar {
  background: rgba(255, 255, 255, 0.3);
  margin-top: 10px;
}

.video-timeline .current-progress {
  background: rgb(24, 144, 255);
  border-radius: 5px;
  position: relative;
}

.seeker {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
  top: -50%;
  right: 0;
  display: flex;
  justify-content: center;
  transform: translateX(50%);
  z-index: 99;
  background-color: rgb(24, 144, 255);
  visibility: hidden;
}

.seeker.visible {
  visibility: visible;
}

.video-fullscreen-time {
  font-size: 18px;
  display: flex;
  color: #fff;
  gap: 4px;
  justify-content: center;
  align-items: center;
  visibility: hidden;
}

.video-fullscreen-controls .video-fullscreen-time {
  visibility: visible;
}

.playback-speed-dropdown {
  z-index: 1200;
}
</style>
<style>
.playback-speed-dropdown-options.ant-select-dropdown {
  border-radius: 0 !important;
  width: 53px !important;
}
</style>
