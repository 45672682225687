import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'

dayjs.extend(duration)
dayjs.extend(utc)

export function getIntervalTime(timeInMs) {
  if (timeInMs < 0) return '0'
  const dur = dayjs.duration(Number(timeInMs))
  const format = getTimeFormat(timeInMs)
  const time = dayjs.utc(dur.asMilliseconds()).format(format)
  return time
}

function getTimeFormat(time, unit = 'ms') {
  const multiplier = unit === 's' ? 1 : 1000
  switch (true) {
    case time < 10 * 60 * multiplier: //durations less than 10 minutes
      return 'm:ss'
    case time < 60 * 60 * multiplier: //durations less than 1 hour
      return 'mm:ss'
    case time < 3600 * 10 * multiplier: //durations less than 10 hours
      return 'h:mm:ss'
    case time >= 3600 * 10 * multiplier: //durations equal to or greater than 10 hours
      return 'hh:mm:ss'
    default:
      return time
  }
}

export const getFormattedTime = (timeInSeconds) => {
  const durationObject = dayjs.duration(timeInSeconds, 'seconds')
  let timeInMs = durationObject.asMilliseconds()
  const format = getTimeFormat(timeInMs)
  const formattedTime = dayjs.utc(timeInMs).format(format)
  return formattedTime
}

export const randDarkColor = () => {
  const lum = -0.25
  let hex = String('#' + Math.random().toString(16).slice(2, 8).toUpperCase()).replace(
    /[^0-9a-f]/gi,
    ''
  )
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }
  let rgb = '#',
    c,
    i
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16)
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16)
    rgb += ('00' + c).substr(c.length)
  }
  return rgb
}


