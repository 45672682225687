export default [
    {
        title: '#',
        dataIndex: 'index',
        width: '5%',
        align: 'center'
    },
    {
        title: 'Color',
        dataIndex: 'color',
        width: '5%',
        align: 'center'
    },
    {
        title: 'Mark Assembly Area',
        dataIndex: 'isMarking',
        width: '15%',
        align: 'center'
    },
    {
        title: 'Dimension (in)',
        dataIndex: 'lineDistance',
        width: '20%',
        align: 'center',
        children: [
            {
                title: 'Width (in)',
                dataIndex: 'width',
                width: '20%',
                align: 'center'
            },
            {
                title: 'Length (in)',
                dataIndex: 'length',
                width: '20%',
                align: 'center'
            },
        ],
    },
    {
        title: 'Locate Operator',
        dataIndex: 'loc_operator',
        width: '15%',
        align: 'center'
    },

]

export const cellOutlineColumns = [
    {
        title: '#',
        dataIndex: 'index',
        width: '5%',
        align: 'center'
    },
    {
        title: 'Color',
        dataIndex: 'color',
        width: '5%',
        align: 'center'
    },
    {
        title: 'Mark Region',
        dataIndex: 'isMarking',
        width: '15%',
        align: 'center'
    },
    {
        title: 'Dimension (in)',
        dataIndex: 'lineDistance',
        width: '20%',
        align: 'center',
        children: [
            {
                title: 'Width (in)',
                dataIndex: 'width',
                width: '20%',
                align: 'center'
            },
            {
                title: 'Length (in)',
                dataIndex: 'length',
                width: '20%',
                align: 'center'
            },
            
        ],    },

        {
            title: 'Locate Operator',
            dataIndex: 'loc_operator',
            width: '15%',
            align: 'center'
        },
]
