<template>
  <div class="position-relative">
    <ul class="value-type-list">
      <li v-for="[label, value] in valueTypeData" :key="label">
        <strong>{{ getValueType(label) }} : </strong>
        &nbsp;{{ round(value, 1) }}%
      </li>
    </ul>
    <highcharts v-if="chartOptions" type="chart" :options="chartOptions" />
  </div>
</template>
<script>
import { VA_Keys } from '../config'

import { round } from 'lodash-es'
export default {
  setup: () => ({ round }),
  props: ['title', 'valueTypeData'],
  mounted() {
    this.setChartOptions()
  },
  data() {
    return {
      chartOptions: null
    }
  },
  methods: {
    getValueType(assesment) {
      return VA_Keys[assesment]
    },
    setChartOptions() {
      this.chartOptions = {
        chart: {
          type: 'pie',
          zoomType: 'xy',
          style: {
            padding: '1em'
          }
        },
        credits: {
          enabled: false
        },
        title: {
          text: this.title
        },
        legend: {
          enabled: false
        },
        tooltip: {
          pointFormatter: function () {
            const { y } = this.options
            return `<span style="font-weight: bold">${y}%</span>
            `
          }
        },

        plotOptions: {
          series: {
            size: '60%',
            innerSize: '60%',
            dataLabels: {
              alignTo: 'plotEdges',
              enabled: true,
              padding: 10,
              style: {
                fontWeight: 'bold',
                fontSize: '14px',
                width: '100%'
              }
            }
          }
        },
        series: [
          {
            name: '',
            data: this.valueTypeData.map(([label, value]) => [label, Number(value.toFixed(1))])
          }
        ],
        exporting: {
          enabled: false
        }
      }
    }
  }
}
</script>
<style>
.value-type-list {
  position: absolute;
  right: 0;
  display: inline-block;
  padding: 0px 10px 0px 20px;
  z-index: 99;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  background-color: #fff;
}
</style>
