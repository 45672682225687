import { defineStore } from 'pinia'
import StationService from 'src/services/station'

export const useStationAudit = defineStore('workStationAudit', {
    state: () => ({
        studySteps: [],
        studyAnswers: [],
        IsAIModalOpen: false,
        kaizenPoint: null,
        currentStudy: null,
        auditQuestionsAnswers: {},
        auditFollowUpAnswersList: {},
        updateWorkAuditStationLoader: false,
        workStationAuditResponseAnalysisLoader: false,
        workStationMaturityLoader: false,
        workStationMaturity: null,
        workStationAuditResponseAnalysis: [],
        workStationAuditSuggestionLoader: false,
        WorkAuditImprovementSuggestions: [],
    }),
    getters: {
        auditAnswers: (state) => {
            return state.auditQuestionsAnswers
        },

        auditFollowUpAnswers: (state) => {
            return state.auditFollowUpAnswersList
        },
    },
    actions: {
        setAuditAnswers(value) {
            this.auditQuestionsAnswers = value
        },

        setAuditFollowUpAnswers(value) {
            this.auditFollowUpAnswersList = value
        },

        setWorkStationAuditResponseAnalysis(value) {
            this.workStationAuditResponseAnalysis = value
        },

        async fetchWorkStationAuditResponses(study_id) {
            if (study_id !== this.currentStudy) {
                this.studyAnswers = []
                this.currentStudy = study_id
                const [error, data] = await StationService.fetchWorkStationAuditResponses(study_id)
                if (error) {
                    return
                }
                this.studyAnswers = data.work_audit_responses
                this.studySteps = data.step

                data.work_audit_responses.forEach((workAuditResponse) => {
                    if (workAuditResponse.steps.length > 0) {
                        this.auditFollowUpAnswersList[workAuditResponse.id] = workAuditResponse.steps.map(
                            (st) => st.id
                        )
                    }
                })
            }
        },

        async clearWorkStationAuditResponses(study_id, response) {
            this.updateWorkAuditStationLoader = true
            const [error] = await StationService.updateWorkStationAuditResponses(study_id, response)
            if (error) {
                return
            }
            this.studyAnswers = this.studyAnswers.map((data) => ({ ...data, response: null, steps: [] }))
            this.updateWorkAuditStationLoader = false
        },

        async updateWorkStationAuditResponses(study_id, response) {
            this.updateWorkAuditStationLoader = true
            const [error] = await StationService.updateWorkStationAuditResponses(study_id, response)
            if (error) {
                return
            }
            this.updateWorkAuditStationLoader = false
        },

        combineData(analyzedData, suggestionData) {
            let sortedAnalyzedData = analyzedData?.sort((a, b) => a - b)
            let sortedSuggestionData = suggestionData?.sort(
                (a, b) => a.work_audit_response.id - b.work_audit_response.id
            )
            let combinedData = []
            sortedAnalyzedData?.forEach((analyzedData) => {
                combinedData.push(analyzedData)
                sortedSuggestionData?.forEach((suggestionData) => {
                    if (suggestionData.work_audit_response.id == analyzedData.id) {
                        combinedData.push({ question: suggestionData })
                    }
                })
            })
            return combinedData
        },

        async fetchWorkStationAuditResponseAnalysis(study_id) {
            this.workStationAuditResponseAnalysisLoader = true
            const [error, data] = await StationService.fetchWorkStationAuditResponseAnalysis(study_id)
            if (error) {
                return
            }
            const analyzedData = data.analyzed_data || []
            const suggestionData = data.suggestion || []
            const combinedData = this.combineData(analyzedData, suggestionData)

            this.workStationAuditResponseAnalysis = combinedData
            this.workStationAuditResponseAnalysisLoader = false
        },

        async fetchWorkStationAuditSuggestions(payload) {
            this.workStationAuditSuggestionLoader = true
            console.log('APi payload:', payload)
            const [error, data] = await StationService.fetchWorkStationAuditSuggestions(payload)
            if (error) {
                return
            }
            this.WorkAuditImprovementSuggestions = data.body.response.map((suggestions, i) => ({
                key: i,
                ...suggestions
            }))
            this.workStationAuditSuggestionLoader = false
        },

        async fetchWorkStationAuditResponseResult(payload) {
            this.workStationMaturityLoader = true
            const [error, data] = await StationService.fetchWorkStationAuditResponseResult(payload)
            if (error) {
                return
            }
            this.workStationMaturity = data.maturity
            this.workStationMaturityLoader = false
        },

        toggleAiModal(kaizenPoint) {
            this.kaizenPoint = kaizenPoint
            this.IsAIModalOpen = !this.IsAIModalOpen
            this.WorkAuditImprovementSuggestions = []
        },

        async insertSuggestions(kaizenPoint, selectedPoints) {
            let responseId = null
            let currentIndex = null
            this.workStationAuditResponseAnalysis.forEach((each, index) => {
                if (each.question.improvement == kaizenPoint) {
                    currentIndex = index
                    responseId = each.id
                }
            })
            let points = selectedPoints.map((suggestion) => ({
                work_audit_response_id: responseId,
                explanation: suggestion.question.explanation,
                solution: suggestion.question.solution
            }))
            let createSuggestions = points.map((suggestion) =>
                StationService.createWorkStationAuditResponseSuggestion(suggestion)
            )
            await Promise.all(createSuggestions)
        },

        async handleDeleteAISuggestion(suggestionId) {
            try {
                this.workStationAuditResponseAnalysis = this.workStationAuditResponseAnalysis.filter(
                    (data) => data.question.id !== suggestionId
                )
                await StationService.deleteAISuggestion(suggestionId)
            } catch (error) {
                console.log(error)
            }
        },
    }
})