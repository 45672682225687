import ApiHelper from './index'

export const startSegmentMapping = async (studyId) => {
    return await ApiHelper.post(`deep_model/segment_map/${studyId}`)
}

export const startSegmentsRemaping = async (studyId) => {
    return await ApiHelper.get(`deep_model/remap_segmentations/${studyId}`)
}
export default {
    startSegmentMapping,
    startSegmentsRemaping
}
