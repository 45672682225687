import ApiHelper from './index'

const extractHandTracklets = async (studyId) => {
  return await ApiHelper.get(`hand_tracking/extract_hand_tracklets/${studyId}/`)
}

const generateHandTrackingResult = async (studyId) => {
  return await ApiHelper.get(`hand_tracking/map_tracklets_to_segment/${studyId}/`)
}

const startHandTracking = async (studyId) => {
  return await ApiHelper.get(`hand_tracking/segment_analysis/${studyId}/`)
}

const getHandTrackingResult = async (studyId) => {
  return await ApiHelper.get(`/hand_tracking/get_hand_tracking_result/${studyId}`)
}

const getLayoutSuggestions = async (studyId) => {
  return await ApiHelper.get(`/hand_tracking/get_ergonomics_result/${studyId}`)
}

export default {
  extractHandTracklets,
  generateHandTrackingResult,
  startHandTracking,
  getHandTrackingResult,
  getLayoutSuggestions
}
