export const fmeaType = {
    design: 'Design',
    process: 'Process'
}

export const fmeaTypeOptions = [
    {
        value: 'Design',
        label: 'Design'
    },
    {
        value: 'Process',
        label: 'Process'
    }
]