<template>
  <a-modal
    centered
    destroy-on-close
    :closable="false"
    :open="showReloadModal"
    width="600px"
    :maskClosable="false"
    :footer="null"
    class="update-notification-modal"
    data-test-id="annotate-posture-modal"
    :bodyStyle="{ textAlign: 'center', padding: '20px' }"
  >
    <div class="modal-content">
      <p class="modal-text">
        A new version of the application is available. To enjoy the latest features and
        improvements, please reload the page.
      </p>
      <a-button type="primary" size="large" @click="handleSubmit" class="reload-btn">
        Reload Now
      </a-button>
    </div>
  </a-modal>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useNotificationStore } from '../../../stores/notification'

const notificationStore = useNotificationStore()
const { showReloadModal } = storeToRefs(notificationStore)

const handleSubmit = () => {
  window.location.reload(true)
}
</script>
<style lang="css" scoped>
.icon-container {
  margin-bottom: 20px;
}

.reload-icon {
  font-size: 50px;
  color: #007bff;
  animation: spin 2s linear infinite;
}

.modal-text {
  font-size: 18px;
  color: #333;
  margin-bottom: 24px;
}

.reload-btn {
  font-weight: bold;
  width: 100%;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
