<template>
  <a-app>
    <NotificationModal />
    <error v-show="isAppCrashed" />
    <RouterView v-show="!isAppCrashed" />
  </a-app>
</template>

<script setup>
import { RouterView } from 'vue-router'
import 'driver.js/dist/driver.css'
import Error from './components/Core/ErrorComponent.vue'
import { onErrorCaptured, provide, ref } from 'vue'
import NotificationModal from './components/User/Notification/Notification.vue'

const isAppCrashed = ref(false)

provide('hideError', () => {
  isAppCrashed.value = false
})

onErrorCaptured((error) => {
  // whitelist media error 
  if (error.message.indexOf('media') >= 0) return
  // INFO: Error captured by global handler and reported to Sentry.
  isAppCrashed.value = true
})
</script>

<style>
@import 'bootstrap-icons/font/bootstrap-icons.css';
@import 'main.css';
@import 'styles/index.css';
@import 'styles/components.css';

.full-modal .ant-modal {
  max-width: 100%;
  top: 0;
  padding-bottom: 0;
  margin: 0;
}

.full-modal .ant-modal-content {
  display: flex;
  flex-direction: column;
  height: calc(100vh);
}

.full-modal .ant-modal-body {
  flex: 1;
}

.center {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(202, 202, 202);
}

/* Driver */
.driver-active .driver-active-element {
  pointer-events: none !important;
}

.driver-active .driver-active-element * {
  pointer-events: none !important;
}

.ant-dropdown {
  z-index: 2000 !important;
}
</style>
