<template>
  <a-row class="w-100 px-4" :gutter="[32, 24]">
    <a-col class="gutter-row" :xs="10" :md="10" :lg="12" :xl="13">
      <div
        v-if="!masterCycleFileUrl && !isDemoOngoing"
        class="w-100 h-100 d-flex justify-content-center align-items-center"
      >
        <a-spin size="large" />
      </div>

      <div v-else class="w-100 pt-4">
        <SegmentVideo
          v-if="videoFPS"
          :video-url="!isDemoOngoing ? masterCycleFileUrl : stationDesignDummyObject.url"
          :segment-play="segmentPlay"
          :duration-time="segmentPlay.duration"
          :frame-per-sec="videoFPS"
          :styles="{ maxHeight: '480px', objectFit: 'cover' }"
        />

        <!-- <div class="w-100 d-flex justify-content-center mx-auto" v-if="segmentPlay">
          <span
            style="margin-left: 36px; gap: 10px"
            class="d-flex align-items-center justify-content-center w-100 mx-auto"
          >
            <LeftOutlined
              class="cycle-nav-icon"
              :class="{ disabled: currCycle <= 0 }"
              @click="handleCycleChange(-1)"
            />
            <span v-if="isDemoOngoing" style="font-size: 18px"> 1 / 1 </span>
            <span v-else style="font-size: 18px">
              {{ currCycle + 1 }} / {{ workCycles?.length }}
            </span>
            <RightOutlined
              :class="{ disabled: currCycle === workCycles?.length - 1 }"
              class="cycle-nav-icon"
              @click="handleCycleChange(1)"
            />
            <sub v-if="segmentPlay?.is_master">(Master)</sub>
          </span>
        </div> -->
      </div>
    </a-col>

    <a-col :xs="14" :md="14" :lg="12" :xl="11" class="d-flex gutter-row">
      <div
        class="w-100 d-flex justify-content-center align-items-center"
        v-if="!groupQuestions.length && !isDemoOngoing"
      >
        <a-spin size="large" tip="" />
      </div>

      <a-card
        v-else
        :title="currentCategoryTitle"
        class="d-flex flex-column mt-2 w-100"
        id="work-audit-page"
        size="small"
        :headStyle="{
          fontSize: '16.4px',
          borderTopRight: '3px',
          borderTopLeft: '3px',
          backgroundColor: '#FAFAFA',
          color: 'black'
        }"
        :bodyStyle="{ flexGrow: 1, width: '100%', height: '55vh', overflowY: 'auto' }"
      >
        <template #extra>
          <span class="response-counter"
            >Questions: {{ answeredQuestionsCount }} / {{ studyAnswers.length }}</span
          >
        </template>
        <div v-for="answerResp in groupQuestions" :key="answerResp.question.id">
          <a-card class="question-card">
            <p>{{ answerResp.question.question }}</p>
            <a-radio-group v-model:value="answerResp.response">
              <a-radio :value="1" @change="answerResp.response = 1">Yes</a-radio>
              <a-radio :value="0" @change="answerResp.response = 0">No</a-radio>
              <a-radio :value="2" @change="answerResp.response = 2">N/A</a-radio>
            </a-radio-group>
          </a-card>
          <a-card
            v-if="
              answerResp.response !== null &&
              ((answerResp.question.follow_up_on === 'YES' && answerResp.response == 1) ||
                (answerResp.question.follow_up_on === 'NO' && answerResp.response == 0))
            "
            class="followup-question-card"
          >
            <a-typography-text class="d-block mb-2">
              If {{ answerResp.question.follow_up_on }}, then select the appropriate action from the
              list.
            </a-typography-text>

            <a-checkbox-group
              class="actions-check-list"
              v-model:value="followUpAnswersList[answerResp.id]"
              @change="updateFollowUpAnswers($event, answerResp.id)"
              :options="followUpQuestionsList"
            />
          </a-card>
        </div>

        <template #actions>
          <a-space class="audit-navigation-buttons">
            <a-button
              size="small k-btn-sm"
              :disabled="updateWorkAuditStationLoader || isFormUnAnswered"
              @click="clearForm"
              >Clear Form</a-button
            >
            <a-space>
              <a-button
                v-if="currentPage > 1"
                :disabled="updateWorkAuditStationLoader"
                @click="goToPreviousPage"
                >Back</a-button
              >
              <a-button
                v-if="currentPage < totalPages"
                :disabled="updateWorkAuditStationLoader"
                @click="goToNextPage"
                :type="nextSubmitButtonType"
                >Next</a-button
              >
              <a-button
                v-if="currentPage === totalPages"
                :disabled="navButtonDisabled"
                :type="nextSubmitButtonType"
                @click="submitForm(false)"
                >Submit</a-button
              >
            </a-space>
          </a-space>
        </template>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue'
import { mapState, mapActions } from 'pinia'
import { useStationAudit } from 'src/stores/workStationAudit'
import { useStationStore } from 'src/stores/station'
import { useUserStore } from 'src/stores/user'
import { useToast } from 'vue-toastification'
import eventBus from 'src/utils/eventBus'
import SegmentVideo from './SegmentVideo.vue'

const toast = useToast()

export default {
  components: { LeftOutlined, RightOutlined, SegmentVideo },
  inject: ['nextTab', 'showError'],
  data() {
    return {
      questionGroup: {
        FACILITY_RELATED_QUESTION: 'FACILITY_RELATED_QUESTION',
        MANUFACTURING_WASTES: 'MANUFACTURING_WASTES',
        OPERATORS_ADHERENCE_TO_STANDARD_WORK: 'OPERATORS_ADHERENCE_TO_STANDARD_WORK',
        WORKPLACE_DESIGN: 'WORKPLACE_DESIGN'
      },
      groupQuestion: [],
      currentPage: 1,
      totalPages: 4,
      answers: {},
      followUpAnswersList: {},
      checkedList: [],
      segmentPlay: {
        start: 0,
        end: 0
      },
      currCycle: 0,
      initialAnswers: '',
      isDataSubmit: false
    }
  },
  watch: {
    studyAnswers(value) {
      this.initialAnswers = JSON.stringify(value)
    }
  },
  async created() {
    if (this.isDemoOngoing) return
    const studyId = this.newStudyId
    await Promise.all([this.fetchWorkCyles(), this.fetchWorkStationAuditResponses(studyId)])
    this.answers = this.auditAnswers
    this.followUpAnswersList = this.auditFollowUpAnswers
    this.handleCycleChange()
    eventBus.$on('audit-next-page', this.handleShowNextScreen)
  },
  beforeUnmount() {
    eventBus.$off('audit-next-page', this.handleShowNextScreen)
  },
  computed: {
    ...mapState(useStationStore, [
      'stationDesignDummyObject',
      'workCycles',
      'newStudy',
      'newStudyId',
      'masterCycleFileUrl',
      'masterCycleVideoId',
      'studyFilesObject'
    ]),
    ...mapState(useUserStore, ['isDemoOngoing']),
    ...mapState(useStationAudit, [
      'studySteps',
      'studyAnswers',
      'auditAnswers',
      'auditFollowUpAnswers',
      'updateWorkAuditStationLoader'
    ]),

    videoFPS() {
      if (!this.masterCycleVideoId || !this.studyFilesObject) return
      const fps = this.studyFilesObject[this.masterCycleVideoId]?.fps
      return fps || 30
    },

    followUpQuestionsList() {
      return this.studySteps.map((step, index) => ({
        label: step.name ? step.name : `Step ${index + 1}`,
        value: step.id
      }))
    },

    groupQuestions() {
      let questionGroup
      switch (this.currentPage) {
        case 1:
          questionGroup = this.questionGroup.FACILITY_RELATED_QUESTION
          break
        case 2:
          questionGroup = this.questionGroup.OPERATORS_ADHERENCE_TO_STANDARD_WORK
          break
        case 3:
          questionGroup = this.questionGroup.WORKPLACE_DESIGN
          break
        case 4:
          questionGroup = this.questionGroup.MANUFACTURING_WASTES
          break
        default:
          questionGroup = null
      }
      if (this.isDemoOngoing) {
        return this.stationDesignDummyObject.studyAnswers.filter(
          (answer) => answer.question.group === questionGroup
        )
      }
      return questionGroup
        ? this.studyAnswers.filter((answer) => answer.question.group === questionGroup)
        : []
    },

    currentCategoryTitle() {
      switch (this.currentPage) {
        case 1:
          return 'Facility'
        case 2:
          return 'SWI Adherence'
        case 3:
          return 'Workplace Design'
        case 4:
          return 'Manufacturing Waste'
        default:
          return 'Facility'
      }
    },

    isFormUnAnswered() {
      return this.studyAnswers.every((ques) => ques.response === null)
    },

    answeredQuestionsCount() {
      const answeredQuestions = (
        this.isDemoOngoing ? this.stationDesignDummyObject.studyAnswers : this.studyAnswers
      ).filter((answerResp) => answerResp.response !== null)
      return answeredQuestions.length
    },

    navButtonDisabled() {
      return (
        (this.initialAnswers && this.initialAnswers === JSON.stringify(this.studyAnswers)) ||
        this.updateWorkAuditStationLoader
      )
    },

    nextSubmitButtonType() {
      return this.initialAnswers && this.initialAnswers !== JSON.stringify(this.studyAnswers)
        ? 'primary'
        : 'default'
    }
  },
  methods: {
    ...mapActions(useStationStore, ['fetchWorkCyles']),
    ...mapActions(useStationAudit, [
      'fetchWorkStationAuditResponses',
      'clearWorkStationAuditResponses',
      'updateWorkStationAuditResponses',
      'setAuditFollowUpAnswers'
    ]),

    handleCycleChange(count = 0) {
      if (this.currCycle + count >= this.workCycles.length || this.currCycle < 0) return
      this.currCycle = this.currCycle + count
      const cycle = this.workCycles[this.currCycle]
      if (!cycle) return
      this.handlePlaySegmentVideo(cycle)
    },

    handlePlaySegmentVideo(cycle) {
      const { segment_start, segment_end } = cycle
      this.segmentPlay = {
        start: segment_start,
        end: segment_end,
        duration: Number((segment_end - segment_start).toFixed(2)),
        is_master: cycle?.is_master
      }
    },

    loadedMetaData() {
      this.$refs.uploadedVideo.currentTime = 0
    },

    async goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++
      }
      await this.submitForm(true)
    },

    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--
      }
    },

    async submitForm(isGoingToNextPage) {
      let resp = this.studyAnswers.map((item) => {
        const itemId = item.id
        const steps = this.auditFollowUpAnswers[itemId] || []
        const stepsAsObjects = steps.map((stepId) => ({ id: stepId }))
        return {
          ...item,
          question: item?.question?.question,
          steps: [...stepsAsObjects]
        }
      })
      const studyId = this.newStudyId
      await this.updateWorkStationAuditResponses(studyId, { data: resp })
      let showToaster =
        this.initialAnswers !== JSON.stringify(this.studyAnswers) && !isGoingToNextPage
      await this.fetchWorkStationAuditResponses(studyId)
      if (showToaster) {
        toast.success('Responses has been updated successfully')
      }
      this.initialAnswers = JSON.stringify(this.studyAnswers)
      // this.isDataSubmit = true
    },

    async clearForm() {
      let resp = this.studyAnswers.map((item) => {
        return {
          ...item,
          response: null,
          question: item?.question?.question,
          steps: []
        }
      })
      const { id } = this.$route.params
      await this.clearWorkStationAuditResponses(id, { data: resp })
      this.followUpAnswersList = {}
      const studyId = this.newStudyId
      await this.fetchWorkStationAuditResponses(studyId)
      this.initialAnswers = JSON.stringify(this.studyAnswers)
      // this.isDataSubmit = true
    },

    updateFollowUpAnswers(event, answerId) {
      this.isDataSubmit = false
      this.followUpAnswersList[answerId] = event
      this.setAuditFollowUpAnswers(this.followUpAnswersList)
    },

    handleShowNextScreen() {
      this.nextTab()
    }
  }
}
</script>

<style>
.question-card {
  margin-bottom: 10px;
  padding: 0px 10px;
  page-break-inside: avoid;
  word-wrap: break-word;
  border: 1px solid rgb(218, 220, 224);
  border-radius: 8px;
  display: block;
  width: 100%; /* Adjust the width based on your requirements */
}
.followup-question-card {
  margin-bottom: 10px;
  page-break-inside: avoid;
  word-wrap: break-word;
  border: 1px solid rgb(218, 220, 224);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  width: 100%; /* Adjust the width based on your requirements */
}

.actions-check-list {
  row-gap: 4px;
  white-space: normal;
}

.actions-check-list .ant-checkbox-wrapper {
  flex-basis: 48%;
}

.actions-check-list .ant-checkbox-group-item .ant-checkbox {
  align-self: flex-start;
}
.audit-navigation-buttons {
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.response-counter {
  font-weight: 600;
}
/* Add more styles as needed */
#work-audit-page > .ant-card-head {
  background: #828282;
  color: white;
}
</style>
