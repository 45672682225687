<template>
  <a-select
    :value="selectedFiles"
    :filter-option="true"
    optionFilterProp="label"
    show-search
    mode="multiple"
    ref="video_select"
    placeholder="Select Video"
    class="w-100"
    :class="{
      'video-list-select': selectedFiles.length,
      'normalVideo-select': selectedFiles && size !== 'small',
      'smallVideo-select': selectedFiles && size === 'small',
      auto_generated_inputVideo: auto_generated
    }"
    :disabled="disabled"
    :getPopupContainer="getPopupContainer"
    @change="handleFileSelect"
  >
    <a-select-option
      v-for="option in filteredFileOptions"
      class="video-select-option w-100"
      :value="option.value"
      :label="option.label"
      :key="option.value"
    >
      <span>{{ option.label }}</span>
      <img
        v-if="option.thumbnail"
        crossorigin="anonymous"
        :src="option.thumbnail"
        alt=""
        width="60"
        style="border-radius: 2px"
      />
    </a-select-option>
  </a-select>
</template>

<script>
export default {
  props: {
    selectedFiles: {
      type: Array,
      required: true
    },
    fileOptions: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    auto_generated: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    oldFiles: {
      type: Array
    }
  },
  computed: {
    filteredFileOptions() {
      return this.fileOptions.filter((option) => !this.oldFiles.includes(option.value))
    }
  },
  emits: ['changeFile'],
  methods: {
    handleFileSelect(value) {
      this.$emit('changeFile', value)
    },

    getPopupContainer(triggerNode) {
      const elem = document.getElementById('imageArea')
      return elem ? elem : triggerNode.parentElement
    }
  }
}
</script>

<style>
.smallVideo-select .ant-select-single .ant-select-selector,
.smallVideo-select .ant-select-selection-item {
  height: 45px;
}

.normalVideo-select .ant-select-single .ant-select-selector,
.normalVideo-select .ant-select-selection-item {
  /* height: 3em; */
  display: flex;
  align-items: center;
  gap: 1.5em;
}

.video-list-select .ant-select-selection-item {
  line-height: normal !important;
}

.video-list-select .ant-select-selection-item img {
  display: none;
}

.video-select-option .ant-select-item-option-content {
  display: flex;
  min-height: 2.5em;
  justify-content: space-between;
}

.video-select-option span {
  white-space: normal;
}

.auto_generated_inputVideo > div {
  border: 0.1em solid rgb(24, 144, 255) !important;
}
</style>
