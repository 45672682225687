import ApiHelper from './index'

const createStation = async (payload) => {
  return await ApiHelper.post('station_improvement/station', payload)
}


const fetchWorkStationAuditResponses = async (studyId) => {
  try {
    return await ApiHelper.get(`station_improvement/work-audit-responses/${studyId}/`)
  } catch (error) {
    console.log(error)
  }
}

const updateWorkStationAuditResponses = async (studyId, payload) => {
  try {
    return await ApiHelper.patch(`station_improvement/update-work-audit-responses/${studyId}/`, payload)
  } catch (error) {
    console.error(error)
  }
}

const fetchWorkStationAuditResponseAnalysis = async (studyId) => {
  try {
    return await ApiHelper.get(`station_improvement/work-audit-response-analysis/${studyId}/`)
  } catch (error) {
    console.error(error)
  }
}

const fetchWorkStationAuditResponseResult = async (studyId) => {
  try {
    return await ApiHelper.get(`station_improvement/work-audit-results/${studyId}/`)
  } catch (error) {
    console.error(error)
  }
}
const createWorkStationAuditResponseSuggestion = async (payload) => {
  try {
    return await ApiHelper.post(`station_improvement/work-audit-response-suggestion/`, payload)
  } catch (error) {
    console.error(error)
  }
}

const fetchWorkStationAuditSuggestions = async (params) => {
  try {
    return await ApiHelper.get(`deep_model/get_workaudit_suggestion`, params)
  } catch (error) {
    console.error(error)
  }
}

const fetchStations = async (payload) => {
  return await ApiHelper.get('station_improvement/station', payload)
}

const createNewStudy = async (payload) => {
  return await ApiHelper.post('station_improvement/study', payload)
}

const fetchStudies = async (params) => {
  return await ApiHelper.get('station_improvement/study', params)
}

const getStudy = async (studyId) => {
  return await ApiHelper.get(`station_improvement/study/${studyId}/`)
}

const updateStudy = async (studyId, payload) => {
  return await ApiHelper.patch(`station_improvement/study/${studyId}/`, payload)
}

const updateStation = async (id, payload) => {
  return await ApiHelper.patch(`station_improvement/station/${id}/`, payload)
}

const deleteStudy = async (studyId) => {
  return await ApiHelper.remove(`station_improvement/study/${studyId}/`)
}

// const fetch5W1HQuestions = async (studyId) => {
//   return await ApiHelper.get(`station_improvement/5w1h_responses/${studyId}`)
// }

// const update5W1HQuestionResponse = async (id, payload) => {
//   return await ApiHelper.patch(`station_improvement/5w1h_response/${id}`, payload)
// }

const fetchKaizenNotesQuestions = async (studyId) => {
  return await ApiHelper.get(`station_improvement/kaizen_notes_responses/${studyId}`)
}

const updateKaizenNotesQuestionResponse = async (studyId, payload) => {
  return await ApiHelper.patch(`station_improvement/kaizen_notes_response/${studyId}`, payload)
}

const deleteStep = async (id) => {
  return await ApiHelper.remove(`/station_improvement/step/${id}/`)
}

const deleteAISuggestion = async (id) => {
  return await ApiHelper.remove(`/station_improvement/work-audit-response-suggestion/${id}`)
}

const fetchWorkCycles = async (studyId) => {
  return await ApiHelper.get(`station_improvement/study/${studyId}/work_cycles/`)
}

const createWorkCycles = async (studyId, payload) => {
  return await ApiHelper.post(`station_improvement/work_cycles/${studyId}/`, payload)
}

// const getWorkCycle = async (cycleId) => {
//   return await ApiHelper.get(`station_improvement/work_cycle/${cycleId}/`)
// }

const getWorkCycle = async (studyId, cycleId) => {
  return await ApiHelper.get(`station_improvement/study/${studyId}/work_cycles/${cycleId}`)
}

const createCycleVideo = async (studyId, cycleId) => {
  return await ApiHelper.post(`station_improvement/study/${studyId}/work_cycles/${cycleId}/video`)
}

const updateWorkCycle = async (cycleId, payload) => {
  return await ApiHelper.patch(`station_improvement/work_cycle/${cycleId}/`, payload)
}

const removeWorkCycle = async (cycleId) => {
  return await ApiHelper.remove(`station_improvement/work_cycle/${cycleId}/`)
}

const fetchWorkRegions = async (studyId) => {
  return await ApiHelper.get(`station_improvement/work_regions/${studyId}/`)
}

const createWorkRegions = async (studyId, payload) => {
  return await ApiHelper.post(`station_improvement/work_regions/${studyId}/`, payload)
}

const updateWorkRegion = async (cycleId, payload) => {
  return await ApiHelper.patch(`station_improvement/work_region/${cycleId}/`, payload)
}

const removeWorkRegion = async (cycleId) => {
  return await ApiHelper.remove(`station_improvement/work_region/${cycleId}/`)
}

const runModels = async (studyId, numOfSegments) => {
  return await ApiHelper.post(`deep_model/run_station_ai/${studyId}/${numOfSegments}/`)
}

const runTimeStudyModels = async (studyId, numOfSegments) => {
  return await ApiHelper.post(`deep_model/run-time-study-ai/${studyId}/${numOfSegments}/`)
}

const runSuggestionModels = async (studyId) => {
  return await ApiHelper.post(`deep_model/run-suggestion-improvement-ai/${studyId}`)
}


export default {
  runModels,
  createStation,
  fetchStations,
  createNewStudy,
  fetchStudies,
  getStudy,
  updateStudy,
  updateStation,
  deleteStudy,
  fetchKaizenNotesQuestions,
  updateKaizenNotesQuestionResponse,
  deleteStep,
  fetchWorkCycles,
  createWorkCycles,
  updateWorkCycle,
  getWorkCycle,
  removeWorkCycle,
  fetchWorkRegions,
  createWorkRegions,
  updateWorkRegion,
  removeWorkRegion,
  fetchWorkStationAuditResponses,
  updateWorkStationAuditResponses,
  fetchWorkStationAuditResponseAnalysis,
  fetchWorkStationAuditResponseResult,
  fetchWorkStationAuditSuggestions,
  createWorkStationAuditResponseSuggestion,
  deleteAISuggestion,
  runTimeStudyModels,
  runSuggestionModels,
  createCycleVideo
}
