import ApiHelper from './index'

const startSegmentation = async (studyId, numOfSegments) => {
  return await ApiHelper.get(`deep_model/perform_video_segmentation/${studyId}/${numOfSegments}`)
}

const createSegments = async (studyId, cycle_no, segments) => {
  return await ApiHelper.post(`station_improvement/steps/${studyId}/${cycle_no}/`, { segment_info: segments })
}

const getSegments = async (studyId) => {
  return await ApiHelper.get(`station_improvement/all_steps/${studyId}/`)
}

const updateSegment = async (segmentId, payload) => {
  return await ApiHelper.patch(`station_improvement/step/${segmentId}/`, payload)
}

const deleteSegment = async (segmentId) => {
  return await ApiHelper.remove(`station_improvement/step/${segmentId}/`)
}

const createStepActionAssociation = async (payload) => {
  return await ApiHelper.post('station_improvement/step-action-association', payload)
}

const deleteStepActionAssociation = async (params) => {
  return await ApiHelper.remove('station_improvement/step-action-association', params, false)
}

const updateValueType = async (payload) => {
  return await ApiHelper.patch('station_improvement/update-value-type', payload)
}

const stepQuestionAnswer = async (payload) => {
  return await ApiHelper.post('station_improvement/question-answer', payload)
}

const getCycleSegments = async (studyId, cycle_no) => {
  return await ApiHelper.get(`station_improvement/steps/${studyId}/${cycle_no}/`)
}

const addStepNewPart = async (stepId, payload) => {
  return await ApiHelper.post(`station_improvement/step/${stepId}/parts/`, payload)
}

const addStepNewTool = async (stepId, payload) => {
  return await ApiHelper.post(`station_improvement/step/${stepId}/tools/`, payload)
}

const deleteStepTool = async (ToolId, payload) => {
  return await ApiHelper.remove(`station_improvement/tools/${ToolId}/`, payload)
}

const deleteStepPart = async (PartId, payload) => {
  return await ApiHelper.remove(`station_improvement/parts/${PartId}/`, payload)
}

const getStepsAverageTime = async (studyId) => {
  return await ApiHelper.get(`station_improvement/average_step_time/${studyId}/`)
}

const getEstimatedNoOfSteps = async (studyId) => {
  return await ApiHelper.get(`station_improvement/estimate_steps/${studyId}/`)
}

export default {
  startSegmentation,
  createSegments,
  getSegments,
  updateSegment,
  deleteSegment,
  createStepActionAssociation,
  deleteStepActionAssociation,
  updateValueType,
  stepQuestionAnswer,
  getCycleSegments,
  addStepNewPart,
  addStepNewTool,
  deleteStepTool,
  deleteStepPart,
  getStepsAverageTime,
  getEstimatedNoOfSteps,
}
