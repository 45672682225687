export const localStorageItems = [
  'id',
  'name',
  'role',
  'organization',
  'token',
  'timestamp',
  'hostURL',
  'poc',
  'isPolicyAccepted',
  'email',
  'requireOtp',
  'sessionKey',
  'isOtpSetup',
  'AWSCustomerIdentifier',
  'refresh',
  'azureUser'
  // 'sessionEndTime',
  // 'sessionId',
  // 'sessionUser'
]

export function clearLocalStorageExcept() {
  let keysToKeep = [
    'completedLineBalancingDemo',
    'completedStationDesignDemo',
    'completedFMEADemo',
    'completedErgonomicsDemo',
    'baseURL'
  ]
  // Iterate through all localStorage items
  for (var i = 0; i < localStorageItems.length; i++) {
    var key = localStorageItems[i]
    // Check if the key is not in the keysToKeep array
    if (keysToKeep.indexOf(key) === -1) {
      localStorage.removeItem(key)
    }
  }
}
