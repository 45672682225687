import ApiHelper from './index'

const startOperatorTracking = async (studyId) => {
  return await ApiHelper.post(`deep_model/person_tracking/${studyId}`)
}

const getOperatorTrackingData = async (studyId) => {
  return await ApiHelper.get(`hand_tracking/get_person_tracking_result/${studyId}`)
}

export default {
  startOperatorTracking,
  getOperatorTrackingData
}
