<template>
  <a-result
    status="error"
    title="Oops, something went wrong!"
    sub-title="Apologies for inconvenience, please try again."
    class="position-absolute top-50 start-50 translate-middle"
  >
    <template #extra>
      <a-button type="primary" @click="handleError">Go to Homepage</a-button>
    </template>
  </a-result>
</template>

<script setup>
import { inject } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const hideError = inject('hideError')

const handleError = () => {
  hideError()
  router.push({ name: 'Home' })
}
</script>
