<template>
  <div class="center" v-if="loading && !isDemoOngoing">
    <a-spin size="large" />
  </div>

  <a-row v-else class="w-100">
    <a-col span="6" class="d-flex flex-column py-2 px-3">
      <!-- Process analysis -->
      <a-list bordered :data-source="resultPanels" size="medium" class="process-analysis">
        <template #header>
          <span class="list-header py-3">Station Analysis</span>
        </template>
        <template #renderItem="{ item }">
          <a-list-item
            :id="item.split(' ')[0].toLowerCase()"
            class="step-item m-2 border"
            :class="{ disable: stationLayout === item }"
            @click="handleSelectedTab(item)"
          >
            <a-list-item-meta>
              <template #title>
                <a-typography-text class="w-100"> {{ item }}</a-typography-text>
              </template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>

      <!-- Step analysis -->
      <a-list
        bordered
        :data-source="!isDemoOngoing ? steps : stationDesignDummyObject.steps"
        size="medium"
        class="app-list my-3"
      >
        <template #header>
          <span class="list-header">Step Analysis</span>
        </template>
        <template #renderItem="{ item, index }">
          <a-list-item
            class="step-item m-2"
            :class="{ disable: currentStep === index && !stationLayout }"
            @click="showStepAnalytics(index)"
          >
            <a-list-item-meta
              :title="item.name ? item.name : `Step ${index + 1}`"
              :description="!isDemoOngoing && item.actual_step_time"
            />
          </a-list-item>
        </template>
      </a-list>
    </a-col>

    <a-col span="18" class="d-flex flex-column py-2">
      <div class="suggestions-container">
        <template v-if="!stationLayout">
          <!-- Action analysis -->
          <div class="border rounded-2 card">
            <a-typography-title class="card-header" :level="4">
              Action Analysis
            </a-typography-title>
            <div class="d-flex justify-content-between card-body">
              <div class="d-flex" style="gap: 1.5em; width: 100%">
                <!-- Segment Video -->
                <div style="position: relative" class="d-flex flex-column align-items-center">
                  <video
                    crossorigin="anonymous"
                    ref="video"
                    muted
                    disablePictureInPicture
                    class="segment-video"
                    :src="!isDemoOngoing ? masterCycleFileUrl : stationDesignDummyObject.url"
                    @loadedmetadata="showStepAnalytics(currentStep)"
                    @playing="isPlayingSegment = true"
                  ></video>

                  <div class="d-flex my-1 w-100 justify-content-between">
                    <small>{{
                      getIntervalTime((segmentPlay.segment_start + segmentPlay.progress) * 1000)
                    }}</small>
                    <CaretRightOutlined
                      v-if="!isPlayingSegment"
                      style="font-size: large; cursor: pointer; color: #000"
                      @click="playCurrentSegment"
                    />
                    <PauseOutlined
                      v-else
                      style="font-size: large; cursor: pointer; color: #000"
                      @click="pauseSegment"
                    />
                    <small>{{
                      !isDemoOngoing
                        ? getIntervalTime(segmentPlay.segment_end * 1000)
                        : getIntervalTime(5000)
                    }}</small>
                  </div>

                  <div
                    style="height: 5px; background: lightgray; border-radius: 5px"
                    class="w-100 d-flex"
                  >
                    <div
                      class="h-100"
                      style="background: rgb(24, 144, 255); border-radius: 5px"
                      :style="{ width: (segmentPlay.progress / segmentPlay.end) * 100 + '%' }"
                    ></div>
                  </div>
                </div>

                <!-- Segment Details -->
                <div
                  class="d-flex flex-column justify-content-center align-items-start"
                  style="gap: 1em; width: 50%; height: 100%"
                >
                  <div
                    v-for="item in currentStepDetails"
                    :key="item.label"
                    class="d-flex flex-column w-100"
                  >
                    <a-typography-text>{{ item.label }}</a-typography-text>
                    <a-typography-text strong>
                      {{ item.value }}
                    </a-typography-text>
                    <a-divider strong class="my-2"></a-divider>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Action suggestion -->
          <div v-if="!isCell && !!actionsSuggestions?.length">
            <a-typography-title :level="4">Action Suggestions </a-typography-title>
            <a-row :gutter="[4, 4]" class="w-100">
              <a-col v-for="item in actionsSuggestions" :key="item.suggestion" span="8">
                <div class="suggestion-item">
                  <a-typography-text strong>
                    {{ item.suggestion }}
                  </a-typography-text>
                  <small secondary class="mt-3 text-secondary">
                    Potential Savings: <b>{{ item.potential_savings }}</b>
                  </small>
                </div>
              </a-col>
            </a-row>
          </div>

          <!-- Motion Economy -->
          <div v-if="!isCell" class="card">
            <a-typography-title class="card-header" :level="4"> Motion Economy </a-typography-title>
            <a-row class="d-flex justify-content-between card-body">
              <a-col v-if="motionEconomy?.image || !isDemoOngoing" span="8">
                <a-image
                  :preview="{
                    onVisibleChange: () => handlePreviewImage()
                  }"
                  v-if="motionEconomy?.image || isDemoOngoing"
                  crossorigin="anonymous"
                  style="border-radius: 5px; border: 1px solid lightgray"
                  :src="motionEconomy?.image || stationDesignDummyObject.motionEconomyImage"
                />
              </a-col>
              <a-col span="16" class="d-flex flex-column px-2" style="gap: 1em">
                <div class="d-flex flex-column">
                  <a-typography-text>Total Distance Traveled</a-typography-text>
                  <a-typography-text strong> {{ getMovementDistance() }} </a-typography-text>
                </div>
                <a-divider strong class="my-2"></a-divider>
                <div class="d-flex flex-column">
                  <a-typography-text> Motion Type </a-typography-text>
                  <a-typography-text strong>
                    {{ !isDemoOngoing ? motionEconomy?.motion?.type : 'Economical Motion' }}
                  </a-typography-text>
                  <a-typography-text type="secondary">
                    {{ motionEconomy?.motion?.details }}
                  </a-typography-text>
                </div>
                <a-divider strong class="my-2"></a-divider>
              </a-col>
            </a-row>
          </div>
        </template>

        <!-- Summarized suggestion / Key Takeaways -->
        <div v-if="stationLayout === 'Key Takeaways'">
          <div id="sd-result-key-takeaways-section">
            <a-typography-title :level="4">Key Takeaways</a-typography-title>
            <div class="center" v-if="isfetchingLLMSuggestions && !isDemoOngoing">
              <a-spin size="large" />
              <a-typography-title class="mt-3" :level="4">
                Please wait while we are generating key takeaways.
              </a-typography-title>
            </div>
            <div v-else-if="!isDemoOngoing" style="display: flex; flex-direction: column; gap: 2em">
              <a-typography-text v-for="suggestion in summarizedSuggestions" :key="suggestion">
                <div v-html="formattedText(suggestion)"></div>
              </a-typography-text>
            </div>
            <div v-else style="display: flex; flex-direction: column; gap: 2em">
              <a-typography-text
                v-for="suggestion in stationDesignDummyObject.summarizedSuggestions"
                :key="suggestion"
              >
                <div v-html="formattedText(suggestion)"></div>
              </a-typography-text>
            </div>
          </div>
        </div>

        <!-- Workstation Audit Result -->
        <div class="h-100" v-if="stationLayout == 'Workstation Audit Results'">
          <work-station-audit-result />
        </div>

        <div class="h-100" v-if="stationLayout == 'Spaghetti Diagram'">
          <SpaghettiDiagram />
        </div>

        <!-- Station Layout Suggestions -->
        <div v-if="stationLayout === 'Station Layout Suggestions'">
          <station-layout
            v-if="ergonomicsResult?.length || isDemoOngoing"
            :ergonomicsResult="
              !isDemoOngoing ? ergonomicsResult : stationDesignDummyObject.ergonomicsResult
            "
          />
          <a-empty v-else :image="Empty.PRESENTED_IMAGE_SIMPLE">
            <template #description>
              <span> Ergonomics Data Not Available </span>
            </template>
          </a-empty>
        </div>

        <!-- Time and Motion Study Result -->
        <div
          v-if="stationLayout === 'Time and Motion Study Result'"
          id="sd-result-time-and-motion-study"
        >
          <a-typography-title :level="4">Time and Motion Study Result</a-typography-title>
          <!-- Cycle details -->
          <a-space class="my-3" :size="15">
            <span
              class="d-flex align-items-center"
              v-for="stat in timeAndMotionStats"
              :key="stat.title"
            >
              <a-statistic
                :title="stat.title"
                :value="stat.value"
                :style="{ fontWeight: 500 }"
                :valueStyle="{ fontSize: '28px', textAlign: 'center' }"
              >
              </a-statistic>
              <a-divider v-if="stat.index < 2" type="vertical" :style="{ height: '50px' }" />
            </span>
          </a-space>

          <!-- Charts -->
          <a-row :gutter="[24, 24]" class="mt-2">
            <a-col span="12">
              <a-card id="step-times-chart" title="Step Times Per Cycle" size="small" hoverable>
                <BarChart
                  :title="'Bar chart'"
                  :data="
                    !isDemoOngoing ? stepsTimePerCycle : stationDesignDummyObject.stepsTimePerCycle
                  "
                />
              </a-card>
            </a-col>
            <a-col span="12">
              <a-card
                id="value-pie-chart"
                title="Value Added Time as Percentage of Cycle"
                size="small"
                hoverable
              >
                <PieChart
                  :data="
                    !isDemoOngoing
                      ? valueAddedPieChart
                      : stationDesignDummyObject.valueAddedPieChart
                  "
                />
              </a-card>
            </a-col>
            <a-col span="24">
              <StepsTable :videoUrl="masterCycleFileUrl" />
            </a-col>
          </a-row>
        </div>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import PieChart from './PieChart.vue'
import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons-vue'
import { getIntervalTime } from 'src/utils/outline'
import { mapActions, mapState } from 'pinia'
import { useStationStore } from 'src/stores/station'
import { useUserStore } from 'src/stores/user'
import { useHandTrackingStore } from 'src/stores/handTracking'
import { useSegmentationStore } from 'src/stores/segmentation'
import { useClassificationStore } from 'src/stores/classification'
import { useOperatorTrackingStore } from 'src/stores/operatorTracking'
import { Empty } from 'ant-design-vue'
import BarChart from './BarChart.vue'
import StationLayout from './StationLayout.vue'
import WorkStationAuditResult from './WorkStationAuditResult.vue'
import SpaghettiDiagram from './SpaghettiDiagram.vue'
import StepsTable from './StepsTable.vue'
import eventBus from 'src/utils/eventBus'

export default {
  inject: ['nextTab'],
  components: {
    PieChart,
    CaretRightOutlined,
    PauseOutlined,
    BarChart,
    StationLayout,
    WorkStationAuditResult,
    SpaghettiDiagram,
    StepsTable
  },
  setup() {
    return { getIntervalTime, Empty }
  },
  data() {
    return {
      steps: [],
      loading: false,
      currentStep: 0,
      stationLayout: '',
      isPlayingSegment: false,
      segmentPlay: {
        progress: 0,
        segment_start: 0,
        end: Number.MAX_SAFE_INTEGER,
        segment_end: 0
      },
      isSegmentPaused: false,
      segmentPlayInterval: null,
      isVideoLoaded: false
    }
  },
  computed: {
    ...mapState(useStationStore, [
      'stationDesignDummyObject',
      'newStudy',
      'isCell',
      'masterCycle',
      'masterCycleFileUrl',
      'masterCycleVideoId',
      'studyFilesObject'
    ]),
    ...mapState(useUserStore, ['isDemoOngoing']),
    ...mapState(useSegmentationStore, [
      'segmentationDatForSuggestions',
      'stationLayoutSuggestion',
      'valueAddedPieChart',
      'stepsTimePerCycle'
    ]),
    ...mapState(useHandTrackingStore, [
      'ergonomicsResult',
      'isfetchingLLMSuggestions',
      'summarizedSuggestions'
    ]),

    videoFPS() {
      if (!this.masterCycleVideoId || !this.studyFilesObject) return
      const fps = this.studyFilesObject[this.masterCycleVideoId]?.fps
      return fps || 30
    },

    currentStepDetails() {
      if (!this.steps?.length) return
      const { name, actions, assessment } = this.steps[this.currentStep]
      return [
        {
          label: 'Step Name',
          value: name || 'Untitled'
        },
        {
          label: 'Work Elements',
          value: actions.join(', ')
        },
        {
          label: 'Value Type',
          value: assessment
        }
      ]
    },

    actionsSuggestions() {
      if (!this.steps?.length) return
      const { actionsSuggestions } = this.steps[this.currentStep]
      return !actionsSuggestions?.length
        ? []
        : actionsSuggestions.filter((a_suggest) =>
            Object.prototype.hasOwnProperty.call(a_suggest, 'cost_saving_percentage')
          )
    },

    motionEconomy() {
      if (!this.steps?.length) return {}
      const { motionEconomy } = this.steps[this.currentStep]
      return motionEconomy
    },

    timeAndMotionStats() {
      const {
        isDemoOngoing,
        stationLayoutSuggestion,
        stationDesignDummyObject: { stationLayoutSuggestion: dummyStationLayoutSuggestion }
      } = this

      if (
        !isDemoOngoing &&
        (!stationLayoutSuggestion || !Object.keys(stationLayoutSuggestion)?.length)
      ) {
        return []
      }

      return [
        {
          index: 0,
          title: 'Actual Cycle Time',
          value: !isDemoOngoing
            ? stationLayoutSuggestion.actual_cycle_time
            : dummyStationLayoutSuggestion.actual_cycle_time
        },
        {
          index: 1,
          title: 'Value Added Time',
          value: !isDemoOngoing
            ? stationLayoutSuggestion.valueAddPercentage
            : dummyStationLayoutSuggestion.totalValueAddTime
        },
        {
          index: 2,
          title: 'No. Of Steps',
          value: !isDemoOngoing
            ? stationLayoutSuggestion.no_of_steps
            : dummyStationLayoutSuggestion.no_of_steps
        }
      ]
    },

    resultPanels() {
      const stationPanels = [
        'Key Takeaways',
        'Station Layout Suggestions',
        'Workstation Audit Results'
      ]
      const cellPanels = ['Key Takeaways', 'Workstation Audit Results', 'Spaghetti Diagram']
      return this.isCell ? cellPanels : stationPanels
    }
  },
  watch: {
    // masterCycle(cycle) {
    //   if (!cycle || !Object.keys(cycle)?.length) return
    //   this.getCycleSegments(cycle.cycle_no)
    // },

    segmentationDatForSuggestions(steps) {
      if (!steps || !steps.length) return
      this.steps = steps
    }
  },

  methods: {
    ...mapActions(useStationStore, ['fetchStudy', 'fetchWorkCyles', 'fetchWorkRegions']),
    ...mapActions(useSegmentationStore, ['fetchAllSteps', 'getCycleSegments']),
    ...mapActions(useHandTrackingStore, [
      'getHandTrackingResult',
      'getErgonomicsResult',
      'getSummarizedSuggestions'
    ]),
    ...mapActions(useClassificationStore, ['getTherbligs', 'assignTherbligsToSegments']),
    ...mapActions(useOperatorTrackingStore, ['getOperatorTrackingResults']),

    getMovementDistance() {
      const { motionEconomy } = this
      if (this.isDemoOngoing) return '2126.74m'
      if (!motionEconomy?.total_distance) return '-'
      else return Number(motionEconomy?.total_distance).toFixed(2) + ' in'
    },

    pauseSegment() {
      if (!this.$refs.video?.paused && this.isPlayingSegment) {
        this.$refs.video?.pause()
        this.isPlayingSegment = false
      }
      this.isSegmentPaused = true
      clearInterval(this.segmentPlayInterval)
    },

    async playCurrentSegment() {
      if (!this.isVideoLoaded || !this.$refs.video) {
        return
      }
      const { segment_start } = this.steps[this.currentStep]
      if (!this.isSegmentPaused) this.$refs.video.currentTime = segment_start
      await this.$refs.video?.play()
      this.segmentTimeUpdate()
      if (this.segmentPlayInterval) clearInterval(this.segmentPlayInterval)
      this.segmentPlayInterval = setInterval(this.segmentTimeUpdate, 100 / this.videoFPS)
    },

    segmentTimeUpdate() {
      if (!this.steps?.length || !this.$refs.video) return
      const { segment_start, segment_end } = this.steps[this.currentStep]
      if (this.segmentPlay.progress >= this.segmentPlay.end) {
        if (!this.$refs.video.paused) this.$refs.video.pause()
        this.isPlayingSegment = false
        this.segmentPlay = {
          progress: 0,
          segment_start: segment_start,
          end: segment_end - segment_start,
          segment_end: segment_end
        }
        clearInterval(this.segmentPlayInterval)
        this.isSegmentPaused = false
        return
      }
      this.segmentPlay.progress = this.$refs.video?.currentTime - this.segmentPlay.segment_start
    },

    async showStepAnalytics(index) {
      if (!this.steps?.length) return
      this.stationLayout = ''

      const { segment_start, segment_end } = this.steps[index]
      this.segmentPlay = {
        progress: 0,
        segment_start: segment_start,
        end: segment_end - segment_start,
        segment_end: segment_end
      }
      this.currentStep = index
      this.isPlayingSegment = false
      if (!this.$refs.video) {
        this.isVideoLoaded = false
        return
      }
      this.$refs.video.currentTime = segment_start
      if (!this.$refs.video?.paused) this.$refs.video?.pause()
      clearInterval(this.segmentPlayInterval)
      this.segmentPlayInterval = null
      if (!this.isVideoLoaded) this.isVideoLoaded = true
    },

    handlePreviewImage() {
      setTimeout(() => {
        const els = document.getElementsByClassName('ant-image-preview-img')
        for (let i = 0; i < els.length; i++) {
          const el = els[i]
          el.setAttribute('crossorigin', 'anonymous')
        }
      })
    },

    handleSelectedTab(value) {
      clearInterval(this.segmentPlayInterval)
      this.pauseSegment()
      this.stationLayout = value
    },

    handleShowNextScreen() {
      this.nextTab()
    },

    formattedText(text) {
      return text.replace(/\n/g, '<br>')
    }
  },

  async created() {
    eventBus.$on('trigger-result-tabs', this.handleSelectedTab)
    if (this.isDemoOngoing) return
    this.loading = true
    if (!this.isCell) await this.assignTherbligsToSegments()
    await this.fetchWorkCyles()
    await this.fetchAllSteps()
    await this.fetchWorkRegions()
    await this.getTherbligs()
    if (!this.isCell) {
      await this.getErgonomicsResult()
      await this.getHandTrackingResult()
    } else await this.getOperatorTrackingResults()
    await this.getSummarizedSuggestions()
    this.loading = false
    eventBus.$on('suggestions-next-page', this.handleShowNextScreen)
  },

  beforeUnmount() {
    clearInterval(this.segmentPlayInterval)
    eventBus.$off('suggestions-next-page', this.handleShowNextScreen)
    eventBus.$off('trigger-result-tabs', this.handleSelectedTab)
  }
}
</script>

<style>
.suggestion-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 10px;
}

.suggestion-item {
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.5em 1em !important;
  flex-direction: column;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.process-analysis > .ant-list-header {
  background: #fafafa !important;
  font-weight: 600 !important;
  padding: 10px !important;
  font-size: 14px;
  color: #0b072d;
  text-align: left;
  border-radius: 6.5px;
}

.suggestions-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
  flex: 1;
  /* height: 1px; */
  /* overflow-y: auto; */
  padding: 0.1em 1em;
  position: relative;
}
#step-times-chart.ant-card > .ant-card-head,
#value-pie-chart.ant-card > .ant-card-head {
  background: #fafafa;
  color: black;
}

.disable .ant-list-item-meta .ant-list-item-meta-content .ant-list-item-meta-title {
  color: white !important;
  font-weight: 600;
}

.disable .ant-list-item-meta .ant-list-item-meta-content .ant-list-item-meta-description {
  color: white !important;
}

.segment-video {
  width: 300px;
  max-height: 260px;
  object-fit: cover;
  border: 1px solid lightgray;
  border-radius: 0.5em;
}
</style>
