// Snook Lifting/Lowering
export const liftLowerPoint = [
    {
        label: 'Floor to Knuckel',
        value: 31
    },
    {
        label: 'Knuckle to Shoulder',
        value: 57
    },
    {
        label: 'Above Shoulder',
        value: 58
    },
]

export const liftLowerPointF = [
    {
        label: 'Floor to Knuckel',
        value: 28
    },
    {
        label: 'Knuckle to Shoulder',
        value: 53
    },
    {
        label: 'Above Shoulder',
        value: 54
    },
]

const horizontalDistance = [
    {
        label: '7"',
        value: 7
    },
    {
        label: '10"',
        value: 10
    },
    {
        label: '15"',
        value: 15
    },
]

//values in seconds
export const frequency = [
    {
        label: '1 action / 8 hours',
        value: 28800
    },
    {
        label: '1 action / 30 minutes',
        value: 1800
    },
    {
        label: '1 action / 5 minutes',
        value: 300
    },
    {
        label: '1 action / 2 minutes',
        value: 120
    },
    {
        label: '1 action / 1 minute',
        value: 60
    },
    {
        label: '1 action / 14 seconds',
        value: 14
    },
    {
        label: '1 action / 9 seconds',
        value: 9
    },
    {
        label: '1 action / 5 seconds',
        value: 5
    },
]

const distanceLift = [
    {
        label: '10"',
        value: 10
    },
    {
        label: '20"',
        value: 20
    },
    {
        label: '30"',
        value: 30
    },
]

// Snook Carrying
export const carryPoint = [
    {
        label: 'Waist Height (elbows bent)',
        value: 43
    },
    {
        label: 'Below Waist Height (elbows straight)',
        value: 33
    },
]

export const carryPointF = [
    {
        label: 'Waist Height (elbows bent)',
        value: 40
    },
    {
        label: 'Below Waist Height (elbows straight)',
        value: 31
    },
]

const carryDistance = [
    {
        label: '7"',
        value: 7
    },
    {
        label: '14"',
        value: 14
    },
    {
        label: '27"',
        value: 27
    },
]

const carryFrequency = frequency.slice(0, 5).concat([
    {
        label: '1 action / 20 seconds',
        value: 20
    },
    {
        label: '1 action / 10 seconds',
        value: 10
    },
])

// Push/Pull
export const pushPullPoint = [
    {
        label: 'Low (about 25)',
        value: 25
    },
    {
        label: 'Middle (about 37)',
        value: 37
    },
    {
        label: 'High (about 57)',
        value: 57
    },
]

export const pushPullPointF = [
    {
        label: 'Low (about 22)',
        value: 22
    },
    {
        label: 'Middle (about 35)',
        value: 35
    },
    {
        label: 'High (about 53)',
        value: 53
    },
]

const pushPullDistance = [
    {
        label: '7"',
        value: 7
    },
    {
        label: '25"',
        value: 25
    },
    {
        label: '50"',
        value: 50
    },
]

const pushPullFrequency = frequency.slice(0, 5).concat([
    {
        label: '1 action / 30 seconds',
        value: 30
    },
    {
        label: '1 action / 15 seconds',
        value: 15
    },
    {
        label: '1 action / 12 seconds',
        value: 12
    },
    {
        label: '1 action / 6 seconds',
        value: 6
    },
])


export const getInputsConfig = (gender = 'male') => {
    return {
        'lifting': [
            {
                label: 'Vertical Location (inches)',
                key: 'vertical_location',
                payload_key: 'vertical_location',
                options: gender === 'female' ? liftLowerPointF : liftLowerPoint,
                value: null,
                auto_generated: true
            },
            {
                label: 'Horizontal Location (inches)',
                key: 'horizontal_location',
                payload_key: 'horizontal_location',
                options: horizontalDistance,
                value: null,
                auto_generated: true
            },
            {
                label: 'Distance Of Lift (inches)',
                payload_key: 'distance_of_lift',
                key: 'distance_of_lift',
                options: distanceLift,
                value: null,
                auto_generated: true
            },
            {
                label: 'Frequency',
                key: 'frequency',
                payload_key: 'frequency',
                options: frequency,
                value: null,
                auto_generated: false
            },
            {
                label: 'Actual Weight (lbs)',
                payload_key: 'actual_weight',
                key: 'weight',
                options: null,
                max: 120,
                value: null,
                auto_generated: false
            }
        ],
        'lowering': [
            {
                label: 'Vertical Location (inches)',
                key: 'vertical_location',
                payload_key: 'vertical_location',
                options: gender === 'female' ? liftLowerPointF : liftLowerPoint,
                value: null,
                auto_generated: true
            },
            {
                label: 'Horizontal Location (inches)',
                key: 'horizontal_location',
                payload_key: 'horizontal_location',
                options: horizontalDistance,
                value: null,
                auto_generated: true
            },
            {
                label: 'Distance Of Lift (inches)',
                payload_key: 'distance',
                key: 'distance_of_lift',
                options: distanceLift,
                value: null,
                auto_generated: true
            },
            {
                label: 'Frequency',
                key: 'frequency',
                payload_key: 'frequency',
                options: frequency,
                value: null,
                auto_generated: false
            },
            {
                label: 'Actual Weight (lbs)',
                payload_key: 'actual_weight',
                key: 'weight',
                options: null,
                max: 120,
                value: null,
                auto_generated: false
            }
        ],
        'carrying': [
            {
                label: 'Vertical Location (inches)',
                key: 'vertical_location',
                payload_key: 'vertical_location',
                options: gender === 'female' ? carryPointF : carryPoint,
                value: null,
                auto_generated: true
            },
            {
                label: 'Carry Distance (ft)',
                key: 'carry_distance',
                payload_key: 'carry_distance',
                options: carryDistance,
                value: null,
                auto_generated: true
            },
            {
                label: 'Frequency',
                key: 'frequency',
                payload_key: 'frequency',
                options: carryFrequency,
                value: null,
                auto_generated: false
            },
            {
                label: 'Actual Weight (lbs)',
                payload_key: 'actual_weight',
                key: 'weight',
                options: null,
                max: 120,
                value: null,
                auto_generated: false
            }
        ],
        'pushing': [
            {
                label: 'Vertical Location (inches)',
                payload_key: 'vertical_location',
                key: 'vertical_location', //json key
                options: gender === 'female' ? pushPullPointF : pushPullPoint,
                value: null,
                auto_generated: true
            },
            {
                label: 'Push Distance (ft)',
                key: 'push_pull_distance',
                payload_key: 'push_pull_distance',
                options: pushPullDistance,
                value: null,
                auto_generated: true
            },
            {
                label: 'Frequency',
                key: 'frequency',
                payload_key: 'frequency',
                options: pushPullFrequency,            
                value: null,
                auto_generated: false
            },
            {
                label: 'Initial Force (lbs)',
                key: 'initial_force',
                payload_key: 'initial_force',
                options: null,
                max: 120,
                value: null,
                auto_generated: false
            },
            {
                label: 'Sustained Force (lbs)',
                key: 'sustained_force',
                payload_key: 'sustained_force',
                options: null,
                max: 120,
                value: null,
                auto_generated: false
            }
        ],
        'pulling': [
            {
                label: 'Vertical Location (inches)',
                key: 'vertical_location',
                payload_key: 'vertical_location',
                options: gender === 'female' ? pushPullPointF : pushPullPoint,
                value: null,
                auto_generated: true
            },
            {
                label: 'Pull Distance (ft)',
                key: 'push_pull_distance',
                payload_key: 'push_pull_distance',
                options: pushPullDistance,
                value: null,
                auto_generated: true
            },
            {
                label: 'Frequency',
                key: 'frequency',
                payload_key: 'frequency',
                options: pushPullFrequency,
                value: null,
                auto_generated: false
            },
            {
                label: 'Initial Force (lbs)',
                key: 'initial_force',
                payload_key: 'initial_force',
                options: null,
                max: 120,
                value: null,
                auto_generated: false
            },
            {
                label: 'Sustained Force (lbs)',
                key: 'sustained_force',
                payload_key: 'sustained_force',
                options: null,
                max: 120,
                value: null,
                auto_generated: false
            }
        ]
    }
}