import { defineStore } from 'pinia';
import SegmentMappingService from 'src/services/segment_mapping.js'
import { useStationStore } from './station'

export const useSegmentMapingStore = defineStore('segmentMapping', {
    state: () => ({}),

    getters: {},

    actions: {
        async startSegmentationMapping() {
            console.log('started seg mapping!')
            const { newStudyId: studyId } = useStationStore()
            return await SegmentMappingService.startSegmentMapping(studyId)
        },

        async startSegmentRemaping() {
            console.log('remap segemntation req')
            // return
            const { newStudyId: studyId } = useStationStore()
            const [error] = await SegmentMappingService.startSegmentsRemaping(studyId)
            if (error) {
                console.log('error:', error)
            }
        }
    }
})
