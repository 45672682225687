import { defineStore } from 'pinia'
import HandTrackingService from 'src/services/handTracking'
import LLMService from 'src/services/llm'
import { useStationStore } from './station'
import { useSegmentationStore } from './segmentation'
import {
  getSumOfMaxPotentialSavingsForLayout,
  getSumOfMaxPotentialSavingsForSteps,
  getTotalAmountSavedPerYear
} from 'src/utils/helpers'

export const useHandTrackingStore = defineStore('handTracking', {
  state: () => ({
    handTrackingData: {},
    ergonomicsResult: [],
    summarizedSuggestions: [],
    isfetchingLLMSuggestions: false
  }),

  getters: {
    regionsDetails: (state) => {
      if (!state.ergonomicsResult.length) return []
      const regionsZone = []
      state.ergonomicsResult.forEach((el) => {
        const zonePercentages = Object.entries(el.suggestion.percentages).reduce(
          (res, [zone, regions]) => {
            Object.entries(regions).forEach(([region, percent]) => {
              res[region] = {
                zone,
                percent
              }
            })
            return res
          },
          {}
        )
        regionsZone.push(zonePercentages)
      })
      return regionsZone
    },

    potentialSavingsForLayoutSuggestions: (state) => {
      if (!state.ergonomicsResult.length) return []
      const multipLier = {
        'Zone 3': 0.2,
        'Zone 4': 0.3
      }
      const { totalCycleTime } = useSegmentationStore()
      let regionsDetails = {}
      const layoutSuggestions = {}

      state.ergonomicsResult.forEach((el, i) => {
        const recoms = el.suggestion?.recommendations?.map((el) => {
          const { source_region, dest_region } = el
          regionsDetails = state.regionsDetails[i]
          if (
            ['Zone 3', 'Zone 4'].includes(regionsDetails[source_region[0]]?.zone) &&
            ['Zone 1', 'Zone 2'].includes(regionsDetails[dest_region[0]]?.zone)

          ) {
            const totalZonePercentage = source_region
              .map((el) => regionsDetails[el].percent)
              .reduce((res, el) => res + el, 0)

            const totalZoneTime = totalCycleTime * (totalZonePercentage / 100)

            const timeSavedPerCycle =
              totalZoneTime * multipLier[regionsDetails[source_region[0]].zone]

            const totalAmountSavedPerYear = getTotalAmountSavedPerYear(timeSavedPerCycle)

            return {
              text: `Potential Savings: ${timeSavedPerCycle.toFixed(
                1
              )} sec, $${totalAmountSavedPerYear} per year`,
              timeSavedPerCycle,
              totalAmountSavedPerYear
            }
          }
          else return null
        })
        layoutSuggestions[i] = [...recoms]
      })
      console.log('layoutsuggestion ->', layoutSuggestions)
      return layoutSuggestions
    },

    orthogonalValuesForPotentialSavings() {
      const stepsSavings = getSumOfMaxPotentialSavingsForSteps()
      const layoutSavings = getSumOfMaxPotentialSavingsForLayout()

      const res = {
        timeSavedPerCycle: Math.sqrt(
          Math.pow(stepsSavings.timeSavedPerCycle, 2) + Math.pow(layoutSavings.timeSavedPerCycle, 2)
        ),
        totalAmountSavedPerYear: Math.round(
          Math.sqrt(
            Math.pow(stepsSavings.totalAmountSavedPerYear, 2) +
            Math.pow(layoutSavings.totalAmountSavedPerYear, 2)
          )
        )
      }
      return res
    }
  },

  actions: {
    resetHandTrackingData() {
      this.ergonomicsResult = []
      this.handTrackingData = {}
      this.summarizedSuggestions = []
    },

    async extractHandTracklets() {
      const { newStudyId: studyId } = useStationStore()
      // return
      return await HandTrackingService.extractHandTracklets(studyId)
    },

    async generateHandTrackingResult() {
      const { newStudyId: studyId } = useStationStore()
      return await HandTrackingService.generateHandTrackingResult(studyId)
    },

    async startHandTracking() {
      const { newStudyId: studyId } = useStationStore()
      const [error] = await HandTrackingService.startHandTracking(studyId)
      if (error) {
        console.log('error:', error)
      }
    },

    async getHandTrackingResult() {
      const { newStudyId: studyId } = useStationStore()
      const [error, data] = await HandTrackingService.getHandTrackingResult(studyId)
      if (error) {
        console.log('error:', error)
        this.handTrackingData = []
        return
      }
      this.handTrackingData = data.reduce((res, el) => {
        res[el.step_index] = el
        return res
      }, {})
    },

    async getErgonomicsResult() {
      const { newStudyId: studyId } = useStationStore()

      const [error, data] = await HandTrackingService.getLayoutSuggestions(studyId)
      if (error) {
        console.log('error:', error)
        return
      }
      this.ergonomicsResult = data.map((obj) => ({ ...obj }))
      console.log('htrack res ->', this.ergonomicsResult)
    },

    async getSummarizedSuggestions() {
      const { newStudyId: studyId } = useStationStore()
      this.isfetchingLLMSuggestions = true
      const params = {
        llm_type: 'chatgpt',
        stream: false
      }
      const [error, data] = await LLMService.getSummarizedSuggestions(studyId, params)
      this.isfetchingLLMSuggestions = false
      if (error) {
        console.log('llm error:')
        return
      }

      if (data.status == 'success') {

        const suggestions = Object.values(data.body)
          .map((el) => el.split('\n\n'))
          .reduce((res, el) => [...res, ...el], [])

        const potentialSaving = this.orthogonalValuesForPotentialSavings

        const potentialSavingsSuggestion = `Overall, we anticipate reducing the cycle time by ${potentialSaving?.timeSavedPerCycle?.toFixed(
          2
        )} seconds if the following improvement suggestions are implemented. This should translate to $${potentialSaving?.totalAmountSavedPerYear} per year in savings just from operator wages to assembly the same number of units, assuming 3800 working hours per year and $15 per hour in wages.`


        if (potentialSaving?.timeSavedPerCycle || potentialSaving?.totalAmountSavedPerYear) {
          suggestions.unshift(potentialSavingsSuggestion)
        }

        this.summarizedSuggestions = suggestions
      }
    }
  }
})
