<template>
  <a-modal :open="showVideo" :footer="null" destroy-on-close @cancel="showVideo = false">
    <template #title>
      <div class="w-100 d-flex gap-2 align-items-start pe-4">
        <a-typography-text style="width: 70px"> Step #{{ currStepObj?.step_index + 1 }}: </a-typography-text>
        <a-typography-text class="flex-fill text-wrap">
          {{ stepName }}
        </a-typography-text>
      </div>
      <a-divider class="my-2" />
    </template>

    <div class="w-100">
      <a-space class="py-2 d-flex align-items-start justify-content-between">
        <a-typography-text class="d-block position-relative">
          {{ currStepObj?.work_cycle?.name }}
          <span v-if="currStepObj?.work_cycle?.is_master"> (Master) </span>
        </a-typography-text>
        <a-typography-text
          >Step Duration: {{ getFormattedTime(currStepObj.seg_time) }} s</a-typography-text
        >
      </a-space>

      <div class="tableVideoWrapper">
        <SegmentVideo
          v-for="(cycle, index) in selectedStepCycles"
          :key="cycle.id"
          :video-url="!isDemoOngoing ? cycle.url : stationDesignDummyObject.url"
          :segment-play="segmentPlay"
          :duration-time="segmentPlay.duration"
          :frame-per-sec="cycle.videoFPS"
          :allowFullScreen="true"
          :playback-rate="segmentPlay?.playbackRate"
          :styles="{ maxHeight: '480px', objectFit: 'cover' }"
          :wrapperStyles="{
            display: currCycle === index ? 'flex !important' : 'none !important'
          }"
          @updatePlaybackRate="updatePlaybackRate"
        />
      </div>

      <div class="w-100 mt-1 pb-4 position-relative" v-if="segmentPlay">
        <div class="d-flex align-items-center gap-2 position-absolute start-50 translate-middle-x">
          <LeftOutlined
            class="cycle-nav-icon"
            :class="{ disabled: currCycle <= 0 }"
            @click="handleCycleChange(-1)"
          />
          <span class="lh-1 fs-5"> {{ currCycle + 1 }} / {{ selectedStepCycles?.length }} </span>
          <RightOutlined
            :class="{ disabled: currCycle === selectedStepCycles?.length - 1 }"
            class="cycle-nav-icon"
            @click="handleCycleChange(1)"
          />
        </div>
      </div>
    </div>
  </a-modal>
  <a-table
    id="steps-result-table"
    :bordered="true"
    :dataSource="stepsData"
    :columns="stepsTableColumn"
    size="small"
    :pagination="false"
    :scroll="tableScroll"
    :transformCellText="transformCellText"
  >
    <template #title>
      <a-space>
        <a-typography-title class="m-0" :level="5"> Step List : </a-typography-title>
        <a-tag color="processing">{{ stepsData?.length }}</a-tag>
      </a-space>
    </template>

    <template #bodyCell="{ record, column, text, index }">
      <span v-if="column.dataIndex === 'index'">{{ index + 1 }} </span>
      <span v-if="column.dataIndex === 'assessment'">
        {{ getValueType(record.assessment) }}
      </span>
      <span v-if="column.dataIndex === 'second_shortest'">
        {{ text || 0 }}
      </span>
      <span
        v-if="column.dataIndex === 'actions'"
        class="w-100 d-flex flex-wrap justify-content-center"
        >{{ record.actions.length ? record.actions?.join(', ') : '-' }}</span
      >
      <span v-if="column.dataIndex === 'segment_video'" class="d-flex justify-content-center">
        <a-button
          class="d-flex align-items-center justify-content-center"
          shape="circle"
          @click="handleChangeStep(record.step_index, record.name)"
        >
          <template #icon>
            <CaretRightOutlined style="font-size: 20px" />
          </template>
        </a-button>
      </span>
    </template>
  </a-table>
</template>
<script>
import { stepsColumn, VA_Keys } from '../config'
import { mapState } from 'pinia'
import { useStationStore } from 'src/stores/station'
import { useSegmentationStore } from 'src/stores/segmentation'
import { CaretRightOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons-vue'
import { useUserStore } from 'src/stores/user'
import { getFormattedTime } from 'src/utils/helpers'
import SegmentVideo from './SegmentVideoControlled.vue'

export default {
  setup: () => ({
    stepsColumn,
    VA_Keys,
    getFormattedTime
  }),
  components: { CaretRightOutlined, LeftOutlined, RightOutlined, SegmentVideo },
  data() {
    return {
      showVideo: false,
      selectedStepCycles: [],
      currCycle: 0,
      stepName:null,
      currStepObj: null,
      segmentPlay: {
        start: 0,
        end: 0,
        playbackRate: 1
      }
    }
  },
  computed: {
    ...mapState(useStationStore, [
      'stationDesignDummyObject',
      'studyFilesObject',
      'newStudyId',
      'workCycles'
    ]),
    ...mapState(useUserStore, ['isDemoOngoing']),
    ...mapState(useSegmentationStore, [
      'segmentationDatForSuggestions',
      'stepToCyclesSegmentation',
      'stepsTableData'
    ]),

    stepTimeColumns() {
      if (this.workCycles.length < 2) return []
      const sorted = this.workCycles.slice().sort((a, b) => b.is_master - a.is_master)
      return sorted.map((c, ix) => ({
        title: ix + 1,
        key: `step_time_${c.id}`,
        dataIndex: `step_time_${c.id}`,
        align: 'center',
        width: 60
      }))
    },

    stepsTableColumn() {
      let columns = stepsColumn
      if (this.workCycles.length < 2) return columns
      columns = columns.map((col) =>
        col.key === 'observedTime' ? { ...col, children: [...this.stepTimeColumns] } : col
      )
      return this.workCycles.length > 8 ? columns.filter(({ key }) => key !== 'actions') : columns
    },

    stepsData() {
      if (this.isDemoOngoing) {
        return this.stationDesignDummyObject.segmentationDatForSuggestions
      }
      console.log('table data:', this.stepsTableData)
      return this.stepsTableData
    },

    tableScroll() {
      const max = 8
      // 900 -> other column width
      const contentWidth = 900 + 60 * this.workCycles.length
      return this.workCycles.length > max ? { x: `calc(${contentWidth}px)` } : {}
    }
  },
  methods: {
    getValueType(assesment) {
      return this.VA_Keys[assesment]
    },

    transformCellText({ text, column }) {
      return column.key.includes('step_time') && (!text || !text?.length) ? '0' : text
    },

    setSelectedStepCycles(currStepIndex) {
      this.selectedStepCycles = this.stepToCyclesSegmentation[currStepIndex] || []
    },

    updatePlaybackRate(value) {
      this.segmentPlay.playbackRate = value
    },

    handleCycleChange(count = 0) {
      this.currCycle = this.currCycle + count
      const segment = this.selectedStepCycles[this.currCycle]
      if (!segment) return
      this.currStepObj = segment
      this.handlePlaySegmentVideo(segment)
    },

    handlePlaySegmentVideo(segment) {
      const { segment_start, segment_end, work_cycle, videoFPS } = segment
      this.segmentPlay = {
        start: segment_start / videoFPS,
        end: segment_end / videoFPS,
        duration: Number(((segment_end - segment_start) / videoFPS).toFixed(2)),
        is_master: work_cycle?.is_master,
        playbackRate: this.segmentPlay?.playbackRate
      }
    },

    handleChangeStep(index,stepName) {
      this.currCycle = 0
      this.stepName=stepName
      this.setSelectedStepCycles(index)
      this.handleCycleChange()
      this.showVideo = true
    }
  }
}
</script>
<style>
#steps-result-table > .ant-table-title {
  background: #fafafa !important;
}
#steps-result-table > .ant-table-title .ant-space .ant-space-item .ant-typography {
  color: black !important;
}

.tableVideoWrapper {
  position: relative;
  overflow: hidden;
  /* width: 400px; */
}

.step-name-column {
  max-width: 150px !important;
}
</style>
