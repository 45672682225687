import ApiHelper from './index'

const startClassification = async (studyId) => {
  return await ApiHelper.get(`deep_model/therbligs_classification/${studyId}`)
}

const assignTherbligsToSegments = async (studyId) => {
  return await ApiHelper.get(`deep_model/assign_therbligs/${studyId}`)
}

const getTherbligs = async () => {
  return await ApiHelper.get('station_improvement/v2/actions')
}

const assignTherbligFromStepName = async (studyId, payload) => {
  return await ApiHelper.post(`station_improvement/assign_therblig_from_step_name/${studyId}/`, payload)
}

const addIrrelevantQuestion = async (payload)=>{
  return await ApiHelper.post(`station_improvement/hidden-questions/`,payload)
}

export default {
  getTherbligs,
  startClassification,
  assignTherbligsToSegments,
  assignTherbligFromStepName,
  addIrrelevantQuestion
}
