import { defineStore } from 'pinia'
import AuthService from 'src/services/auth'
import { useAuthStore } from './auth'
export const useUserStore = defineStore('user', {
  state: () => ({
    users: [],
    isDemoOngoing: false,
  }),
  getters: {
    userIdToNameMap() {
      return this.users.reduce((res, el) => {
        res[el.id] = el.username + ' ' + el.lastname
        return res
      }, {})
    }
  },
  actions: {
    initializeDemo(){
      this.isDemoOngoing = true
    },
    destroyDemo(){
      this.isDemoOngoing = false
    },
    async getUsers() {
      const { organization } = useAuthStore()
      const params = {
        Organization: organization
      }
      const [error, data] = await AuthService.getUsers(params)
      if (error) {
        console.log(error)
        return
      }
      this.users = data
    }
  }
})
