import { defineStore } from 'pinia'
import { useSegmentationStore } from './segmentation'
import { useUserStore } from './user'
import { useFileStore } from './file'
import { uuid } from 'vue-uuid'
import { addStatusInStudy, getAllModels, getSuggestionModels, getTimeStudyModels } from 'src/utils/helpers'
import { InProgressStatus, processStatus } from 'src/components/User/StationBalancing/config'
import { stationDesignDummyData, dummyStudy } from 'src/components/User/DummyData/station-design-data'
import StationService from 'src/services/station'

export const useStationStore = defineStore('station', {
  state: () => ({
    studyFilesObject: {},
    masterCycleFileUrl: '',
    masterCycleVideoId: null,
    newStudyId: null,
    newStudy: {},
    studies: [],
    isFetchingStudies: false,
    totalStudies: 0,
    numberOfSteps: 0,
    areProcessesRunning: false,
    areSuggestionProcessRunning: false,
    study5W1HQuestions: {},
    kaizenNotesQuestions: [],
    numSegments: null,
    perHourCost: 15,
    workingHoursPerYear: 3800,
    selectedWorkCycle: null,
    workCycles: [],
    workRegionList: [],
    saveRegions: false,
    noOfWorkRegions: null,
    scaleCoordinate: null,
    isOutlineComplete: false,
    isSegmentsSelected: false,
    isStepsDataLoading: false,
    stationDesignDummyObject: stationDesignDummyData
  }),

  getters: {
    studyName: (state) => state.newStudy?.study_name,

    isCell: (state) => {
      return state.newStudy?.station?.type === 'cell'
    },

    isStudyInProgress() {
      return ['Processing'].includes(this.newStudy?.status)
    },

    videoFPS() {
      return this.newStudy.file?.meta?.fps
    },

    segmentationStatus: (state) => {
      const segmentation = state.newStudy.processes?.find((p) => p.process.name === 'Segmentation')
      return { name: segmentation?.status?.name, progress: segmentation?.progress }
    },

    classificationStatus: (state) => {
      const classification = state.newStudy.processes?.find(
        (p) => p.process.name === 'Classification'
      )
      return { name: classification?.status?.name, progress: classification?.progress }
    },

    handTrackingStatus: (state) => {
      const handTracking = state.newStudy.processes?.find((p) => p.process.name === 'Hand Tracking')
      return { name: handTracking?.status?.name, progress: handTracking?.progress }
    },

    homographyStatus: (state) => {
      const homography = state.newStudy.processes?.find((p) => p.process.name === 'Ergonomics')
      return { name: homography?.status?.name, progress: homography?.progress }
    },

    mappingStatus: (state) => {
      const mapping = state.newStudy.processes?.find((p) => p.process.name === 'Segments Mapping')
      return { name: mapping?.status?.name, progress: mapping?.progress }
    },

    operatorTrackingStatus: (state) => {
      const visualization = state.newStudy.processes?.find((p) => p.process.name === 'Person Tracking')
      return { name: visualization?.status?.name, progress: visualization?.progress }
    },

    stepNameRecommendationStatus: (state) => {
      const nameRecommendation = state.newStudy.processes?.find((p) => p.process.name === 'Step Name Recommendation')
      return { name: nameRecommendation?.status?.name, progress: nameRecommendation?.progress }
    },

    workingSecondsPerYear: (state) => {
      return state.workingHoursPerYear * 3600
    },

    totalCyclesPerYear() {
      const { totalCycleTime } = useSegmentationStore()
      return (this.workingHoursPerYear * 3600) / totalCycleTime
    },

    cyclesIdToObjMap() {
      return this.workCycles.reduce((res, el) => {
        res[el.id] = { ...el }
        return res
      }, {})
    },

    selectedMasterCycleId() {
      return this.workCycles?.find((c) => c.is_master === true)?.id
    },

    masterCycle() {
      return this.workCycles?.find((c) => c.is_master === true) || null
    },

    regionsIdsToIndexMap() {
      if (!this.workRegionList.length) return {}
      return this.workRegionList.reduce((res, el, index) => {
        res[el.id] = index + 1
        return res
      }, {})
    },

    areAllProcessesCompleted(state) {
      const allModels = getAllModels(state.isCell)
      const isStudyCompleted = state.newStudy.processes?.filter(p => allModels.includes(p.process?.name)).every((el) => el.status?.name === processStatus['Finished'])
      return isStudyCompleted
    },

    timeStudyProcessCompleted(state) {
      const timeStudyProcesses = getTimeStudyModels(state.isCell)
      const isTimeStudyCompleted = state.newStudy.processes?.filter(p => timeStudyProcesses.includes(p.process?.name)).every((el) => el.status?.name === processStatus['Finished'])
      return isTimeStudyCompleted
    },

    suggestionProcessInProgress(state) {
      const suggestionProcesses = getSuggestionModels(state.isCell)
      const isImprovementSuggestionRunning = state.newStudy.processes?.filter(p => suggestionProcesses.includes(p.process?.name)).some((el) => {
        return el.status?.name && InProgressStatus.includes(el.status.name)
      })
      return isImprovementSuggestionRunning
    },

    hasAllVideosHaveCycle() {
      if (!this.workCycles?.length) return false
      const fileIds = Object.keys(this.studyFilesObject)
      const cyclesFiles = new Set(this.workCycles.map((c) => c.file?.toString()))
      return JSON.stringify(fileIds?.sort()) === JSON.stringify([...cyclesFiles]?.sort())
    },

    commentsList() {
      if (!this.workCycles.length) return
      const sortedCycles = this.workCycles.sort((a, b) => b.is_master - a.is_master)
      return sortedCycles.map((cycle, index) => ({
        cycle_name: cycle.name,
        comment: cycle.comments,
        index: index + 1
      }))
    },
  },

  actions: {
    insertDummyStudy() {
      this.studies = [dummyStudy, ...this.studies]
    },

    removeDummyStudy() {
      this.studies = this.studies.filter((study) => study.id !== 0)
    },

    setRegionsLoading(value) {
      this.saveRegions = value
    },

    setSegmentsSelected(value) {
      this.isSegmentsSelected = value
    },

    setAreProcessesRunning(value) {
      this.areProcessesRunning = value
    },

    setStepsDataLoading(value) {
      this.isStepsDataLoading = value
    },

    setSuggestionProcessRunning(value) {
      this.areSuggestionProcessRunning = value
    },

    setNumSegments(value) {
      this.numSegments = value
    },

    resetNewStudyState() {
      this.newStudy = {}
    },

    setWorkRegionsCount(count) {
      this.noOfWorkRegions = count
    },

    setScaleCoordinate(value) {
      this.scaleCoordinate = value
    },

    setOutlineComplete(value) {
      this.isOutlineComplete = value
    },

    setStudyFilesObject(value) {
      this.studyFilesObject = value
    },

    resetMasterCycleValues() {
      this.masterCycleVideoId = null
      this.masterCycleFileUrl = null
    },

    async createStation(payload) {
      const [error, data] = await StationService.createStation(payload)
      if (error) {
        console.log('error:', error?.response?.data)
        return error?.response?.data || {}
      }
      return data
    },

    async fetchStations() {
      const [error, data] = await StationService.fetchStations()
      if (error) {
        console.log('error:', error)
        return
      }
      return data
    },

    async updateStation(id, payload, callStudy = true) {
      const [error] = await StationService.updateStation(id, payload)
      if (error) {
        console.log('error:', error)
        return error?.response?.data
      }
      if (callStudy) await this.fetchStudy(this.newStudyId)
      return {}
    },

    async createNewStudy(payload) {
      const [error, data] = await StationService.createNewStudy(payload)
      if (error) {
        console.log('error:', error)
        return 0
      }
      // this.newStudy = addStatusInStudy({ ...data })
      // this.newStudyId = data.id
      return data.id
    },

    async fetchStudy(studyId) {
      const { isDemoOngoing } = useUserStore()
      if (isDemoOngoing) {
        let data = this.stationDesignDummyObject.study
        this.newStudy = data
        this.newStudyId = data.id
        this.numberOfSteps = data.station?.no_of_steps
        return
      }

      const [error, data] = await StationService.getStudy(studyId)
      if (error) {
        console.log('error:', error)
        return
      }

      this.newStudy = addStatusInStudy({
        ...data,
        files: !data.files || !data.files?.length ? [{ ...data.file }] : [...data.files]
      })
      this.newStudyId = data.id
      this.numberOfSteps = data.station?.no_of_steps
    },

    async updateStudy(payload, callStudy = true) {
      const [error] = await StationService.updateStudy(this.newStudyId, payload)
      if (error) {
        console.log('error:', error)
        return
      }
      if (callStudy) await this.fetchStudy(this.newStudyId)
      // const updatedData = { ...this.newStudy, ...payload }
      // this.newStudy = addStatusInStudy({ ...updatedData })
      // this.numberOfSteps = updatedData.station?.no_of_steps
    },

    async fetchWorkCyles() {
      const [error, data] = await StationService.fetchWorkCycles(this.newStudyId)
      if (error) {
        console.log('error: ', error)
        return
      }
      this.workCycles = [...data]?.map(({ cycle_end_frame_no, cycle_start_frame_no, ...res }, index) => {
          const file = this.studyFilesObject[res.file]
          const fps = file?.fps || 30
          return {
            ...res,
            cycle_end_frame_no,
            cycle_start_frame_no,
            file: res.file ? res.file : this.newStudy.file?.id, //SYNC
            segment_start: cycle_start_frame_no / fps,
            segment_end: cycle_end_frame_no / fps,
            cycle_time_duration: (cycle_end_frame_no - cycle_start_frame_no) / fps,
            cycle_index: index,
            thumbnail: file?.thumbnail
          }
        })
    },

    async addWorkCycles(payload) {
      const studyId = this.newStudyId
      const [error] = await StationService.createWorkCycles(studyId, payload)
      if (error) {
        console.log('error: ', error)
        return
      }
    },

    async updateWorkCycle(cycleId, payload) {
      const [error, data] = await StationService.updateWorkCycle(cycleId, payload)
      if (error) {
        console.log('error: ', error)
        return false
      }
      return data
    },

    async deleteWorkCycle(cycleId) {
      const [error, data] = await StationService.removeWorkCycle(cycleId)
      if (error) {
        console.log('error: ', error)
        return
      }
      return data
    },

    updateWorkCycleInList(cycles) {
      this.workCycles = [...cycles]
    },

    async fetchWorkRegions() {
      const [error, data] = await StationService.fetchWorkRegions(this.newStudyId)
      if (error || !data.length) {
        this.setWorkRegionsList([])
        return
      }
      const regions = data.map((r) => ({ ...r, name: uuid.v4() }))
      this.setWorkRegionsList(regions)
      const first_region = data.find((r) => Object.prototype.hasOwnProperty.call(r.scale_cordinates, 'line') === true)
      this.scaleCoordinate = {
        line: first_region?.scale_cordinates?.line || [],
        line_distance: first_region?.scale_cordinates?.line_distance || null
      }
    },

    setWorkRegionsList(payload) {
      this.workRegionList = payload
      this.noOfWorkRegions = payload.length ? payload.length : null
    },

    async addWorkRegions(payload) {
      const studyId = this.newStudyId
      const [error] = await StationService.createWorkRegions(studyId, payload)
      if (error) {
        console.log('error: ', error)
        return
      }
    },

    async updateWorkRegion(regionId, payload) {
      const [error, data] = await StationService.updateWorkRegion(regionId, payload)
      if (error) {
        console.log('error: ', error)
        return
      }
      return data
    },

    async deleteRegion(regionId) {
      const [error, data] = await StationService.removeWorkRegion(regionId)
      if (error) {
        console.log('error: ', error)
        return
      }
      return data
    },

    async fetchStudies(params, loading = true) {
      if (loading) this.isFetchingStudies = true
      const [error, data] = await StationService.fetchStudies(params)
      this.isFetchingStudies = false
      if (error) return
      const { count, results } = data
      this.totalStudies = count
      this.studies = results.map(addStatusInStudy)
    },

    async fetchKaizenNotesQuestions(studyId) {
      const [error, data] = await StationService.fetchKaizenNotesQuestions(studyId)
      if (error) {
        console.log('error:', error)
        return
      }
      this.kaizenNotesQuestions = data
    },

    async updateKaizenNotesResponse(studyId, responses) {
      const [error, data] = await StationService.updateKaizenNotesQuestionResponse(
        studyId,
        responses
      )
      if (error) {
        console.log('error:', error)
        return
      }
      this.kaizenNotesQuestions = data
    },

    async deleteStudy(studyId) {
      const [err] = await StationService.deleteStudy(studyId)
      if (err) {
        console.log('error ->', err)
        return
      }
    },

    async runChainedServices(numSegments) {
      return await StationService.runModels(this.newStudyId, numSegments)
    },

    async runTimeStudy(numSegments) {
      return await StationService.runTimeStudyModels(this.newStudyId, numSegments)
    },

    async runImprovementSuggestions() {
      return await StationService.runSuggestionModels(this.newStudyId)
    },

    async getStudyFilesObject() {
      const { fetchPresignedUrl } = useFileStore()
      const files = this.newStudy?.files
      if (!files?.length) return
      const videoUrlPromises = files.map(async ({ meta, ...file }) => {
        const url = await fetchPresignedUrl(file.location)
        return { ...file, ...meta, url: url }
      })
      const videoUrls = await Promise.all(videoUrlPromises)
      this.studyFilesObject = videoUrls.reduce((res, el) => {
        res[el.id] = el
        return res
      }, {})
    },

    setMaterCycleVideoUrl() {
      if (!this.workCycles.length) return
      const masterCycle = this.workCycles.find((cycle) => cycle.is_master === true)
      if (!masterCycle || !this.studyFilesObject?.[masterCycle?.file]) return
      this.masterCycleVideoId = masterCycle.file
      this.masterCycleFileUrl = this.studyFilesObject[masterCycle.file]?.url
    },

    async createCycleVideo(cycleId) {
      const [error] = await StationService.createCycleVideo(this.newStudyId, cycleId)
      if (error) return 0
      return 1
    },

    async getCycleVideoData(cycleId) {
      const [error, data] = await StationService.getWorkCycle(this.newStudyId, cycleId)
      if (error) return 0
      const { video_file } = data || {}
      const cycleVideoObj = { location: video_file?.location, status: video_file?.process?.status?.name, progress: video_file?.process?.progress }
      return cycleVideoObj
    }

  }
})
