import ApiHelper from './index'

const getSummarizedSuggestions = async (studyId, queryParams) => {
  return await ApiHelper.get(
    `deep_model/get_process_improvement_suggestion/${studyId}`,
    queryParams
  )
}

export default {
  getSummarizedSuggestions
}
