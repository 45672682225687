<template>
  <div
    class="progress-container white-width"
    :data-test-id="`prev: ${prevEnd}`"
    :style="{ width: whiteWidth + '% !important' }"
  />

  <a-dropdown class="painter-context" placement="top" :trigger="isMarking ? [] : 'contextmenu'">
    <div
      class="progress-container marked-label"
      :class="[
        { 'highlighted-label': isOverlapped },
        { 'bordered-label': showBorder}
      ]"
      :data-test-id="`${interval.segment_end} to ${interval.segment_start}`"
      :style="[{ width: intervalWidth + '% !important' }, customStyles]"
    >
      <slot name="intervalName"></slot>
    </div>
    <template #overlay>
      <a-menu>
        <a-menu-item style="max-width: 300px" v-if="contextMenuItems['text']">
          {{ contextMenuItems['text'] }}
        </a-menu-item>
        <a-menu-item v-if="contextMenuItems['add']" @click="$emit('addNewInterval')">
          {{ contextMenuItems['add'] }}
        </a-menu-item>
        <a-menu-item
          v-if="contextMenuItems['remove']"
          @click="$emit('removeInterval', intervalIndex)"
        >
          {{ contextMenuItems['remove'] }}
        </a-menu-item>
        <a-menu-item v-if="contextMenuItems['editStart']" @click="clickEditCycle('start')">
          {{ contextMenuItems['editStart'] }}
        </a-menu-item>
        <a-menu-item v-if="contextMenuItems['editEnd']" @click="clickEditCycle('end')">
          {{ contextMenuItems['editEnd'] }}
        </a-menu-item>
        <a-menu-item v-if="contextMenuItems['rename']" @click="clickRenameCycle()">
          {{ contextMenuItems['rename'] }}
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>
<script>
export default {
  name: 'CyclePainter',
  props: [
    'isMarking',
    'contextMenuItems',
    'interval',
    'prevEnd',
    'intervalIndex',
    'currentSliderPercent',
    'customStyles',
    'showBorder'
  ],
  emits: ['removeInterval', 'editInterval', 'addNewInterval', 'renameInterval'],
  computed: {
    whiteWidth() {
      return this.interval.segment_start - this.prevEnd <= 0
        ? this.interval.segment_start
        : this.interval.segment_start - this.prevEnd
    },
    isOverlapped() {
      const { segment_start, segment_end } = this.interval
      const { currentSliderPercent } = this
      return segment_start <= currentSliderPercent && currentSliderPercent <= segment_end
    },
    intervalWidth() {
      const { interval } = this
      const width = interval?.segment_end - interval?.segment_start
      return width
    },
    borderOnOverlapped(){
      return this.showBorder && this.isOverlapped ? '1px solid darkgrey' : 'none'
    }
  },
  methods: {
    clickEditCycle(position) {
      let editPoint = null
      if (position === 'start') {
        editPoint = this.interval.segment_start
      } else if (position === 'end') {
        editPoint = this.interval.segment_end
      }
      this.$emit('editInterval', editPoint, this.intervalIndex, position)
    },

    clickRenameCycle() {
      this.$emit('renameInterval', this.intervalIndex)
    }
  }
}
</script>
<style scoped>
.progress-container {
  height: 20px;
}

.marked-label {
  font-size: small;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  background-color: #add8e6;
  box-sizing: border-box;
}

.highlighted-label {
  background-color: #85cae0;
  border: v-bind(borderOnOverlapped);
}

.bordered-label:hover {
  border: 1.2px solid darkgrey;
}
</style>
