import URL from './baseUrl'
import { getAuthConfig, getConfig } from './config'
import { axiosInstance } from './index'

const baseURL = URL.authServerUrl

async function get(url, queryParams = {}, config) {
  try {
    const { data } = await axiosInstance.get(url, {
      baseURL,
      params: { ...queryParams },
      ...config
    })
    return [null, data]
  } catch (error) {
    return [error]
  }
}

async function post(url, payload, config) {
  try {
    const { data } = await axiosInstance.post(
      url,
      payload,
      { baseURL },
      {
        ...config
      }
    )
    return [null, data]
  } catch (error) {
    return [error]
  }
}

async function patch(url, payload, config) {
  try {
    const { data } = await axiosInstance.patch(
      url,
      payload,
      { baseURL },
      {
        ...config
      }
    )
    return [null, data]
  } catch (error) {
    return [error]
  }
}

async function put(url, payload, config) {
  try {
    const { data } = await axiosInstance.put(
      url,
      payload,
      { baseURL },
      {
        ...config
      }
    )
    return [null, data]
  } catch (error) {
    return [error]
  }
}

async function remove(url, queryParams = {}, spinner = true, config = getAuthConfig()) {
  try {
    const { data } = await axiosInstance.delete(
      url,
      { baseURL },
      {
        params: { ...queryParams },
        ...config
      }
    )
    return [null, data]
  } catch (error) {
    return [error]
  }
}

const config = getConfig()

async function signIn(payload) {
  return await post('account/login_v2', payload, config)
}

async function signUp(payload) {
  return await post('account/register', payload)
}

async function getUser(queryParams) {
  return await get(`account/users_list`, queryParams)
}
async function fetchRole() {
  return await get('account/roles/', {})
}

async function assignUserWithOrg(data) {
  return await put('account/assign_user_org', data)
}

async function updateDeleteUser(email) {
  return await remove('account/update-delete/' + `${email}/`)
}

async function logout() {
  return await patch('account/logout/', {})
}

async function forgotPassword(payload) {
  return await patch(`account/forgot_password/`, payload, config)
}

async function azureSignIn(payload) {
  return await post('account/azure-signin/', payload, config)
}

async function changePassword(payload) {
  return await patch(`account/reset_password/`, payload, false, config)
}

async function getUsers(params) {
  return await get('account/users_list', params)
}

async function getPresignedUrl(payload) {
  return await post('generic/generate_new_url/', payload, getAuthConfig())
}

async function checkAWSSubscription(payload) {
  return await get('account/check_aws_subscription_expiry', payload)
}

const getPresignedPostURL = async (payload) => {
  return await post('generic/generate_presigned_post/', payload)
}

const getVideoMetaData = async (payload) => {
  return await post('organization/get_video_metadata', payload)
}

const refreshJWTToken = async (payload) => {
  return await post('account/token/refresh/', payload)
}

const azureRefreshToken = async (payload) => {
  return await post('account/refresh-azure-token/', payload)
}

const sendOTP = async () => {
  return await get(`account/send_otp_code/`, {})
}

const verifyOTP = async (payload) => {
  return await post(`account/verify_otp_code/`, payload)
}

const resendOtp = async () => {
  return await get('account/resend_otp/', {})
}

const getUserLinkedOrgByUserId = async (params) => {
  return await get(`account/linked_organizations`, params, getAuthConfig())
}

const switchOrganizationByUser = async (payload) => {
  return await patch(`account/switch_user_organization`, payload)
}

export default {
  signIn,
  signUp,
  logout,
  forgotPassword,
  azureSignIn,
  changePassword,
  getUser,
  getUsers,
  getPresignedUrl,
  checkAWSSubscription,
  getPresignedPostURL,
  getVideoMetaData,
  fetchRole,
  assignUserWithOrg,
  updateDeleteUser,
  refreshJWTToken,
  azureRefreshToken,
  sendOTP,
  verifyOTP,
  resendOtp,
  getUserLinkedOrgByUserId,
  switchOrganizationByUser
}
