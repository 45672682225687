<template>
  <a-modal
    centered
    destroyOnClose
    :open="IsAIModalOpen"
    :footer="null"
    :maskClosable="false"
    width="690px"
    @cancel="toggleAiModal('')"
  >
    <template #title> AI Suggest </template>

    <a-space
      v-if="!WorkAuditImprovementSuggestions.length"
      direction="vertical"
      style="width: 100%"
    >
      <a-space>
        <a-typography-text><strong>Kaizen Point:&nbsp;</strong>{{ kaizenPoint }}</a-typography-text>
      </a-space>
      <div class="create-inputs-wrapper">
        <a-form
          ref="form"
          layout="horizontal"
          autocomplete="off"
          :model="formState"
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
        >
          <a-form-item name="team_members">
            <template #label>
              <small>
                Describe the kaizen point with specific process details in the box below, to
                generate recommendations.
              </small>
            </template>

            <a-textarea
              :rows="4"
              v-model:value="formState.description"
              :rules="[{ required: true, message: 'Description Required.' }]"
              placeholder="Describe the kaizen point in detail"
            />
          </a-form-item>
        </a-form>

        <a-divider />
        <a-space class="w-100 d-flex justify-content-end">
          <a-button
            type="primary"
            :disabled="!formState.description.length"
            :loading="workStationAuditSuggestionLoader"
            @click="handleSubmit"
          >
            Generate</a-button
          >
        </a-space>
      </div>
    </a-space>
    <a-space v-else direction="vertical" style="width: 100%">
      <a-typography-title :level="4">List of potential Improvement Points</a-typography-title>

      <a-table
        width="100%"
        :row-selection="rowSelection"
        :columns="columns"
        :data-source="WorkAuditImprovementSuggestions"
        :pagination="false"
        :scroll="{ y: 300 }"
      >
        <template #bodyCell="{ record }">
          <span>
            <b>{{ record.Solution }} : </b> {{ record.Explanation }}
          </span>
        </template>
      </a-table>
      <div class="d-flex justify-content-end align-items-center gap-2">
        <a-button @click="toggleAiModal('')">Cancel</a-button>
        <a-button @click="handleInsertSuggestions" type="primary" :loading="insertLoading"
          >Insert</a-button
        >
      </div>
    </a-space>
  </a-modal>
</template>
<script>
import { fmeaTypeOptions } from 'src/config/fmea-type-config'
import { mapState, mapActions } from 'pinia'
import { useToast } from 'vue-toastification'
import { useStationAudit } from 'src/stores/workStationAudit'
import { useStationStore } from 'src/stores/station'

export default {
  props: ['show'],
  emits: ['close', 'getList'],

  setup: () => ({
    fmeaTypeOptions,
    toast: useToast()
  }),

  data() {
    return {
      insertLoading: false,
      formState: {
        description: ''
      },
      selectedSuggestions: [],
      columns: [
        {
          title: 'Potential Improvement Points',
          dataIndex: 'improvement'
        }
      ],
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedSuggestions = selectedRows
        },
        getCheckboxProps: (record) => ({
          disabled: record.name === 'Disabled User',
          name: record.name
        })
      }
    }
  },

  computed: {
    ...mapState(useStationAudit, [
      'IsAIModalOpen',
      'kaizenPoint',
      'WorkAuditImprovementSuggestions',
      'workStationAuditSuggestionLoader'
    ]),
    ...mapState(useStationStore, ['newStudyId'])
  },
  watch: {},
  methods: {
    ...mapActions(useStationAudit, [
      'fetchWorkStationAuditSuggestions',
      'fetchWorkStationAuditResponseAnalysis',
      'toggleAiModal',
      'insertSuggestions'
    ]),

    async handleInsertSuggestions() {
      this.insertLoading = true
      await this.insertSuggestions(
        this.kaizenPoint,
        this.selectedSuggestions.map((suggestion) => ({
          question: {
            improvement: suggestion.Solution + ': ' + suggestion.Explanation,
            solution: suggestion.Solution,
            explanation: suggestion.Explanation
          }
        }))
      )
      await this.fetchWorkStationAuditResponseAnalysis(this.newStudyId)
      this.insertLoading = false
      this.toggleAiModal('')
    },

    handleCloseModal() {
      this.$refs.form?.resetFields()
      for (const key in this.formState) this.formState[key] = null
      this.$emit('close')
    },

    getCreatePayload() {
      let fmeaData = {}
      fmeaData = {
        llm_type: 'chatgpt',
        requirement: this.formState.description,
        stream: false
      }
      return fmeaData
    },

    clearFormValues() {
      for (const key in this.formState) this.formState[key] = null
      this.$emit('close')
    },

    handleCancel() {
      this.clearFormValues()
    },

    async handleSubmit() {
      const isValid = await this.validateFields()
      if (!isValid) return
      await this.handleConfirm()
    },

    async validateFields() {
      let isValid = false
      await this.$refs.form
        .validateFields()
        .then(() => (isValid = true))
        .catch(() => (isValid = false))

      return isValid
    },

    async handleConfirm() {
      const payload = this.getCreatePayload()
      if (!Object.keys(payload)?.length) return
      await this.fetchWorkStationAuditSuggestions(payload)
      this.formState.description = ''
    }
  }
}
</script>
<style></style>
