import { defineStore } from 'pinia'

export const useNotificationStore = defineStore('notification', {
  state: () => ({
    showReloadModal: false
  }),

  actions: {
    toggleModal() {
      this.showReloadModal = true
    }
  }
})
