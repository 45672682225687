<template>
  <a-modal :open="showVideo" :footer="null" destroy-on-close @cancel="showVideo = false">
    <template #title>
      <div class="w-100 d-flex gap-2 align-items-start pe-4">
        <a-typography-text style="width: 70px">
          Step #{{ currentStepObj?.step_index + 1 }}:
        </a-typography-text>
        <a-typography-text class="flex-fill text-wrap">
          {{ currentStepObj?.name }}
        </a-typography-text>
      </div>
    </template>
    <div class="w-100">
      <SegmentVideo
        :video-url="!isDemoOngoing ? masterCycleFileUrl : stationDesignDummyObject.url"
        :segment-play="segmentPlay"
        :duration-time="segmentPlay.duration"
        :frame-per-sec="currentStepObj?.videoFPS"
        :allow-full-screen="true"
        :playback-rate="playbackRate"
        :styles="{ maxHeight: '480px', objectFit: 'cover' }"
        @updatePlaybackRate="updatePlaybackRate"
      />
    </div>
  </a-modal>

  <highcharts v-if="chartOptions" type="chart" :options="chartOptions" />
</template>
<script>
import { mapState } from 'pinia'
import { useUserStore } from 'src/stores/user'
import { useStationStore } from 'src/stores/station'
import { useSegmentationStore } from 'src/stores/segmentation'
import SegmentVideo from './SegmentVideoControlled.vue'

export default {
  props: ['title', 'stepTimesData'],
  components: {
    SegmentVideo
  },
  mounted() {
    this.setChartOptions()
  },
  data() {
    return {
      showVideo: false,
      currentStepObj: null,
      playbackRate: 1,
      segmentPlay: { start: 0, end: 0 },
      chartOptions: null
    }
  },
  watch: {
    stepTimesData() {
      this.setChartOptions()
    }
  },
  computed: {
    ...mapState(useUserStore, ['isDemoOngoing']),
    ...mapState(useStationStore, ['stationDesignDummyObject', 'masterCycleFileUrl']),
    ...mapState(useSegmentationStore, ['masterCycleSegments']),
    seriesData() {
      if (!this.stepTimesData.length) return []
      return this.stepTimesData.filter((s) => s.data[0] !== 0)
    }
  },
  methods: {
    updatePlaybackRate(value) {
      this.playbackRate = value
    },

    setChartOptions() {
      const self = this
      this.chartOptions = {
        chart: {
          type: 'column'
        },

        credits: {
          enabled: false
        },

        title: {
          text: ''
        },

        xAxis: {
          type: 'category',
          title: {
            text: 'Station'
          },
          labels: {
            style: {
              display: 'none'
            }
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Time in seconds'
          }
        },
        legend: {
          enabled: false
        },
        tooltip: {
          formatter: function () {
            return `<span>${this.series.name}&nbsp;: <b>${this.point.y}s</b> </span><br/>`
          }
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: false
            },
            events: {
              click: function () {
                const stepIndex = this.userOptions.step_index
                const sorted = self.masterCycleSegments.sort((a, b) => a.step_index - b.step_index)
                self.currentStepObj = sorted[stepIndex]
                const { segment_start, segment_end, videoFPS } = self.masterCycleSegments[stepIndex]
                self.segmentPlay = {
                  start: segment_start / videoFPS,
                  end: segment_end / videoFPS,
                  duration: Number(((segment_end - segment_start) / videoFPS).toFixed(2))
                }
                self.showVideo = true
              }
            }
          }
        },
        series: self.seriesData,
        exporting: {
          enabled: false
        }
      }
    }
  }
}
</script>
<style></style>
