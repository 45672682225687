import { defineStore } from 'pinia';
import { useStationStore } from './station'
import OperatorTrackingService from 'src/services/operatorTracking';

export const useOperatorTrackingStore = defineStore('operatorTracking', {
    state: () => ({
        operatorIds: [],
        timeValues: {},
        operatorMoves: null,
        regionDistances: null,
        operatorTrackingData: {}
    }),

    getters: {
        operatorIdsOptions() {
            const operators = [{ label: 'All Operators', value: 'all' }]
            const newValues = !this.operatorIds.length ? [] : this.operatorIds.map((id, index) => ({ label: `Operator ${index + 1}`, value: id }))
            return operators.concat(newValues)
        },
    },

    actions: {
        async startOperatorTracking() {
            console.log('started operator tracking!')
            const { newStudyId: studyId } = useStationStore()
            return await OperatorTrackingService.startOperatorTracking(studyId)
        },

        async getOperatorTrackingResults() {
            const { newStudyId: studyId } = useStationStore()
            const [error, data] = await OperatorTrackingService.getOperatorTrackingData(studyId)
            if (error) {
                console.log('error in getting operator tracking data')
                return
            }
            this.operatorTrackingData = data
            console.log('op results:', this.operatorTrackingData)
            this.getTimeValues()
            this.getMovementCountValues()
            this.getRegionDistancesValues()
            const { movement_count, operator_distances } = data
            this.operatorIds = movement_count && Object.keys(movement_count).length ? Object.keys(movement_count) : operator_distances ? Object.keys(operator_distances) : []
        },

        getTimeValues(selectedOperator = 'all') {
            const { regionsIdsToIndexMap } = useStationStore()
            const { combined_time_per_worker, time_per_worker } = this.operatorTrackingData
            if (!combined_time_per_worker || !time_per_worker) return
            const operatorTimeValues = selectedOperator === 'all' ? combined_time_per_worker : time_per_worker[selectedOperator]
            this.timeValues = Object.keys(regionsIdsToIndexMap).reduce((res, regionId) => {
                const index = regionsIdsToIndexMap[regionId]
                res[index] = operatorTimeValues[regionId] ? operatorTimeValues[regionId].toFixed(2) : 0
                return res
            }, {})
        },

        getMovementCountValues(selectedOperator = 'all') {
            const { combined_movement_count, movement_count } = this.operatorTrackingData
            const operatorMovements = selectedOperator === 'all' ? combined_movement_count : movement_count[selectedOperator]
            this.operatorMoves = operatorMovements && operatorMovements
        },

        getRegionDistancesValues(selectedOperator = 'all') {
            const { combined_movement_distances, movement_distances } = this.operatorTrackingData
            const regionDistances = selectedOperator === 'all' ? combined_movement_distances : movement_distances[selectedOperator]
            this.regionDistances = regionDistances && regionDistances
        }
    }
})
