<template>
  <div>
    <a-row :gutter="[24, 0]" class="mb-4">
      <a-col span="12">
        <a-typography-title :level="4">Spaghetti Diagram</a-typography-title>
        <a-space class="my-3" :size="15">
          <span class="d-flex align-items-center" v-for="stat in statisticData" :key="stat.title">
            <a-statistic
              :value="stat.value"
              :style="{ fontWeight: 500 }"
              :valueStyle="{ fontSize: '28px', textAlign: 'center' }"
            >
              <template #title>
                <a-space class="d-flex align-items-center" v-if="stat.index === 0">
                  <span>{{ stat.title }}</span>
                  <a-popover placement="bottom">
                    <template #content>
                      <p style="max-width: 400px">
                        Please note that the distances provided are rough estimates. Their accuracy
                        depends on:
                      </p>
                      <ol
                        style="width: 400px; padding-left: 14px"
                        class="d-flex flex-column justify-content-start"
                      >
                        <li
                          v-for="(text, ix) in distanceHelperText"
                          :class="{ 'mb-2': ix < distanceHelperText.length - 1 }"
                          :key="text"
                        >
                          {{ text }}
                        </li>
                      </ol>
                    </template>
                    <InfoCircleOutlined class="helper-info-icon" />
                  </a-popover>
                </a-space>
                <span v-else>{{ stat.title }}</span>
              </template>
            </a-statistic>
            <a-divider
              v-if="stat.index < statisticData?.length - 1"
              type="vertical"
              :style="{ height: '50px' }"
            />
          </span>
        </a-space>
        <div id="operatorSelect">
          <a-select
            :disabled="isDemoOngoing"
            placeholder="Select Operator"
            v-model:value="selectedOperator"
            :options="operatorIdsOptions"
            :getPopupContainer="getPopupContainer"
            style="width: 300px"
          />
        </div>
      </a-col>
      <a-col span="12" class="d-flex flex-column" style="gap: 40px">
        <div v-if="timeValues && Object.keys(timeValues)?.length">
          <a-typography-title :level="4" class="mb-2 spaghetti-header"
            >Time Expenditure <small>(seconds)</small></a-typography-title
          >
          <div class="time-grid" :style="getGridColumStyle()">
            <span class="grid-item" v-for="(timeValue, key, index) in timeValues" :key="key">
              {{ timeValue || '-' }}
              <label> {{ index + 1 }}</label>
            </span>
          </div>
        </div>

        <div>
          <a-typography-title :level="4" class="mb-2 spaghetti-header"
            >From - To Chart</a-typography-title
          >
          <a-switch
            class="d-block ms-auto"
            :disabled="isMovesDataNotAvailable"
            v-model:checked="movesDistanceSwitch"
            checked-children="Moves"
            un-checked-children="Distance (ft)"
          />
        </div>
      </a-col>
    </a-row>

    <a-row :gutter="[24, 24]">
      <a-col span="12">
        <a-card class="h-100 p-0" :bodyStyle="{ padding: 0 }">
          <a-image
            :preview="{
              onVisibleChange: (visible, prevVisible) => handleSpaghettiImage(visible, prevVisible)
            }"
            crossorigin="anonymous"
            :src="spaghettiImageUrl"
            class="image"
          />
        </a-card>
      </a-col>
      <a-col span="12">
        <a-card class="h-100" :bodyStyle="{ padding: 0 }">
          <a-empty v-if="isMovesDataNotAvailable" :image="Empty.PRESENTED_IMAGE_SIMPLE">
            <template #description>
              <span> Movement Data Not Available </span>
            </template>
          </a-empty>
          <HeatmapChart
            v-else
            :heatmapData="currentHeatmapData"
            :checked="movesDistanceSwitch"
            :max="noOfWorkRegions"
          />
        </a-card>
      </a-col>
      <a-col span="24">
        <a-descriptions
          v-if="operatorSequence && Object.keys(operatorSequence)?.length"
          bordered
          size="small"
          :column="1"
        >
          <template #title>
            <a-typography-title :level="4" class="mb-0">Sequence:</a-typography-title>
          </template>
          <a-descriptions-item
            v-for="(sequence, key) in operatorSequence"
            :key="key"
            :label="key"
            :labelStyle="{ width: '200px' }"
            :contentStyle="{ fontSize: '16px' }"
            >{{ sequence }}</a-descriptions-item
          >
        </a-descriptions>
      </a-col>
      <a-col span="24" v-if="!isMovesDataNotAvailable">
        <a-typography-title :level="4" class="mb-2">Findings:</a-typography-title>
        <ul class="w-100">
          <li>
            The longest route is from Zone {{ findings[0]?.from }} to Zone {{ findings[0]?.to }}
            <b>({{ findings[0]?.value }} feet/move)</b>
          </li>
          <li>
            The most traveled routes are between Zone {{ findings[1]?.from }} and Zone
            {{ findings[1]?.to }}
            <b>({{ findings[1]?.value }} times/cycle)</b>
          </li>
        </ul>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import { useUserStore } from 'src/stores/user'
import { InfoCircleOutlined } from '@ant-design/icons-vue'
import { mapActions, mapState } from 'pinia'
import { useOperatorTrackingStore } from 'src/stores/operatorTracking'
import { useStationStore } from 'src/stores/station'
import HeatmapChart from './HeatMap.vue'

export default {
  components: {
    InfoCircleOutlined,
    HeatmapChart
  },
  setup: () => ({
    Empty,
    distanceHelperText: [
      'Dimensions of the benchtop you provided.',
      'Whether or not the camera imaging plane is perfectly parallel to the ground.',
      'Lighting, so we can accurately determine operator position.',
      'Radial distortion of the camera lens.'
    ]
  }),
  data() {
    return {
      selectedOperator: 1,
      movesDistanceSwitch: true,
      spaghettiImageUrl: '',
      operatorSequence: {},
      findings: []
    }
  },

  computed: {
    ...mapState(useUserStore, ['isDemoOngoing']),
    ...mapState(useStationStore, ['newStudy', 'noOfWorkRegions', 'regionsIdsToIndexMap']),
    ...mapState(useOperatorTrackingStore, [
      'operatorTrackingData',
      'operatorIdsOptions',
      'operatorMoves',
      'regionDistances',
      'timeValues'
    ]),

    statisticData() {
      const stats = [{ title: 'Total Distance', value: this.totalDistance, index: 0 }]
      if (!this.isMovesDataNotAvailable)
        stats.push({ title: 'Total Moves', value: this.totalMoves, index: 1 })
      return stats
      // { title: 'Walk Time', value: 0, index: 2 }
    },

    totalDistance() {
      if (!this.operatorTrackingData) return 0
      let unit = ' ft'
      const { operator_distances } = this.operatorTrackingData
      if (!operator_distances || !Object.keys(operator_distances)?.length) return 0
      let distance = 0

      if (this.selectedOperator === 'all') {
        distance = Object.values(operator_distances)?.reduce((sum, el) => {
          sum += Math.round(el / 12)
          return sum
        }, 0)
        distance = Math.ceil(distance)
      } else if (operator_distances[this.selectedOperator]) {
        distance = operator_distances[this.selectedOperator]
        distance = Math.round(distance / 12)
      }
      return distance ? distance + unit : 0
    },

    totalMoves() {
      if (!this.operatorTrackingData || this.isMovesDataNotAvailable) return 0
      const movesArray = Object.values(this.operatorMoves)
      return movesArray?.reduce((sum, el) => (sum += el), 0)
    },

    movesHeatmapData() {
      if (this.isMovesDataNotAvailable) return []
      const movesJson = this.getIndexedJson(this.operatorMoves)
      return this.getMovesSeriesData(movesJson)
    },

    distanceHeatmapData() {
      const { regionDistances } = this
      if (this.isMovesDataNotAvailable || !regionDistances || !Object.keys(regionDistances)?.length)
        return []
      const distanceJson = this.getIndexedJson(regionDistances)
      return this.getDistanceSeriesData(distanceJson)
    },

    currentHeatmapData() {
      return this.movesDistanceSwitch ? this.movesHeatmapData : this.distanceHeatmapData
    },

    isMovesDataNotAvailable() {
      if (this.operatorTrackingData)
        return !this.operatorMoves || Object.keys(this.operatorMoves)?.length === 0
      else return true
    }
  },

  watch: {
    selectedOperator(operatorId) {
      this.getSelectedOperatorData(operatorId)
    }
  },

  methods: {
    ...mapActions(useOperatorTrackingStore, [
      'getTimeValues',
      'getMovementCountValues',
      'getRegionDistancesValues'
    ]),

    getPopupContainer() {
      return document.getElementById('operatorSelect')
    },

    getSelectedOperatorData(operatorId) {
      if (!this.operatorTrackingData) return
      this.spaghettiImageUrl = ''
      const imageUrls = this.operatorTrackingData.urls
      this.spaghettiImageUrl =
        imageUrls && imageUrls[operatorId] ? imageUrls[operatorId] : imageUrls?.['combined'] || ''
      this.getTimeValues(operatorId)
      this.getMovementCountValues(operatorId)
      this.getRegionDistancesValues(operatorId)
      this.getOperatorSequence(operatorId)
      this.getTrackingFinding()
    },

    getTrackingFinding() {
      let findings = []
      const { regionDistances, operatorMoves } = this
      findings[0] = this.getRegionsFindings(regionDistances, operatorMoves, false)
      findings[1] = this.getRegionsFindings(regionDistances, operatorMoves)
      this.findings = findings
    },

    getIndexedJson(json) {
      let indexed = {}
      for (let k in json) {
        let key = JSON.parse(k)
        const x = this.regionsIdsToIndexMap[key[0]]
        const y = this.regionsIdsToIndexMap[key[1]]
        indexed[[x, y]] = json[k]
      }
      return indexed
    },

    getMovesSeriesData(json) {
      let res = []
      // y => from
      for (let y = 0; y < this.noOfWorkRegions; y++) {
        // x =>  to
        for (let x = 0; x < this.noOfWorkRegions; x++) {
          const key = [y + 1, x + 1].toString()
          if (json[key]) res.push({ x, y, value: json[key] })
          else res.push({ x, y, value: null })
        }
      }
      return res
    },

    getDistanceSeriesData(distanceJson) {
      let res = []
      // y => from
      for (let y = 0; y < this.noOfWorkRegions; y++) {
        // x =>  to
        for (let x = 0; x < this.noOfWorkRegions; x++) {
          const key = [y + 1, x + 1].toString()
          const distance = distanceJson[key] ? distanceJson[key] : 0
          if (distanceJson[key]) res.push({ x, y, value: Math.ceil(distance / 12) })
          else res.push({ x, y, value: null })
        }
      }
      return res
    },

    getRegionsFindings(distanceJson, movesJson, forMoves = true) {
      let obj = { from: 0, to: 0, value: 0 }
      if (
        !distanceJson ||
        !Object.keys(distanceJson)?.length ||
        !movesJson ||
        !Object.keys(movesJson)?.length
      )
        return obj

      let max = 0
      let dict = this.regionsIdsToIndexMap
      const distanceKeys = Object.keys(distanceJson)
      const moveKeys = Object.keys(movesJson)
      const coveredRegions = distanceKeys.filter((key) =>
        moveKeys.some((m_key) => {
          const [a, b] = JSON.parse(key)
          const [m, k] = JSON.parse(m_key)
          return `[${m},${k}]` == `[${a},${b}]` || `[${m},${k}]` == `[${b},${a}]`
        })
      )
      const json = forMoves ? movesJson : distanceJson
      const regionList = forMoves ? moveKeys : coveredRegions

      // Get max value
      regionList.forEach((region) => {
        const value = json[region]
        if (value > max) {
          max = value
          const [a, b] = JSON.parse(region)
          obj['from'] = dict[a]
          obj['to'] = dict[b]
          obj['value'] = value
          obj['key'] = region
        }
      })

      // get per move distance
      if (!forMoves) {
        const longestRouteKey = obj.key
        const longestRouteMoves = movesJson[longestRouteKey] ?? movesJson[longestRouteKey.reverse()]
        const distance = Number(obj['value']) / 12
        console.log('longest:', longestRouteKey, longestRouteMoves, distance)
        obj['value'] = longestRouteMoves ? Math.ceil(distance / longestRouteMoves) : distance
      }
      return obj
    },

    getOperatorSequence(selectedOperator = 'all') {
      if (!this.operatorIdsOptions.length) return
      const sequenceObject = this.operatorTrackingData.sequence
      if (!sequenceObject) return
      const allOperatorIds = [...this.operatorIdsOptions]
      allOperatorIds.shift()
      let sequence = {}
      if (selectedOperator === 'all') {
        allOperatorIds.forEach(({ label, value: operatorId }) => {
          sequence[label] = sequenceObject[operatorId]
        })
      } else {
        const index = this.operatorIdsOptions.findIndex((op) => op.value === selectedOperator)
        sequence[this.operatorIdsOptions[index].label] = sequenceObject[selectedOperator]
      }
      this.operatorSequence = sequence
    },

    handleSpaghettiImage() {
      setTimeout(() => {
        const els = document.getElementsByClassName('ant-image-preview-img')
        for (let i = 0; i < els.length; i++) {
          const el = els[i]
          el.setAttribute('crossorigin', 'anonymous')
        }
      })
    },

    getGridColumStyle() {
      if (this.noOfWorkRegions) {
        return {
          gridTemplateColumns: `repeat(${this.noOfWorkRegions}, 1fr) !important`
        }
      } else return {}
    }
  },

  mounted() {
    if (this.operatorIdsOptions.length) {
      this.selectedOperator = this.operatorIdsOptions[0]?.value
      this.getSelectedOperatorData(this.selectedOperator)
      this.getOperatorSequence(this.selectedOperator)
    } else this.selectedOperator = null
  }
}
</script>

<style>
.image {
  border-radius: 5px;
  border: 1px solid lightgray;
  width: 100%;
  height: auto;
}

.helper-info-icon {
  color: red;
  vertical-align: middle;
  font-size: 14.6px;
}

.spaghetti-header {
  display: block;
  height: auto;
  background-color: lightgrey;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.time-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  position: relative;
  border: 1px solid #ccc;
}

.grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #f9f9f9;
  position: relative;
}

.grid-item label {
  position: absolute;
  bottom: -50%;
  font-size: 11.4px;
}

.grid-item:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 1px;
  background-color: #ccc;
}
</style>
