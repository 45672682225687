export const ergonomicsDummyData = {
  dummyData: {
    id: 0,
    is_deleted: false,
    deleted_at: null,
    project_name: 'Trunk Reba TRest',
    assessment_type: 'REBA',
    line: null,
    station: null,
    time_created: '03/19/2024',
    is_processed: false,
    created_by: 'John Doe',
    deleted_by: null,
    organization: 'retrocausal',
    process: {
      id: 2457,
      progress: 50,
      description: '',
      process: 4,
      status: 1,
      completed_status: 2
    },
    file: {
      id: 684,
      is_deleted: false,
      deleted_at: null,
      name: 'Trunk(1).mp4',
      description: null,
      created: '2024-03-11T20:03:50.894450Z',
      location: 'file_manager/Trunk(1).mp4',
      meta: {
        fps: '25.00',
        size: '7082745',
        type: 'video',
        codec: 'h264',
        width: 480,
        height: 864,
        duration: '27.880000'
      },
      thumbnail: 'https://kaizen-videos-thumbnails.s3.us-east-2.amazonaws.com/Trunk(1).jpg',
      created_by: 150,
      deleted_by: null,
      organization: 'retrocausal',
      folder: null,
      process: 2364,
      tag: []
    },
    key: 1048
  },
  snook: {
    outputVideo: 'https://kaizen-doc-videos.s3.us-east-2.amazonaws.com/Pulling.mp4',
    dummyData: {
      id: 0,
      is_deleted: false,
      deleted_at: null,
      project_name: 'Bin transfer using trolley 2',
      assessment_type: 'SNOOK TABLE',
      line: null,
      station: null,
      time_created: '03/21/2024',
      is_processed: false,
      created_by: 'hassan ahmed',
      deleted_by: null,
      organization: 'hassan-exp-org',
      process: {
        id: 2577,
        progress: 100,
        description: '',
        process: 4,
        status: 2,
        completed_status: 2
      },
      file: {
        id: 754,
        is_deleted: false,
        deleted_at: null,
        name: 'Pulling (1).mp4',
        description: null,
        created: '2024-03-21T08:26:28.345837Z',
        location: 'file_manager/Pulling (1).mp4',
        meta: {
          fps: '29.97',
          size: 19265941,
          type: 'video/mp4',
          codec: 'h264',
          duration: 9.22
        },
        thumbnail: 'https://kaizen-videos-thumbnails.s3.us-east-2.amazonaws.com/Pulling (1).jpg',
        created_by: 430,
        deleted_by: null,
        organization: 'hassan-exp-org',
        folder: null,
        process: 2573,
        tag: []
      },
      snook_table_type: 'pulling',
      gender: 'male',
      key: 1096,
      in_progress: false
    },
    snookInputs: [
      {
        label: 'Vertical Location',
        key: 'vertical_location',
        payload_key: 'vertical_location',
        options: [
          {
            label: 'Low',
            value: 25
          },
          {
            label: 'Middle',
            value: 37
          },
          {
            label: 'High',
            value: 57
          }
        ],
        value: 25,
        auto_generated: true
      },
      {
        label: 'Pull Distance',
        key: 'push_pull_distance',
        payload_key: 'push_pull_distance',
        options: [
          {
            label: '7"',
            value: 7
          },
          {
            label: '25"',
            value: 25
          },
          {
            label: '50"',
            value: 50
          }
        ],
        value: 25,
        auto_generated: true
      },
      {
        label: 'Frequency',
        key: 'frequency',
        payload_key: 'frequency',
        options: [
          {
            label: '1 action / 8 hours',
            value: 28800
          },
          {
            label: '1 action / 30 minutes',
            value: 1800
          },
          {
            label: '1 action / 5 minutes',
            value: 300
          },
          {
            label: '1 action / 2 minutes',
            value: 120
          },
          {
            label: '1 action / 1 minute',
            value: 60
          },
          {
            label: '1 action / 30 seconds',
            value: 30
          },
          {
            label: '1 action / 15 seconds',
            value: 15
          },
          {
            label: '1 action / 12 seconds',
            value: 12
          },
          {
            label: '1 action / 6 seconds',
            value: 6
          }
        ],
        value: 60,
        auto_generated: false
      },
      {
        label: 'Initial Force',
        key: 'initial_force',
        payload_key: 'initial_force',
        options: null,
        max: 120,
        value: 54,
        auto_generated: false
      },
      {
        label: 'Sustained Force',
        key: 'sustained_force',
        payload_key: 'sustained_force',
        options: null,
        max: 120,
        value: 78,
        auto_generated: false
      }
    ],
    recommResultData: [
      {
        title: 'Results',
        children: [
          {
            label: 'Risk Index',
            key: 'risk_index',
            first_value_key: 'initial_risk_index',
            second_value_key: 'sustained_risk_index',
            firstValue: '0.74',
            secondValue: '1.95'
          },
          {
            label: 'Design Goal',
            key: 'design_goal',
            first_value_key: 'initial_design_goal',
            second_value_key: 'sustained_design_goal',
            firstValue: 73,
            secondValue: 40
          }
        ]
      }
    ],
    data: [
      {
        label: 'Vertical Location',
        key: 'vertical_location',
        payload_key: 'vertical_location',
        options: [
          {
            label: 'Low',
            value: 25
          },
          {
            label: 'Middle',
            value: 37
          },
          {
            label: 'High',
            value: 57
          }
        ],
        value: 'Low',
        auto_generated: true
      },
      {
        label: 'Pull Distance',
        key: 'push_pull_distance',
        payload_key: 'push_pull_distance',
        options: [
          {
            label: '7"',
            value: 7
          },
          {
            label: '25"',
            value: 25
          },
          {
            label: '50"',
            value: 50
          }
        ],
        value: 25,
        auto_generated: true
      },
      {
        label: 'Frequency',
        key: 'frequency',
        payload_key: 'frequency',
        options: [
          {
            label: '1 action / 8 hours',
            value: 28800
          },
          {
            label: '1 action / 30 minutes',
            value: 1800
          },
          {
            label: '1 action / 5 minutes',
            value: 300
          },
          {
            label: '1 action / 2 minutes',
            value: 120
          },
          {
            label: '1 action / 1 minute',
            value: 60
          },
          {
            label: '1 action / 30 seconds',
            value: 30
          },
          {
            label: '1 action / 15 seconds',
            value: 15
          },
          {
            label: '1 action / 12 seconds',
            value: 12
          },
          {
            label: '1 action / 6 seconds',
            value: 6
          }
        ],
        value: '1 action / 1 minute',
        auto_generated: false
      },
      {
        label: 'Initial Force',
        key: 'initial_force',
        payload_key: 'initial_force',
        options: null,
        max: 120,
        value: 54,
        auto_generated: false
      },
      {
        label: 'Sustained Force',
        key: 'sustained_force',
        payload_key: 'sustained_force',
        options: null,
        max: 120,
        value: 78,
        auto_generated: false
      }
    ],
    videoUrl:
      'https://retrocausal-kaizen.s3.amazonaws.com/ergonomics/processed/Pulling_880/Pulling_880_tracking.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYWFGI2RLB7ZLUFG6%2F20240318%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20240318T072208Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=6d7cdfcb1f1c2b658f1753ee9cf2594b60c43bd90860086c401f17eee2ce495b'
  },
  niosh: {
    outputVideo: 'https://kaizen-doc-videos.s3.us-east-2.amazonaws.com/WH+Lifting+%26+Lowering.mp4',
    loadAnalysisChartData: {
      title: 'Load Analysis Chart',
      data: [
        {
          y: 2,
          color: '#d88d2aA1'
        },
        {
          y: 2,
          color: '#d88d2aA1'
        },
        {
          y: 2,
          color: '#d88d2aA1'
        },
        {
          y: 2,
          color: '#d88d2aA1'
        },
        {
          y: 2,
          color: '#d88d2aA1'
        },
        {
          y: 2,
          color: '#d88d2aA1'
        },
        {
          y: 2,
          color: '#d88d2aA1'
        },
        {
          y: 2,
          color: '#d88d2aA1'
        },
        {
          y: 2,
          color: '#d88d2aA1'
        },
        {
          y: 2,
          color: '#d88d2aA1'
        },
        {
          y: 2,
          color: '#d88d2aA1'
        }
      ],
      labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
      xlabelString: 'Timestamp in seconds',
      ylabelString: 'Activity',
      yLabels: [0, 1, 2, 3, 4, 5, 6],
      classesScore: {
        1: 'High Lift ',
        2: 'Power Zone ',
        3: 'Stooper Lift ',
        4: 'Free Motion ',
        5: 'Standing Static Stance ',
        6: 'Overhead Reach ',
        7: 'Sitting Static Stance '
      }
    },
    dummyData: {
      id: 0,
      is_deleted: false,
      deleted_at: null,
      project_name: 'Warehouse lifting',
      assessment_type: 'NIOSH',
      line: null,
      station: null,
      time_created: '03/21/2024',
      is_processed: true,
      created_by: 'hassan ahmed',
      deleted_by: null,
      organization: 'hassan-exp-org',
      process: {
        id: 2576,
        progress: 100,
        description: '',
        process: 4,
        status: 2,
        completed_status: 2
      },
      file: {
        id: 751,
        is_deleted: false,
        deleted_at: null,
        name: 'Warehouse Lifting operation.mp4',
        description: null,
        created: '2024-03-21T08:26:24.221203Z',
        location: 'file_manager/Warehouse Lifting operation.mp4',
        meta: {
          fps: '30',
          size: 6183538,
          type: 'video/mp4',
          codec: 'h264',
          duration: 11.8
        },
        thumbnail:
          'https://kaizen-videos-thumbnails.s3.us-east-2.amazonaws.com/Warehouse Lifting operation.jpg',
        created_by: 430,
        deleted_by: null,
        organization: 'hassan-exp-org',
        folder: null,
        process: 2570,
        tag: []
      },
      key: 1095,
      in_progress: false
    },
    computedVariables: {
      horizontalLocation: {
        key: 'H_',
        label: 'Horizontal Location (10 - 25 inches)',
        origin: 10,
        dest: 10
      },
      verticalLocation: {
        key: 'V_',
        label: 'Vertical Location (0 - 70 inches)',
        origin: 21.94,
        dest: 3.23
      },
      angleOfAsymmetry: {
        key: 'A_',
        label: 'Angle Of Asymmetry (0 - 135)',
        origin: 0,
        dest: 32.13
      }
    },
    weightLimitData: [
      {
        title: 'Recommended Weight Limit (RWL)',
        children: [
          {
            key: 'RWL',
            text: 'RWL',
            origin: '40.07',
            dest: '30.69'
          },
          {
            key: 'FIRWL',
            text: 'FIRWL',
            origin: '45.54',
            dest: '34.88'
          }
        ]
      },
      {
        title: 'Lifting Index (LI)',
        children: [
          {
            key: 'LI',
            text: 'LI',
            origin: '1.04',
            dest: '1.36'
          },
          {
            key: 'FILI',
            text: 'FILI',
            origin: '0.92',
            dest: '1.20'
          }
        ]
      }
    ],
    data: [
      {
        key: 'load',
        text: 'Load Weight',
        colorsRange: null,
        origin: 42,
        dest: 42
      },
      {
        key: 'HM',
        text: 'Horizontal Location (HM)',
        colorsRange: [0.4, 0.91],
        origin: 1,
        dest: 1
      },
      {
        key: 'VM',
        text: 'Vertical Location (VM)',
        colorsRange: [0.7, 0.96],
        origin: 0.94,
        dest: 0.8
      },
      {
        key: 'DM',
        text: 'Travel Distance (DM)',
        colorsRange: [0.85, 0.94],
        origin: 1,
        dest: 1
      },
      {
        key: 'AM',
        text: 'Asymmetry Angle (AM)',
        colorsRange: [0.57, 0.95],
        origin: 1,
        dest: 0.9
      },
      {
        key: 'FM',
        text: 'Frequency (FM)',
        colorsRange: null,
        origin: 0.88,
        dest: 0.88
      },
      {
        key: 'CM',
        text: 'Coupling (CM)',
        colorsRange: [0.9, 0.95],
        origin: 0.95,
        dest: 0.95
      }
    ],
    loadAnalysisTableData: [
      {
          "weight_class": "High Lift (Lifting load above torso)",
          "original_class": "weight_top",
          "job_percent": 0,
          "frequency": "Never"
      },
      {
          "weight_class": "Power Zone (Lifting load at torso level)",
          "original_class": "weight_mid",
          "job_percent": 100,
          "frequency": "Constantly"
      },
      {
          "weight_class": "Free Motion (Bending without load)",
          "original_class": "bend_without_weight",
          "job_percent": 0,
          "frequency": "Never"
      },
      {
          "weight_class": "Stooper Lift (Bending while holding load)",
          "original_class": "bend_with_weight",
          "job_percent": 0,
          "frequency": "Never"
      },
      {
          "weight_class": "Standing Static Stance (Standing / Walking without load)",
          "original_class": "stand_without_weight",
          "job_percent": 0,
          "frequency": "Never"
      },
      {
          "weight_class": "Overhead Reach (Reach above torso without load)",
          "original_class": "reach_top_without_weight",
          "job_percent": 0,
          "frequency": "Never"
      },
      {
          "weight_class": "Sitting Static Stance (Sitting without load)",
          "original_class": "sitting",
          "job_percent": 0,
          "frequency": "Never"
      }
  ]
  },
  handErgonomics: {
    outputVideo:
      'https://kaizen-doc-videos.s3.us-east-2.amazonaws.com/Filter+assembly+slower+-+Trim.mp4',
    dummyData: {
      id: 0,
      is_deleted: false,
      deleted_at: null,
      project_name: 'Filter assembly Hand ergo',
      assessment_type: 'Hand Grip & Insertion Force',
      line: null,
      station: null,
      time_created: '02/20/2024',
      is_processed: true,
      created_by: 'hassan ahmed',
      deleted_by: null,
      organization: 'hassan-exp-org',
      process: {
        id: 1891,
        progress: 100,
        description: '',
        process: 4,
        status: 2,
        completed_status: 2
      },
      file: {
        id: 597,
        is_deleted: false,
        deleted_at: null,
        name: 'Filter assembly slower - Trim.mp4',
        description: null,
        created: '2024-02-19T12:17:53.051069Z',
        location: 'file_manager/Filter assembly slower - Trim.mp4',
        meta: {
          fps: '29.83',
          size: '494380680',
          type: 'video',
          codec: 'h264',
          width: 1920,
          height: 1080,
          duration: '217.458328'
        },
        thumbnail:
          'https://kaizen-videos-thumbnails.s3.us-east-2.amazonaws.com/Filter assembly slower - Trim.jpg',
        created_by: 430,
        deleted_by: null,
        organization: 'hassan-exp-org',
        folder: null,
        process: 1882,
        tag: []
      },
      key: 940,
      in_progress: false
    },
    mapHandErgonomicsData: [
      {
        key: 0,
        id: 5329,
        segment_start: 343,
        name: '(00:11 - 00:17)',
        thumbnail: '',
        arm: 'left',
        frequency: 'occasional',
        actual_load: 5,
        grip: 'power_grip',
        wrist: 'extension',
        load: 0,
        thumbnail: 'https://kaizen-doc-videos.s3.us-east-2.amazonaws.com/343.jpg'
      },
      {
        key: 1,
        id: 5330,
        segment_start: 3018,
        name: '(01:41 - 01:51)',
        thumbnail: '',
        arm: 'right',
        frequency: 'occasional',
        actual_load: 2,
        grip: 'lateral_pinch',
        wrist: 'radial_deviation',
        load: 0,
        thumbnail: 'https://kaizen-doc-videos.s3.us-east-2.amazonaws.com/3018.jpg'
      }
    ],
    analyzedData: [
      {
        grip: 'Power Grip',
        wrist: 'Extension',
        handedness: 'Left',
        frequency: 'Static',
        actual_load: 5,
        recommended_load: 2.355,
        segment_start: 343,
        thumbnail: 'https://kaizen-doc-videos.s3.us-east-2.amazonaws.com/343.jpg'
      },
      {
        grip: 'Lateral Pinch',
        wrist: 'Radial Deviation',
        handedness: 'Right',
        frequency: 'Static',
        actual_load: 2,
        recommended_load: 0.648,
        segment_start: 3018,
        thumbnail: 'https://kaizen-doc-videos.s3.us-east-2.amazonaws.com/3018.jpg'
      }
    ],
    originVideoUrl:
      'https://kaizen-doc-videos.s3.us-east-2.amazonaws.com/Filter+assembly+slower+-+Trim.mp4'
  },
  rula: {
    getFinalScore: 8,
    assessmentFrameScore: 9,
    outputVideo: 'https://kaizen-doc-videos.s3.us-east-2.amazonaws.com/IMG_8133+(1).mov',
    intervalsList: [
      {
        coupling_score: 1,
        action_score: 0,
        load_score: 1,
        table_a_score: 6,
        table_b_score: 7,
        final_reba_score: 9,
        max_score_frame: 482,
        presigned_url:
          'https://kaizen-doc-videos.s3.us-east-2.amazonaws.com/frame_482.jpg',
        pose_at_risk: 'final_upper_arm_score',
        pose_risk_level: 'High',
        areas_at_risk: 5,
        frame_interval: {
          start: 390,
          end: 539
        },
        key: '0'
      },
      {
        coupling_score: 1,
        action_score: 0,
        load_score: 1,
        table_a_score: 4,
        table_b_score: 7,
        final_reba_score: 8,
        max_score_frame: 2169,
        presigned_url:
          'https://kaizen-doc-videos.s3.us-east-2.amazonaws.com/frame_2169.jpg',
        pose_at_risk: 'final_upper_arm_score',
        pose_risk_level: 'High',
        areas_at_risk: 5,
        frame_interval: {
          start: 2100,
          end: 2189
        },
        key: '1'
      }
    ],
    scoreInputsAssessmentTypeScoreStep: [
      {
        input: 'Force / Load',
        score: [
          {
            label: 'load < 11 lbs',
            value: 0
          },
          {
            label: 'load is between 11 to 22 lbs',
            value: 1
          },
          {
            label: 'load > 22 lbs',
            value: 2
          }
        ],
        key: 'load_score'
      },
      {
        input: 'Muscle use',
        score: [
          {
            label: 'Default - 0',
            value: 0
          },
          {
            label: 'static - 1',
            value: 1
          }
        ],
        key: 'muscle_use_score'
      }
    ],
    jointsSeverity: {
      final_neck_score: 'High',
      right_upper_arm_score: 'High',
      left_upper_arm_score: 'High',
      right_lower_arm_score: 'Medium',
      left_lower_arm_score: 'Medium',
      right_wrist_score: 'Low',
      left_wrist_score: 'Low',
      final_trunk_score: 'Medium',
      right_leg_score: 'High',
      left_leg_score: 'High',
      final_reba_score: 'High'
    },
    resultIntervalsList: [
      {
        coupling_score: 1,
        action_score: 0,
        load_score: 1,
        table_a_score: 6,
        table_b_score: 7,
        final_reba_score: 9,
        max_score_frame: 482,
        presigned_url:
          'https://kaizen-doc-videos.s3.us-east-2.amazonaws.com/frame_482.jpg',
        pose_at_risk: 'final_upper_arm_score',
        pose_risk_level: 'High',
        areas_at_risk: 5,
        frame_interval: {
          start: 390,
          end: 539
        },
        key: '0'
      },
      {
        coupling_score: 1,
        action_score: 0,
        load_score: 1,
        table_a_score: 4,
        table_b_score: 7,
        final_reba_score: 8,
        max_score_frame: 2169,
        presigned_url:
          'https://kaizen-doc-videos.s3.us-east-2.amazonaws.com/frame_2169.jpg',
        pose_at_risk: 'final_upper_arm_score',
        pose_risk_level: 'High',
        areas_at_risk: 5,
        frame_interval: {
          start: 2100,
          end: 2189
        },
        key: '1'
      }
    ],
    framePosturesData: {
      right_upper_arm_angle: 121.78258695220372,
      left_upper_arm_angle: 136.1578720237191,
      right_shoulder_raised: true,
      right_arm_abducted: true,
      right_arm_supported_leaning: true,
      right_upper_arm_score: 5,
      left_shoulder_raised: false,
      left_arm_abducted: true,
      left_arm_supported_leaning: true,
      left_upper_arm_score: 4,
      final_upper_arm_score: 5,
      left_lower_arm_angle: 131.10094657167477,
      left_lower_arm_score: 2,
      right_lower_arm_angle: 136.93169978232868,
      right_lower_arm_score: 2,
      final_lower_arm_score: 2,
      neck_angle: 21.297515160285116,
      neck_twist_angle: 21.16978489602052,
      neck_twisted: true,
      neck_side_bend_angle: 24.950609648793556,
      neck_side_bending: false,
      final_neck_score: 3,
      trunk_bending_angles: [6.228719869340528, 1.678850994511129],
      trunk_twisted: true,
      trunk_side_bending: false,
      final_trunk_score: 2,
      right_knee_angle: 65.60657004496716,
      left_knee_angle: 75.46330508483231,
      left_leg_score: 3,
      right_leg_score: 3,
      final_leg_score: 3,
      left_wrist_angle: 17.89683869946664,
      right_wrist_angle: 9.4209783843653,
      right_wrist_score: 1,
      left_wrist_score: 1,
      final_wrist_score: 1,
      table_a_score: 6,
      coupling_score: 1,
      table_b_score: 7,
      load_score: 1,
      activity_score: 0,
      final_reba_score: 9
    },
    polygonShapes: {
      final_neck_score: {
        points: [
          132, 73.80000305175781, 170.99999999999997, 74.80000305175781, 170.99999999999997,
          88.80000305175781, 170.99999999999997, 92.80000305175781, 211, 105.80000305175781, 211,
          160.8000030517578, 90, 161.8000030517578, 92, 105.80000305175781, 127, 93.80000305175781,
          132, 90.8000030517578, 132, 74.80000305175781, 132, 74.80000305175781
        ],
        id: '0',
        width: 0,
        height: 0,
        stroke: '#ff0000B3',
        fill: '#ff0000B3',
        name: 'neck',
        closed: true
      },
      left_upper_arm_score: {
        points: [
          49.83746337890625, 164.8000030517578, 82.83746337890625, 164.8000030517578,
          82.83746337890625, 159.8000030517578, 86.83746337890625, 158.8000030517578,
          87.83746337890625, 159.8000030517578, 88.83746337890625, 105.80000305175781,
          79.83746337890624, 108.8000030517578, 69.83746337890625, 113.80000305175783,
          61.83746337890625, 120.80000305175781, 55.83746337890624, 129.8000030517578,
          54.83746337890625, 137.8000030517578, 54.83746337890625, 143.8000030517578,
          53.83746337890625, 148.8000030517578, 51.83746337890626, 154.8000030517578,
          49.83746337890625, 162.8000030517578, 49.83746337890625, 162.8000030517578
        ],
        id: '6',
        width: 0,
        height: 0,
        stroke: '#ffa500B3',
        fill: '#ffa500B3',
        name: 'left_upper_arm',
        closed: true
      },
      right_upper_arm_score: {
        points: [
          251.83746337890622, 165.8000030517578, 218.83746337890625, 166.80000305175784,
          218.83746337890625, 159.8000030517578, 215.83746337890625, 158.8000030517578,
          213.83746337890625, 159.8000030517578, 213.83746337890625, 105.80000305175781,
          223.83746337890625, 108.8000030517578, 233.83746337890625, 113.80000305175783,
          240.83746337890625, 120.80000305175781, 244.83746337890625, 128.8000030517578,
          245.83746337890625, 134.8000030517578, 246.83746337890625, 141.8000030517578,
          248.83746337890628, 147.8000030517578, 251.83746337890622, 159.8000030517578,
          252.83746337890625, 163.8000030517578, 252.83746337890625, 163.8000030517578
        ],
        id: '7',
        width: 0,
        height: 0,
        stroke: '#ffa500B3',
        fill: '#ffa500B3',
        name: 'right_upper_arm',
        closed: true
      },
      left_wrist_score: {
        points: [
          54.83746337890625, 262, 35.83746337890625, 258, 35.83746337890625, 264, 34.83746337890625,
          270, 30.837463378906254, 275, 22.83746337890625, 279, 15.837463378906248, 287,
          11.83746337890625, 294, 9.83746337890625, 297, 9.83746337890625, 299, 10.83746337890625,
          299, 11.83746337890625, 298, 13.83746337890625, 297, 18.83746337890625, 290,
          22.83746337890625, 288, 23.83746337890625, 290, 23.83746337890625, 292,
          21.837463378906246, 298, 18.83746337890625, 306, 16.83746337890625, 315.99999999999994,
          16.83746337890625, 320, 16.83746337890625, 315.99999999999994, 18.83746337890625, 320,
          20.83746337890625, 321, 21.837463378906246, 322, 25.83746337890625, 320,
          28.83746337890625, 320, 29.83746337890625, 320, 32.83746337890625, 319, 34.83746337890625,
          315.99999999999994, 36.83746337890625, 315.99999999999994, 36.83746337890625,
          315.99999999999994, 42.83746337890624, 311, 47.83746337890625, 300, 50.83746337890625,
          290, 52.83746337890625, 280, 52.83746337890625, 272, 55.83746337890624, 263,
          55.83746337890624, 263
        ],
        id: '9',
        width: 0,
        height: 0,
        stroke: '#ffa500B3',
        fill: '#ffa500B3',
        name: 'left_wrist',
        closed: true
      },
      right_wrist_score: {
        points: [
          244.83746337890625, 259.3999938964844, 264.83746337890625, 256.3999938964844,
          265.83746337890625, 257.3999938964844, 266.83746337890625, 265.3999938964844,
          269.83746337890625, 272.3999938964844, 273.83746337890625, 276.3999938964844,
          281.83746337890625, 280.3999938964844, 285.83746337890625, 285.3999938964844,
          288.83746337890625, 290.3999938964844, 291.83746337890625, 295.3999938964844,
          293.83746337890625, 298.3999938964844, 293.83746337890625, 299.3999938964844,
          288.83746337890625, 297.3999938964844, 283.83746337890625, 291.3999938964844,
          279.83746337890625, 287.3999938964844, 277.83746337890625, 289.3999938964844,
          279.83746337890625, 296.3999938964844, 282.83746337890625, 303.3999938964844,
          283.83746337890625, 305.59999084472656, 284.83746337890625, 313.59999084472656,
          284.83746337890625, 318.59999084472656, 280.83746337890625, 321.59999084472656,
          277.83746337890625, 321.59999084472656, 273.83746337890625, 319.59999084472656,
          270.83746337890625, 319.59999084472656, 266.83746337890625, 316.59999084472656,
          262.83746337890625, 314.59999084472656, 259.83746337890625, 311.59999084472656,
          253.83746337890625, 297.59999084472656, 250.83746337890625, 287.59999084472656,
          249.83746337890625, 280.59999084472656, 249.83746337890625, 272.59999084472656,
          246.83746337890625, 262.59999084472656, 246.83746337890625, 262.59999084472656
        ],
        id: '8',
        width: 0,
        height: 0,
        stroke: '#ffa500B3',
        fill: '#ffa500B3',
        name: 'right_wrist',
        closed: true
      },
      right_leg_score: {
        points: [
          157.83746337890625, 319, 210.83746337890625, 318, 210.83746337890625, 337,
          208.83746337890625, 363, 202.83746337890622, 395, 199.83746337890628, 415,
          199.83746337890628, 425, 201.83746337890625, 439, 202.83746337890622, 445.59999847412104,
          203.83746337890625, 457.5999984741211, 203.83746337890625, 474.5999984741211,
          199.83746337890628, 487.5999984741211, 193.83746337890625, 516.5999984741211,
          189.83746337890622, 530.5999984741211, 189.83746337890622, 543.5999984741211,
          191.83746337890625, 555.5999984741211, 199.83746337890628, 571.5999984741211,
          202.83746337890622, 580.5999984741211, 202.83746337890622, 583.5999984741211,
          198.83746337890625, 586.5999984741211, 194.83746337890625, 587.5999984741211,
          191.83746337890625, 589.5999984741211, 187.83746337890625, 590.5999984741211,
          184.83746337890625, 591.5999984741211, 180.83746337890625, 591.5999984741211,
          178.83746337890625, 591.5999984741211, 178.83746337890625, 592.5999984741211,
          177.83746337890625, 594.5999984741211, 173.83746337890628, 593.5999984741211,
          172.83746337890625, 586.5999984741211, 170.83746337890625, 581.5999984741211,
          170.83746337890625, 567.5999984741211, 170.83746337890625, 558.5999984741211,
          168.83746337890625, 553.5999984741211, 168.83746337890625, 542.5999984741211,
          171.83746337890625, 529.5999984741211, 172.83746337890625, 511.5999984741211,
          170.83746337890625, 499.5999984741211, 168.83746337890625, 485.5999984741211,
          165.83746337890625, 467.5999984741211, 165.83746337890625, 454.5999984741211,
          166.83746337890625, 442.59999847412115, 167.83746337890625, 431, 167.83746337890625, 418,
          166.83746337890625, 400, 163.83746337890622, 384, 161.83746337890625, 364.99999999999994,
          159.83746337890625, 349, 158.83746337890625, 334, 158.83746337890625, 319,
          158.83746337890625, 319
        ],
        id: '8',
        width: 0,
        height: 0,
        stroke: '#ffa500B3',
        fill: '#ffa500B3',
        name: 'right_knee',
        closed: true
      },
      left_leg_score: {
        points: [
          144.83746337890625, 318.59999847412115, 89.83746337890625, 317.5999984741211,
          92.83746337890625, 349.5999984741211, 95.83746337890625, 377.5999984741211,
          102.83746337890626, 407.5999984741211, 102.83746337890626, 418.5999984741211,
          100.83746337890625, 439.59999847412115, 97.83746337890624, 457.5999984741211,
          98.83746337890625, 472.5999984741211, 103.83746337890625, 494.59999847412104,
          107.83746337890625, 511.5999984741211, 113.83746337890625, 533.5999984741211,
          113.83746337890625, 542.5999984741211, 108.83746337890625, 559.5999984741211,
          103.83746337890625, 571.5999984741211, 99.83746337890625, 578.5999984741211,
          99.83746337890625, 584.5999984741211, 102.83746337890626, 585.5999984741211,
          103.83746337890625, 587.5999984741211, 106.83746337890625, 587.5999984741211,
          108.83746337890625, 588.5999984741211, 109.83746337890625, 589.5999984741211,
          112.83746337890625, 589.5999984741211, 115.83746337890626, 590.5999984741211,
          117.83746337890625, 591.5999984741211, 120.83746337890625, 590.5999984741211,
          122.83746337890625, 590.5999984741211, 124.83746337890625, 592.5999984741211,
          126.83746337890625, 594.5999984741211, 129.83746337890625, 590.5999984741211,
          130.83746337890625, 584.5999984741211, 131.83746337890625, 580.5999984741211,
          131.83746337890625, 571.5999984741211, 131.83746337890625, 563.5999984741211,
          134.83746337890625, 552.5999984741211, 134.83746337890625, 543.5999984741211,
          130.83746337890625, 531.5999984741211, 130.83746337890625, 520.5999984741211,
          130.83746337890625, 506.5999984741211, 132.83746337890625, 489.5999984741211,
          135.83746337890625, 474.5999984741211, 137.83746337890625, 454, 136.83746337890625, 439,
          135.83746337890625, 433, 134.83746337890625, 419, 135.83746337890625, 407,
          139.83746337890625, 374, 141.83746337890625, 358, 143.83746337890625, 329,
          144.83746337890625, 319, 144.83746337890625, 319
        ],
        id: '9',
        width: 0,
        height: 0,
        stroke: '#ff0000B3',
        fill: '#ff0000B3',
        name: 'left_knee',
        closed: true
      },
      right_lower_arm_score: {
        points: [
          220.83746337890625, 171.5999984741211, 252.83746337890625, 170.5999984741211,
          251.83746337890622, 188.5999984741211, 252.83746337890625, 199.59999847412112,
          256.83746337890625, 208.5999984741211, 260.83746337890625, 215.5999984741211,
          263.83746337890625, 228.5999984741211, 264.83746337890625, 238.59999847412107,
          264.83746337890625, 248.59999847412112, 264.83746337890625, 250.5999984741211,
          241.83746337890625, 254.5999984741211, 239.83746337890625, 246.5999984741211,
          236.83746337890625, 240.5999984741211, 232.83746337890625, 232.5999984741211,
          230.83746337890625, 224.5999984741211, 227.83746337890625, 216.5999984741211,
          227.83746337890625, 209.5999984741211, 226.83746337890625, 198.5999984741211,
          224.83746337890625, 188.5999984741211, 223.83746337890625, 181.5999984741211,
          220.83746337890625, 172.5999984741211, 220.83746337890625, 172.5999984741211
        ],
        id: '8',
        width: 0,
        height: 0,
        stroke: '#006400B3',
        fill: '#006400B3',
        name: 'right_lower_arm',
        closed: true
      },
      left_lower_arm_score: {
        points: [
          81.83746337890625, 170.5999984741211, 48.83746337890625, 170.5999984741211,
          48.83746337890625, 185.5999984741211, 48.83746337890625, 201.5999984741211,
          44.83746337890625, 210.5999984741211, 39.83746337890625, 219.5999984741211,
          38.83746337890625, 231.5999984741211, 37.83746337890625, 244.5999984741211,
          36.83746337890625, 253.5999984741211, 36.83746337890625, 253.5999984741211,
          57.83746337890625, 258.5999984741211, 63.83746337890625, 244.5999984741211,
          68.83746337890625, 233.5999984741211, 74.83746337890625, 221.5999984741211,
          75.83746337890625, 209.5999984741211, 75.83746337890625, 198.5999984741211,
          77.83746337890625, 187.5999984741211, 82.83746337890625, 171.5999984741211,
          82.83746337890625, 171.5999984741211
        ],
        id: '9',
        width: 0,
        height: 0,
        stroke: '#006400B3',
        fill: '#006400B3',
        name: 'left_lower_arm',
        closed: true
      },
      final_trunk_score: {
        points: [
          101, 195.59999084472656, 103, 220.5999908447266, 103, 245.59999084472656, 99,
          258.59999084472656, 203, 258.59999084472656, 199, 238.59999084472656, 201,
          202.59999084472656, 202, 192.59999084472656, 101, 194.5999908447266, 101,
          194.5999908447266
        ],
        id: '9',
        width: 0,
        height: 0,
        stroke: '#ff0000B3',
        fill: '#ff0000B3',
        name: 'trunk',
        closed: true
      }
    },
    cycleAssessmentChart: {
      final_upper_arm_score: {
        low: 75.03467406380028,
        medium: 24.965325936199722,
        high: 0
      },
      final_lower_arm_score: {
        low: 33.287101248266296,
        medium: 0,
        high: 66.7128987517337
      },
      final_neck_score: {
        low: 24.965325936199722,
        medium: 75.03467406380028,
        high: 0
      },
      final_trunk_score: {
        low: 50.069348127600556,
        medium: 49.930651872399444,
        high: 0
      },
      final_leg_score: {
        low: 83.35644937586684,
        medium: 0,
        high: 16.643550624133148
      },
      final_wrist_score: {
        low: 100,
        medium: 0,
        high: 0
      },
      cycle_assessment: {
        low: 16.78224687933426,
        medium: 83.21775312066573,
        high: 0
      },
      percentage: 66.7128987517337,
      max_limb: 'final_lower_arm_score'
    },
    cycleData: [
      {
        name: 'High',
        data: [0]
      },
      {
        name: 'Medium',
        data: [83.21775312066573]
      },
      {
        name: 'Acceptable',
        data: [16.78224687933426]
      }
    ],
    jointsData: [
      {
        name: 'High',
        data: [0, 66.7128987517337, 0, 0, 16.643550624133148, 0]
      },
      {
        name: 'Medium',
        data: [24.965325936199722, 0, 75.03467406380028, 49.930651872399444, 0, 0]
      },
      {
        name: 'Acceptable',
        data: [
          75.03467406380028, 33.287101248266296, 24.965325936199722, 50.069348127600556,
          83.35644937586684, 100
        ]
      }
    ],
    cycleAnalysisXLabels: ['Upper Arm', 'Lower Arm', 'Neck', 'Trunk', 'Leg', 'Wrist'],
    limbsChartData: {
      right_upper_arm_angle: [
        {
          frame: '0',
          y: 38.69460255340575
        },
        {
          frame: '6',
          y: 38.43830071988372
        },
        {
          frame: '12',
          y: 39.02206383454452
        },
        {
          frame: '18',
          y: 39.07791970076738
        },
        {
          frame: '24',
          y: 38.23472511914356
        },
        {
          frame: '30',
          y: 38.41766321149315
        },
        {
          frame: '36',
          y: 39.068167920031414
        },
        {
          frame: '42',
          y: 39.30310046109735
        },
        {
          frame: '48',
          y: 39.068975014969126
        },
        {
          frame: '54',
          y: 38.160341519912905
        },
        {
          frame: '60',
          y: 37.88841703386608
        },
        {
          frame: '66',
          y: 38.773259971079206
        },
        {
          frame: '72',
          y: 39.64255006076775
        },
        {
          frame: '78',
          y: 40.117731594686525
        },
        {
          frame: '84',
          y: 40.4934807670008
        },
        {
          frame: '90',
          y: 40.8047000377104
        },
        {
          frame: '96',
          y: 40.9405253866946
        },
        {
          frame: '102',
          y: 40.781530708251374
        },
        {
          frame: '108',
          y: 41.080651382044614
        },
        {
          frame: '114',
          y: 40.642912810118986
        },
        {
          frame: '120',
          y: 40.02988784452372
        },
        {
          frame: '126',
          y: 38.3348517039836
        },
        {
          frame: '132',
          y: 36.8610704953181
        },
        {
          frame: '138',
          y: 34.69352993267173
        },
        {
          frame: '144',
          y: 32.17014245929773
        },
        {
          frame: '150',
          y: 28.977095688478123
        },
        {
          frame: '156',
          y: 23.872948096350274
        },
        {
          frame: '162',
          y: 21.509614146043198
        },
        {
          frame: '168',
          y: 24.194020461611835
        },
        {
          frame: '174',
          y: 31.249276575682604
        },
        {
          frame: '180',
          y: 39.56468038270488
        },
        {
          frame: '186',
          y: 49.96874425665678
        },
        {
          frame: '192',
          y: 60.25733462616205
        },
        {
          frame: '198',
          y: 70.52398197251141
        },
        {
          frame: '204',
          y: 76.92576457044075
        },
        {
          frame: '210',
          y: 82.00186193037989
        },
        {
          frame: '216',
          y: 86.4935780496409
        },
        {
          frame: '222',
          y: 89.60356456061511
        },
        {
          frame: '228',
          y: 91.72862943154058
        },
        {
          frame: '234',
          y: 93.03592782357711
        },
        {
          frame: '240',
          y: 93.37376007333617
        },
        {
          frame: '246',
          y: 93.70216269414912
        },
        {
          frame: '252',
          y: 93.26940038052429
        },
        {
          frame: '258',
          y: 92.8128869316016
        },
        {
          frame: '264',
          y: 92.29669610766642
        },
        {
          frame: '270',
          y: 91.05236739228985
        },
        {
          frame: '276',
          y: 89.30174760215093
        },
        {
          frame: '282',
          y: 87.03203122595703
        },
        {
          frame: '288',
          y: 82.93604841225925
        },
        {
          frame: '294',
          y: 77.4215618111695
        },
        {
          frame: '300',
          y: 72.7056264141704
        },
        {
          frame: '306',
          y: 66.5539881199705
        },
        {
          frame: '312',
          y: 58.54568721195095
        },
        {
          frame: '318',
          y: 50.15939879161547
        },
        {
          frame: '324',
          y: 43.57898966814377
        },
        {
          frame: '330',
          y: 38.298601764502315
        },
        {
          frame: '336',
          y: 31.74160774655601
        },
        {
          frame: '342',
          y: 28.648105285460964
        },
        {
          frame: '348',
          y: 30.597810758432857
        },
        {
          frame: '354',
          y: 35.43416961410545
        },
        {
          frame: '360',
          y: 37.74180263305781
        },
        {
          frame: '366',
          y: 37.11292807929789
        },
        {
          frame: '372',
          y: 37.06464416064292
        },
        {
          frame: '378',
          y: 37.527833256221825
        },
        {
          frame: '384',
          y: 36.90052424669114
        },
        {
          frame: '390',
          y: 33.85010878568142
        },
        {
          frame: '396',
          y: 34.738643172432965
        },
        {
          frame: '402',
          y: 36.82180079993147
        },
        {
          frame: '408',
          y: 37.48868119842911
        },
        {
          frame: '414',
          y: 38.22169054822258
        },
        {
          frame: '420',
          y: 38.98597424127127
        },
        {
          frame: '426',
          y: 36.64837097456744
        },
        {
          frame: '432',
          y: 32.52550983085165
        },
        {
          frame: '438',
          y: 32.485676184493265
        },
        {
          frame: '444',
          y: 29.06603463064695
        },
        {
          frame: '450',
          y: 28.312056343500995
        },
        {
          frame: '456',
          y: 34.43848432835378
        },
        {
          frame: '462',
          y: 29.198069626038734
        },
        {
          frame: '468',
          y: 27.136170005044466
        },
        {
          frame: '474',
          y: 23.41694904391389
        },
        {
          frame: '480',
          y: 18.86700860429085
        },
        {
          frame: '486',
          y: 18.034217939981257
        },
        {
          frame: '492',
          y: 14.652274959898799
        },
        {
          frame: '498',
          y: 22.009369913713417
        },
        {
          frame: '504',
          y: 25.848923524364583
        },
        {
          frame: '510',
          y: 26.302976965578164
        },
        {
          frame: '516',
          y: 28.01036566140614
        },
        {
          frame: '522',
          y: 30.20264735526785
        },
        {
          frame: '528',
          y: 32.91769704829276
        },
        {
          frame: '534',
          y: 34.10605991763547
        },
        {
          frame: '540',
          y: 33.55211689768587
        },
        {
          frame: '546',
          y: 33.456746801748366
        },
        {
          frame: '552',
          y: 33.45421685139489
        },
        {
          frame: '558',
          y: 32.98458225813914
        },
        {
          frame: '564',
          y: 31.756647384324438
        },
        {
          frame: '570',
          y: 26.15769481511789
        },
        {
          frame: '576',
          y: 22.101174737739125
        },
        {
          frame: '582',
          y: 16.19835412447731
        },
        {
          frame: '588',
          y: 12.391412538435361
        },
        {
          frame: '594',
          y: 16.98640938953531
        },
        {
          frame: '600',
          y: 19.046956830901397
        },
        {
          frame: '606',
          y: 19.333435726840126
        },
        {
          frame: '612',
          y: 14.057664599131959
        },
        {
          frame: '618',
          y: 15.360909519137572
        },
        {
          frame: '624',
          y: 17.18035655341719
        },
        {
          frame: '630',
          y: 24.92505180623361
        },
        {
          frame: '636',
          y: 30.02646575309721
        },
        {
          frame: '642',
          y: 31.36101653133926
        },
        {
          frame: '648',
          y: 31.20717990406055
        },
        {
          frame: '654',
          y: 30.81022169698755
        },
        {
          frame: '660',
          y: 26.646490620208628
        },
        {
          frame: '666',
          y: 19.529595353864245
        },
        {
          frame: '672',
          y: 20.065615744842912
        },
        {
          frame: '678',
          y: 22.262767780378898
        },
        {
          frame: '684',
          y: 21.621430729746514
        },
        {
          frame: '690',
          y: 20.350960571467763
        },
        {
          frame: '696',
          y: 22.687212179603954
        },
        {
          frame: '702',
          y: 27.249343894913004
        },
        {
          frame: '708',
          y: 29.280832526271475
        },
        {
          frame: '714',
          y: 29.426711591602828
        },
        {
          frame: '720',
          y: 30.426523184528232
        }
      ],
      left_upper_arm_angle: [
        {
          frame: '0',
          y: 33.55362912270471
        },
        {
          frame: '6',
          y: 33.264577501158456
        },
        {
          frame: '12',
          y: 33.787369748524306
        },
        {
          frame: '18',
          y: 33.8133208350366
        },
        {
          frame: '24',
          y: 33.76520472853574
        },
        {
          frame: '30',
          y: 33.9231506167316
        },
        {
          frame: '36',
          y: 34.340742049738765
        },
        {
          frame: '42',
          y: 34.57969150009074
        },
        {
          frame: '48',
          y: 35.170082907823804
        },
        {
          frame: '54',
          y: 34.60582103597665
        },
        {
          frame: '60',
          y: 34.62390952727742
        },
        {
          frame: '66',
          y: 35.25788848581583
        },
        {
          frame: '72',
          y: 35.631801396920274
        },
        {
          frame: '78',
          y: 35.76918605973784
        },
        {
          frame: '84',
          y: 35.959575964872506
        },
        {
          frame: '90',
          y: 36.39814968234448
        },
        {
          frame: '96',
          y: 36.37832972303658
        },
        {
          frame: '102',
          y: 37.130843142812886
        },
        {
          frame: '108',
          y: 37.082897744136744
        },
        {
          frame: '114',
          y: 37.14583775214106
        },
        {
          frame: '120',
          y: 39.15628377482484
        },
        {
          frame: '126',
          y: 41.22809896623694
        },
        {
          frame: '132',
          y: 42.02708783431934
        },
        {
          frame: '138',
          y: 42.42617460307719
        },
        {
          frame: '144',
          y: 42.192791719732334
        },
        {
          frame: '150',
          y: 41.72922123878276
        },
        {
          frame: '156',
          y: 38.20425874443337
        },
        {
          frame: '162',
          y: 34.32752422785958
        },
        {
          frame: '168',
          y: 33.77835520754232
        },
        {
          frame: '174',
          y: 35.36073069469669
        },
        {
          frame: '180',
          y: 40.054965934418156
        },
        {
          frame: '186',
          y: 44.92456772077913
        },
        {
          frame: '192',
          y: 49.03535946277935
        },
        {
          frame: '198',
          y: 54.193976179774545
        },
        {
          frame: '204',
          y: 57.12504471794889
        },
        {
          frame: '210',
          y: 58.045463425296305
        },
        {
          frame: '216',
          y: 60.40517541366759
        },
        {
          frame: '222',
          y: 64.66845066399506
        },
        {
          frame: '228',
          y: 63.60389966202759
        },
        {
          frame: '234',
          y: 62.669026406407895
        },
        {
          frame: '240',
          y: 62.60287182569233
        },
        {
          frame: '246',
          y: 61.78936191108287
        },
        {
          frame: '252',
          y: 62.2734313211297
        },
        {
          frame: '258',
          y: 61.931927644244965
        },
        {
          frame: '264',
          y: 61.71635128738728
        },
        {
          frame: '270',
          y: 62.91352036305804
        },
        {
          frame: '276',
          y: 62.19997907798388
        },
        {
          frame: '282',
          y: 59.8073850193525
        },
        {
          frame: '288',
          y: 57.952398326563404
        },
        {
          frame: '294',
          y: 55.808169344586865
        },
        {
          frame: '300',
          y: 53.63178542928885
        },
        {
          frame: '306',
          y: 51.567593765899936
        },
        {
          frame: '312',
          y: 45.808336915142405
        },
        {
          frame: '318',
          y: 40.24857755454283
        },
        {
          frame: '324',
          y: 33.440068510335834
        },
        {
          frame: '330',
          y: 27.987064355818603
        },
        {
          frame: '336',
          y: 28.75500338777257
        },
        {
          frame: '342',
          y: 29.400396727592486
        },
        {
          frame: '348',
          y: 30.152705415947366
        },
        {
          frame: '354',
          y: 30.493054618064452
        },
        {
          frame: '360',
          y: 30.630278864234555
        },
        {
          frame: '366',
          y: 30.81234423368295
        },
        {
          frame: '372',
          y: 30.754216156696714
        },
        {
          frame: '378',
          y: 30.923704556903825
        },
        {
          frame: '384',
          y: 32.32495106195485
        },
        {
          frame: '390',
          y: 35.52323029871743
        },
        {
          frame: '396',
          y: 36.477055427865665
        },
        {
          frame: '402',
          y: 32.149137478590156
        },
        {
          frame: '408',
          y: 29.353496096076476
        },
        {
          frame: '414',
          y: 36.13171808065331
        },
        {
          frame: '420',
          y: 37.56649203171239
        },
        {
          frame: '426',
          y: 40.2864772402082
        },
        {
          frame: '432',
          y: 48.43080661871679
        },
        {
          frame: '438',
          y: 42.262561636138344
        },
        {
          frame: '444',
          y: 36.981713464019336
        },
        {
          frame: '450',
          y: 32.160156201886466
        },
        {
          frame: '456',
          y: 31.686772319985376
        },
        {
          frame: '462',
          y: 31.17582524806096
        },
        {
          frame: '468',
          y: 31.75084097239224
        },
        {
          frame: '474',
          y: 34.14688735323877
        },
        {
          frame: '480',
          y: 38.93311679248402
        },
        {
          frame: '486',
          y: 40.57491138468301
        },
        {
          frame: '492',
          y: 43.963305236691944
        },
        {
          frame: '498',
          y: 43.600618873389884
        },
        {
          frame: '504',
          y: 47.531959265306064
        },
        {
          frame: '510',
          y: 52.515352680039214
        },
        {
          frame: '516',
          y: 57.85980781009054
        },
        {
          frame: '522',
          y: 61.57571091864027
        },
        {
          frame: '528',
          y: 62.996478727535305
        },
        {
          frame: '534',
          y: 63.9067625172467
        },
        {
          frame: '540',
          y: 63.898018458511096
        },
        {
          frame: '546',
          y: 63.540612456753266
        },
        {
          frame: '552',
          y: 62.547092198293534
        },
        {
          frame: '558',
          y: 61.669161078831024
        },
        {
          frame: '564',
          y: 57.8931438063391
        },
        {
          frame: '570',
          y: 50.067777786525035
        },
        {
          frame: '576',
          y: 41.36924834011608
        },
        {
          frame: '582',
          y: 39.503470624066544
        },
        {
          frame: '588',
          y: 37.98666894141146
        },
        {
          frame: '594',
          y: 35.8932216416016
        },
        {
          frame: '600',
          y: 35.527920181893805
        },
        {
          frame: '606',
          y: 35.7365048408865
        },
        {
          frame: '612',
          y: 36.31396160655906
        },
        {
          frame: '618',
          y: 36.82413994243339
        },
        {
          frame: '624',
          y: 37.49892469371384
        },
        {
          frame: '630',
          y: 36.92909306765761
        },
        {
          frame: '636',
          y: 34.85348335950358
        },
        {
          frame: '642',
          y: 32.978517192122574
        },
        {
          frame: '648',
          y: 33.8672871285211
        },
        {
          frame: '654',
          y: 34.49565016863038
        },
        {
          frame: '660',
          y: 35.52290124662884
        },
        {
          frame: '666',
          y: 36.45794395966272
        },
        {
          frame: '672',
          y: 34.89612788934917
        },
        {
          frame: '678',
          y: 32.401865251874824
        },
        {
          frame: '684',
          y: 30.99359220710724
        },
        {
          frame: '690',
          y: 29.421103211853186
        },
        {
          frame: '696',
          y: 29.565793098504223
        },
        {
          frame: '702',
          y: 29.263568882756438
        },
        {
          frame: '708',
          y: 30.4217079613881
        },
        {
          frame: '714',
          y: 29.365894014761036
        },
        {
          frame: '720',
          y: 29.52082640999106
        }
      ],
      left_lower_arm_angle: [
        {
          frame: '0',
          y: 7.69952246034174
        },
        {
          frame: '6',
          y: 8.085416076677916
        },
        {
          frame: '12',
          y: 8.254528035241634
        },
        {
          frame: '18',
          y: 8.51044371632807
        },
        {
          frame: '24',
          y: 8.398473162327463
        },
        {
          frame: '30',
          y: 8.282448937354843
        },
        {
          frame: '36',
          y: 8.269881684384444
        },
        {
          frame: '42',
          y: 8.377078074242405
        },
        {
          frame: '48',
          y: 8.56032342749134
        },
        {
          frame: '54',
          y: 8.69891921062748
        },
        {
          frame: '60',
          y: 8.74749943596089
        },
        {
          frame: '66',
          y: 9.332462008556206
        },
        {
          frame: '72',
          y: 9.493350887396389
        },
        {
          frame: '78',
          y: 9.599042142429852
        },
        {
          frame: '84',
          y: 9.446519244377725
        },
        {
          frame: '90',
          y: 8.70014938714462
        },
        {
          frame: '96',
          y: 8.280773377013844
        },
        {
          frame: '102',
          y: 9.409179947742388
        },
        {
          frame: '108',
          y: 9.885697591353704
        },
        {
          frame: '114',
          y: 9.99413340622101
        },
        {
          frame: '120',
          y: 10.468140677767536
        },
        {
          frame: '126',
          y: 12.523105556185186
        },
        {
          frame: '132',
          y: 14.788329002836349
        },
        {
          frame: '138',
          y: 17.77605875614327
        },
        {
          frame: '144',
          y: 19.844102024736173
        },
        {
          frame: '150',
          y: 24.960042536969127
        },
        {
          frame: '156',
          y: 33.39697005304038
        },
        {
          frame: '162',
          y: 46.66570612706758
        },
        {
          frame: '168',
          y: 58.467292378265654
        },
        {
          frame: '174',
          y: 67.61151987485927
        },
        {
          frame: '180',
          y: 74.8393197785787
        },
        {
          frame: '186',
          y: 81.21507291645977
        },
        {
          frame: '192',
          y: 87.17686675249657
        },
        {
          frame: '198',
          y: 93.7354094060718
        },
        {
          frame: '204',
          y: 98.76168534013637
        },
        {
          frame: '210',
          y: 103.6496540803387
        },
        {
          frame: '216',
          y: 107.41075368856012
        },
        {
          frame: '222',
          y: 110.66334932234719
        },
        {
          frame: '228',
          y: 110.63800731465905
        },
        {
          frame: '234',
          y: 111.48977072172198
        },
        {
          frame: '240',
          y: 112.19474484390895
        },
        {
          frame: '246',
          y: 111.16335779935922
        },
        {
          frame: '252',
          y: 110.41776641239257
        },
        {
          frame: '258',
          y: 109.82688447861327
        },
        {
          frame: '264',
          y: 109.84467239454189
        },
        {
          frame: '270',
          y: 110.77339305601777
        },
        {
          frame: '276',
          y: 111.06999633885012
        },
        {
          frame: '282',
          y: 112.17383213910458
        },
        {
          frame: '288',
          y: 109.3652121443676
        },
        {
          frame: '294',
          y: 107.97221128135715
        },
        {
          frame: '300',
          y: 104.47879004956118
        },
        {
          frame: '306',
          y: 101.3368485778573
        },
        {
          frame: '312',
          y: 96.71065138234661
        },
        {
          frame: '318',
          y: 93.00989063527406
        },
        {
          frame: '324',
          y: 90.0826827649845
        },
        {
          frame: '330',
          y: 83.42674812736483
        },
        {
          frame: '336',
          y: 71.11451287539415
        },
        {
          frame: '342',
          y: 64.35084452960164
        },
        {
          frame: '348',
          y: 61.84410981715648
        },
        {
          frame: '354',
          y: 57.72801690974444
        },
        {
          frame: '360',
          y: 55.7303537388307
        },
        {
          frame: '366',
          y: 53.84550543241174
        },
        {
          frame: '372',
          y: 53.73104533936831
        },
        {
          frame: '378',
          y: 54.43761427859057
        },
        {
          frame: '384',
          y: 56.069294173140605
        },
        {
          frame: '390',
          y: 57.76528260035225
        },
        {
          frame: '396',
          y: 60.185458634644476
        },
        {
          frame: '402',
          y: 55.421943032664345
        },
        {
          frame: '408',
          y: 46.24796120075871
        },
        {
          frame: '414',
          y: 36.86440271410797
        },
        {
          frame: '420',
          y: 19.243173091558617
        },
        {
          frame: '426',
          y: 13.997603832445009
        },
        {
          frame: '432',
          y: 51.575640279866604
        },
        {
          frame: '438',
          y: 65.7199374293134
        },
        {
          frame: '444',
          y: 67.31299419808417
        },
        {
          frame: '450',
          y: 70.99864125311346
        },
        {
          frame: '456',
          y: 75.51077098391487
        },
        {
          frame: '462',
          y: 78.03161075996786
        },
        {
          frame: '468',
          y: 79.12747435073818
        },
        {
          frame: '474',
          y: 76.77341729976632
        },
        {
          frame: '480',
          y: 75.33451332802163
        },
        {
          frame: '486',
          y: 73.18646160734357
        },
        {
          frame: '492',
          y: 71.36043632403565
        },
        {
          frame: '498',
          y: 73.13770371621358
        },
        {
          frame: '504',
          y: 76.82764749314853
        },
        {
          frame: '510',
          y: 81.00487223624575
        },
        {
          frame: '516',
          y: 82.62150715855942
        },
        {
          frame: '522',
          y: 84.40163728882422
        },
        {
          frame: '528',
          y: 85.66091777209239
        },
        {
          frame: '534',
          y: 87.0363267472689
        },
        {
          frame: '540',
          y: 88.32085509475537
        },
        {
          frame: '546',
          y: 88.67388053829725
        },
        {
          frame: '552',
          y: 87.42553680325958
        },
        {
          frame: '558',
          y: 87.66786781098871
        },
        {
          frame: '564',
          y: 86.166910414127
        },
        {
          frame: '570',
          y: 78.54771575725849
        },
        {
          frame: '576',
          y: 63.817073199141475
        },
        {
          frame: '582',
          y: 44.192772140055865
        },
        {
          frame: '588',
          y: 34.86803038548908
        },
        {
          frame: '594',
          y: 26.524483641120014
        },
        {
          frame: '600',
          y: 23.92492396550776
        },
        {
          frame: '606',
          y: 23.823197965766497
        },
        {
          frame: '612',
          y: 25.07917491374934
        },
        {
          frame: '618',
          y: 22.627760600874495
        },
        {
          frame: '624',
          y: 22.807577610686764
        },
        {
          frame: '630',
          y: 19.88433891793838
        },
        {
          frame: '636',
          y: 18.656224635799106
        },
        {
          frame: '642',
          y: 16.157833300184905
        },
        {
          frame: '648',
          y: 16.676392136872778
        },
        {
          frame: '654',
          y: 17.205921523110106
        },
        {
          frame: '660',
          y: 19.104059177510095
        },
        {
          frame: '666',
          y: 24.377145387963964
        },
        {
          frame: '672',
          y: 27.280973948271424
        },
        {
          frame: '678',
          y: 28.77230497560336
        },
        {
          frame: '684',
          y: 29.81052087281182
        },
        {
          frame: '690',
          y: 26.81259581675198
        },
        {
          frame: '696',
          y: 23.53158923655141
        },
        {
          frame: '702',
          y: 19.020982774842366
        },
        {
          frame: '708',
          y: 16.872185911665042
        },
        {
          frame: '714',
          y: 17.742922678473974
        },
        {
          frame: '720',
          y: 17.44433319885772
        }
      ],
      right_lower_arm_angle: [
        {
          frame: '0',
          y: 8.85574029356485
        },
        {
          frame: '6',
          y: 8.98971036333927
        },
        {
          frame: '12',
          y: 9.163768000779669
        },
        {
          frame: '18',
          y: 9.114408095588061
        },
        {
          frame: '24',
          y: 9.301868086024257
        },
        {
          frame: '30',
          y: 9.555205330006316
        },
        {
          frame: '36',
          y: 10.323816845476557
        },
        {
          frame: '42',
          y: 10.498973236941623
        },
        {
          frame: '48',
          y: 10.126389431047073
        },
        {
          frame: '54',
          y: 9.837590481258871
        },
        {
          frame: '60',
          y: 9.994573123007626
        },
        {
          frame: '66',
          y: 10.579231711879856
        },
        {
          frame: '72',
          y: 10.104072492644207
        },
        {
          frame: '78',
          y: 10.327806552964631
        },
        {
          frame: '84',
          y: 11.22786545751822
        },
        {
          frame: '90',
          y: 11.855344537689064
        },
        {
          frame: '96',
          y: 11.82332721968757
        },
        {
          frame: '102',
          y: 11.565425538883005
        },
        {
          frame: '108',
          y: 10.778673946542597
        },
        {
          frame: '114',
          y: 10.295681876878945
        },
        {
          frame: '120',
          y: 9.536281118682297
        },
        {
          frame: '126',
          y: 10.48192104201479
        },
        {
          frame: '132',
          y: 12.728109602895898
        },
        {
          frame: '138',
          y: 14.48526272912116
        },
        {
          frame: '144',
          y: 17.052321648779923
        },
        {
          frame: '150',
          y: 23.7910197337566
        },
        {
          frame: '156',
          y: 34.00205185511888
        },
        {
          frame: '162',
          y: 45.00967337275148
        },
        {
          frame: '168',
          y: 54.60401838507002
        },
        {
          frame: '174',
          y: 63.53228803973215
        },
        {
          frame: '180',
          y: 73.0442297366135
        },
        {
          frame: '186',
          y: 81.65840685696509
        },
        {
          frame: '192',
          y: 89.7263897039205
        },
        {
          frame: '198',
          y: 96.23266944306908
        },
        {
          frame: '204',
          y: 100.75040388454953
        },
        {
          frame: '210',
          y: 105.77926308565179
        },
        {
          frame: '216',
          y: 109.531130329389
        },
        {
          frame: '222',
          y: 112.35611972551602
        },
        {
          frame: '228',
          y: 114.20744059192707
        },
        {
          frame: '234',
          y: 114.91568288224778
        },
        {
          frame: '240',
          y: 114.67687956196485
        },
        {
          frame: '246',
          y: 114.98380794823133
        },
        {
          frame: '252',
          y: 114.48584050659116
        },
        {
          frame: '258',
          y: 114.11259661401195
        },
        {
          frame: '264',
          y: 114.11657580750608
        },
        {
          frame: '270',
          y: 114.04845875218474
        },
        {
          frame: '276',
          y: 113.27167035811189
        },
        {
          frame: '282',
          y: 112.37056540351462
        },
        {
          frame: '288',
          y: 110.49668984566625
        },
        {
          frame: '294',
          y: 108.31460553194484
        },
        {
          frame: '300',
          y: 106.48413755572555
        },
        {
          frame: '306',
          y: 104.34785165730733
        },
        {
          frame: '312',
          y: 101.5998796521257
        },
        {
          frame: '318',
          y: 98.79498531443504
        },
        {
          frame: '324',
          y: 96.07856926791209
        },
        {
          frame: '330',
          y: 92.41944870241787
        },
        {
          frame: '336',
          y: 84.18494268368178
        },
        {
          frame: '342',
          y: 78.27965874070904
        },
        {
          frame: '348',
          y: 73.93016382938028
        },
        {
          frame: '354',
          y: 70.52232405447573
        },
        {
          frame: '360',
          y: 68.46448737978157
        },
        {
          frame: '366',
          y: 66.96081887509081
        },
        {
          frame: '372',
          y: 66.61878123510051
        },
        {
          frame: '378',
          y: 66.50277185522692
        },
        {
          frame: '384',
          y: 67.25200738907661
        },
        {
          frame: '390',
          y: 69.64365668063387
        },
        {
          frame: '396',
          y: 72.12735570463919
        },
        {
          frame: '402',
          y: 72.32278499266623
        },
        {
          frame: '408',
          y: 70.1294201026861
        },
        {
          frame: '414',
          y: 64.96173361037077
        },
        {
          frame: '420',
          y: 59.375167608178884
        },
        {
          frame: '426',
          y: 56.39959206539015
        },
        {
          frame: '432',
          y: 54.60329469068698
        },
        {
          frame: '438',
          y: 49.03824779288172
        },
        {
          frame: '444',
          y: 58.35444511290976
        },
        {
          frame: '450',
          y: 55.884847755974405
        },
        {
          frame: '456',
          y: 64.64955987973877
        },
        {
          frame: '462',
          y: 70.88873907389377
        },
        {
          frame: '468',
          y: 68.45189561200357
        },
        {
          frame: '474',
          y: 58.040572288695145
        },
        {
          frame: '480',
          y: 30.101279930914245
        },
        {
          frame: '486',
          y: 53.19362890723387
        },
        {
          frame: '492',
          y: 65.92856374848651
        },
        {
          frame: '498',
          y: 59.34470801041344
        },
        {
          frame: '504',
          y: 65.6956283393496
        },
        {
          frame: '510',
          y: 74.31828773768144
        },
        {
          frame: '516',
          y: 76.54087469856205
        },
        {
          frame: '522',
          y: 79.53728543031163
        },
        {
          frame: '528',
          y: 79.56691245053342
        },
        {
          frame: '534',
          y: 80.51576529197924
        },
        {
          frame: '540',
          y: 81.73084579861788
        },
        {
          frame: '546',
          y: 81.56341330115988
        },
        {
          frame: '552',
          y: 80.57977785296397
        },
        {
          frame: '558',
          y: 78.38649228956439
        },
        {
          frame: '564',
          y: 73.32829677845358
        },
        {
          frame: '570',
          y: 63.7917369374329
        },
        {
          frame: '576',
          y: 42.3444906525354
        },
        {
          frame: '582',
          y: 34.112028430368326
        },
        {
          frame: '588',
          y: 26.956394340245424
        },
        {
          frame: '594',
          y: 13.29112307149046
        },
        {
          frame: '600',
          y: 8.606149820366767
        },
        {
          frame: '606',
          y: 13.330364675771932
        },
        {
          frame: '612',
          y: 17.211334913254746
        },
        {
          frame: '618',
          y: 25.552374164060353
        },
        {
          frame: '624',
          y: 23.911022076086482
        },
        {
          frame: '630',
          y: 16.72504953583902
        },
        {
          frame: '636',
          y: 13.952318409946542
        },
        {
          frame: '642',
          y: 12.605024749323292
        },
        {
          frame: '648',
          y: 15.342019967622633
        },
        {
          frame: '654',
          y: 16.1430303073343
        },
        {
          frame: '660',
          y: 17.29556684968054
        },
        {
          frame: '666',
          y: 20.468798314980006
        },
        {
          frame: '672',
          y: 24.115418568997143
        },
        {
          frame: '678',
          y: 26.26509678377772
        },
        {
          frame: '684',
          y: 27.527638671915582
        },
        {
          frame: '690',
          y: 25.27896369753188
        },
        {
          frame: '696',
          y: 19.234211230876724
        },
        {
          frame: '702',
          y: 17.393298070947168
        },
        {
          frame: '708',
          y: 14.233938444849429
        },
        {
          frame: '714',
          y: 21.86257255588302
        },
        {
          frame: '720',
          y: 23.33687810559337
        }
      ],
      neck_angle: [
        {
          frame: '0',
          y: 27.979236160941355
        },
        {
          frame: '6',
          y: 28.4304810109399
        },
        {
          frame: '12',
          y: 28.6654421215068
        },
        {
          frame: '18',
          y: 28.911226370422355
        },
        {
          frame: '24',
          y: 28.553061479116536
        },
        {
          frame: '30',
          y: 29.052760244659453
        },
        {
          frame: '36',
          y: 28.974977420689754
        },
        {
          frame: '42',
          y: 29.166451393910137
        },
        {
          frame: '48',
          y: 28.975533409845042
        },
        {
          frame: '54',
          y: 29.030730985151592
        },
        {
          frame: '60',
          y: 29.139319383405905
        },
        {
          frame: '66',
          y: 29.63765332066862
        },
        {
          frame: '72',
          y: 30.071847689815655
        },
        {
          frame: '78',
          y: 30.125298456839886
        },
        {
          frame: '84',
          y: 29.762462295578786
        },
        {
          frame: '90',
          y: 29.809875875091166
        },
        {
          frame: '96',
          y: 30.76755594526628
        },
        {
          frame: '102',
          y: 32.85931766412142
        },
        {
          frame: '108',
          y: 34.19011128971485
        },
        {
          frame: '114',
          y: 34.308600944857574
        },
        {
          frame: '120',
          y: 33.43335557402489
        },
        {
          frame: '126',
          y: 33.1336626627168
        },
        {
          frame: '132',
          y: 32.695638136809976
        },
        {
          frame: '138',
          y: 31.103219593113664
        },
        {
          frame: '144',
          y: 28.611289241656607
        },
        {
          frame: '150',
          y: 26.068599487052296
        },
        {
          frame: '156',
          y: 24.07299031197698
        },
        {
          frame: '162',
          y: 24.939021304762775
        },
        {
          frame: '168',
          y: 27.103895930533838
        },
        {
          frame: '174',
          y: 26.45961417609476
        },
        {
          frame: '180',
          y: 24.239435578751767
        },
        {
          frame: '186',
          y: 21.662246302454008
        },
        {
          frame: '192',
          y: 18.796516996148966
        },
        {
          frame: '198',
          y: 17.26152299098257
        },
        {
          frame: '204',
          y: 15.966604190079892
        },
        {
          frame: '210',
          y: 13.669184324032784
        },
        {
          frame: '216',
          y: 13.608280964891463
        },
        {
          frame: '222',
          y: 14.686428676198062
        },
        {
          frame: '228',
          y: 14.501012365472633
        },
        {
          frame: '234',
          y: 14.503629050451536
        },
        {
          frame: '240',
          y: 15.36808874286794
        },
        {
          frame: '246',
          y: 14.398663158636396
        },
        {
          frame: '252',
          y: 14.258805533374186
        },
        {
          frame: '258',
          y: 14.132008034268125
        },
        {
          frame: '264',
          y: 13.940238939317197
        },
        {
          frame: '270',
          y: 14.02382265750171
        },
        {
          frame: '276',
          y: 13.66739140550473
        },
        {
          frame: '282',
          y: 12.998595884861743
        },
        {
          frame: '288',
          y: 13.194535868619653
        },
        {
          frame: '294',
          y: 12.82043192871879
        },
        {
          frame: '300',
          y: 12.99240529748775
        },
        {
          frame: '306',
          y: 14.063540240603828
        },
        {
          frame: '312',
          y: 15.943863147659869
        },
        {
          frame: '318',
          y: 18.497157583999098
        },
        {
          frame: '324',
          y: 22.140818044286476
        },
        {
          frame: '330',
          y: 26.598125981756226
        },
        {
          frame: '336',
          y: 34.52816608795814
        },
        {
          frame: '342',
          y: 36.94340229797032
        },
        {
          frame: '348',
          y: 34.52390900423691
        },
        {
          frame: '354',
          y: 31.80069912198644
        },
        {
          frame: '360',
          y: 30.77102825546649
        },
        {
          frame: '366',
          y: 32.342880707209474
        },
        {
          frame: '372',
          y: 32.76936210368075
        },
        {
          frame: '378',
          y: 32.87057135540749
        },
        {
          frame: '384',
          y: 33.008095660064185
        },
        {
          frame: '390',
          y: 34.53653065491113
        },
        {
          frame: '396',
          y: 35.70017632338562
        },
        {
          frame: '402',
          y: 36.81814152356196
        },
        {
          frame: '408',
          y: 34.19938215704806
        },
        {
          frame: '414',
          y: 33.73568261633196
        },
        {
          frame: '420',
          y: 33.766237437346575
        },
        {
          frame: '426',
          y: 32.62548844572055
        },
        {
          frame: '432',
          y: 31.24206836131731
        },
        {
          frame: '438',
          y: 32.13158571986449
        },
        {
          frame: '444',
          y: 32.75831559526969
        },
        {
          frame: '450',
          y: 32.839603429980684
        },
        {
          frame: '456',
          y: 30.840460474175956
        },
        {
          frame: '462',
          y: 27.857651552713293
        },
        {
          frame: '468',
          y: 27.46061624548028
        },
        {
          frame: '474',
          y: 30.677416551817714
        },
        {
          frame: '480',
          y: 23.97997959334532
        },
        {
          frame: '486',
          y: 14.973390083732994
        },
        {
          frame: '492',
          y: 8.485056902952067
        },
        {
          frame: '498',
          y: 14.415270572692776
        },
        {
          frame: '504',
          y: 13.082509701449311
        },
        {
          frame: '510',
          y: 12.35430881279538
        },
        {
          frame: '516',
          y: 12.415931931888707
        },
        {
          frame: '522',
          y: 13.485470019095175
        },
        {
          frame: '528',
          y: 13.475863233279227
        },
        {
          frame: '534',
          y: 12.95109069300517
        },
        {
          frame: '540',
          y: 13.039325629588575
        },
        {
          frame: '546',
          y: 12.677101428623104
        },
        {
          frame: '552',
          y: 11.902436879338069
        },
        {
          frame: '558',
          y: 12.426137071792766
        },
        {
          frame: '564',
          y: 12.942074008793384
        },
        {
          frame: '570',
          y: 11.64731551577728
        },
        {
          frame: '576',
          y: 12.806919877758673
        },
        {
          frame: '582',
          y: 13.02297369579594
        },
        {
          frame: '588',
          y: 12.044572402661913
        },
        {
          frame: '594',
          y: 14.191999321635734
        },
        {
          frame: '600',
          y: 17.351618018424602
        },
        {
          frame: '606',
          y: 16.279798510597004
        },
        {
          frame: '612',
          y: 16.49506403421273
        },
        {
          frame: '618',
          y: 18.9549071404266
        },
        {
          frame: '624',
          y: 21.852579596327843
        },
        {
          frame: '630',
          y: 20.123107660295545
        },
        {
          frame: '636',
          y: 16.925312249672658
        },
        {
          frame: '642',
          y: 18.39103954345609
        },
        {
          frame: '648',
          y: 19.628661206703725
        },
        {
          frame: '654',
          y: 17.703319294435147
        },
        {
          frame: '660',
          y: 17.38344262254776
        },
        {
          frame: '666',
          y: 17.32833312145536
        },
        {
          frame: '672',
          y: 16.29109171171757
        },
        {
          frame: '678',
          y: 19.163093692657696
        },
        {
          frame: '684',
          y: 19.914886308784304
        },
        {
          frame: '690',
          y: 23.575634642338684
        },
        {
          frame: '696',
          y: 28.553816610818956
        },
        {
          frame: '702',
          y: 26.55530155787818
        },
        {
          frame: '708',
          y: 24.139968493416745
        },
        {
          frame: '714',
          y: 26.18671084350899
        },
        {
          frame: '720',
          y: 26.071205168299002
        }
      ],
      trunk_bending_angles: [
        {
          frame: '0',
          y: 21.121479235847268
        },
        {
          frame: '6',
          y: 21.457543824147272
        },
        {
          frame: '12',
          y: 21.5749906337136
        },
        {
          frame: '18',
          y: 21.833330824883763
        },
        {
          frame: '24',
          y: 21.89843133135995
        },
        {
          frame: '30',
          y: 21.953227720654763
        },
        {
          frame: '36',
          y: 22.19114572790229
        },
        {
          frame: '42',
          y: 22.201496398520927
        },
        {
          frame: '48',
          y: 22.068367607099454
        },
        {
          frame: '54',
          y: 22.21887235542204
        },
        {
          frame: '60',
          y: 22.387375802619307
        },
        {
          frame: '66',
          y: 22.437134289357143
        },
        {
          frame: '72',
          y: 22.197556410293735
        },
        {
          frame: '78',
          y: 22.040515079727115
        },
        {
          frame: '84',
          y: 21.96082704477267
        },
        {
          frame: '90',
          y: 21.87176495310263
        },
        {
          frame: '96',
          y: 21.646307379098914
        },
        {
          frame: '102',
          y: 21.619900155435904
        },
        {
          frame: '108',
          y: 22.05944922345185
        },
        {
          frame: '114',
          y: 22.30242471890855
        },
        {
          frame: '120',
          y: 22.09125955212488
        },
        {
          frame: '126',
          y: 21.39639233479563
        },
        {
          frame: '132',
          y: 20.18285045639832
        },
        {
          frame: '138',
          y: 19.11650428300218
        },
        {
          frame: '144',
          y: 17.730570325364987
        },
        {
          frame: '150',
          y: 16.815479204290423
        },
        {
          frame: '156',
          y: 18.005547226276967
        },
        {
          frame: '162',
          y: 21.542010466633773
        },
        {
          frame: '168',
          y: 25.15951030125927
        },
        {
          frame: '174',
          y: 28.505678870075936
        },
        {
          frame: '180',
          y: 32.543545132854995
        },
        {
          frame: '186',
          y: 39.08145013575145
        },
        {
          frame: '192',
          y: 45.967135352924466
        },
        {
          frame: '198',
          y: 52.81608746261502
        },
        {
          frame: '204',
          y: 57.900914610034526
        },
        {
          frame: '210',
          y: 62.45637424515108
        },
        {
          frame: '216',
          y: 65.718718103445
        },
        {
          frame: '222',
          y: 68.36798654749569
        },
        {
          frame: '228',
          y: 69.65672738238229
        },
        {
          frame: '234',
          y: 70.24358633869694
        },
        {
          frame: '240',
          y: 70.61226991557712
        },
        {
          frame: '246',
          y: 70.50929777252358
        },
        {
          frame: '252',
          y: 70.49791583808964
        },
        {
          frame: '258',
          y: 70.25085795738256
        },
        {
          frame: '264',
          y: 69.97018490362788
        },
        {
          frame: '270',
          y: 69.04530806155945
        },
        {
          frame: '276',
          y: 67.52691811007966
        },
        {
          frame: '282',
          y: 65.52941615042234
        },
        {
          frame: '288',
          y: 62.64386858727394
        },
        {
          frame: '294',
          y: 58.957221921725434
        },
        {
          frame: '300',
          y: 56.14824262412501
        },
        {
          frame: '306',
          y: 52.411329642381254
        },
        {
          frame: '312',
          y: 46.972852359017196
        },
        {
          frame: '318',
          y: 40.73601464078976
        },
        {
          frame: '324',
          y: 34.426771485219014
        },
        {
          frame: '330',
          y: 27.64296975489489
        },
        {
          frame: '336',
          y: 18.374515999489756
        },
        {
          frame: '342',
          y: 14.267379758488026
        },
        {
          frame: '348',
          y: 12.838997059646687
        },
        {
          frame: '354',
          y: 11.512695207782482
        },
        {
          frame: '360',
          y: 12.24198706104976
        },
        {
          frame: '366',
          y: 13.720150303722892
        },
        {
          frame: '372',
          y: 14.089344029344684
        },
        {
          frame: '378',
          y: 14.342712551140789
        },
        {
          frame: '384',
          y: 15.224120701771568
        },
        {
          frame: '390',
          y: 16.977674791474968
        },
        {
          frame: '396',
          y: 18.615553442175152
        },
        {
          frame: '402',
          y: 18.654634461303875
        },
        {
          frame: '408',
          y: 17.673577783348218
        },
        {
          frame: '414',
          y: 19.120171532956764
        },
        {
          frame: '420',
          y: 19.264773030168268
        },
        {
          frame: '426',
          y: 19.082523511800346
        },
        {
          frame: '432',
          y: 19.3506808220522
        },
        {
          frame: '438',
          y: 18.309450902117778
        },
        {
          frame: '444',
          y: 17.385590666670048
        },
        {
          frame: '450',
          y: 18.865017268267053
        },
        {
          frame: '456',
          y: 21.287495798956407
        },
        {
          frame: '462',
          y: 19.276735412175842
        },
        {
          frame: '468',
          y: 16.673830050015816
        },
        {
          frame: '474',
          y: 13.55856868449967
        },
        {
          frame: '480',
          y: 8.164598183541377
        },
        {
          frame: '486',
          y: 6.676897167641156
        },
        {
          frame: '492',
          y: 4.417996673224112
        },
        {
          frame: '498',
          y: 4.069322044965783
        },
        {
          frame: '504',
          y: 10.559820235853765
        },
        {
          frame: '510',
          y: 20.98883283245744
        },
        {
          frame: '516',
          y: 28.781765951963365
        },
        {
          frame: '522',
          y: 34.45497459442399
        },
        {
          frame: '528',
          y: 37.577628838868925
        },
        {
          frame: '534',
          y: 39.32014189410548
        },
        {
          frame: '540',
          y: 40.10916910356914
        },
        {
          frame: '546',
          y: 40.123820383310466
        },
        {
          frame: '552',
          y: 39.11429334328386
        },
        {
          frame: '558',
          y: 38.30887086990652
        },
        {
          frame: '564',
          y: 36.358970271754934
        },
        {
          frame: '570',
          y: 31.27639772963466
        },
        {
          frame: '576',
          y: 22.05761345908489
        },
        {
          frame: '582',
          y: 7.7605605163094875
        },
        {
          frame: '588',
          y: 3.149224720400929
        },
        {
          frame: '594',
          y: 7.033032112342527
        },
        {
          frame: '600',
          y: 8.422735018520694
        },
        {
          frame: '606',
          y: 8.91728087610549
        },
        {
          frame: '612',
          y: 9.081176443554343
        },
        {
          frame: '618',
          y: 9.295034152915358
        },
        {
          frame: '624',
          y: 9.587104423834043
        },
        {
          frame: '630',
          y: 12.932179092077158
        },
        {
          frame: '636',
          y: 14.142312674404527
        },
        {
          frame: '642',
          y: 14.818708099100055
        },
        {
          frame: '648',
          y: 14.384158077869575
        },
        {
          frame: '654',
          y: 14.120073307228015
        },
        {
          frame: '660',
          y: 12.091612975576336
        },
        {
          frame: '666',
          y: 8.474225271456268
        },
        {
          frame: '672',
          y: 7.578012368824871
        },
        {
          frame: '678',
          y: 7.675606957757219
        },
        {
          frame: '684',
          y: 7.319656237641945
        },
        {
          frame: '690',
          y: 8.007408036460596
        },
        {
          frame: '696',
          y: 10.879362211042753
        },
        {
          frame: '702',
          y: 14.771053484907497
        },
        {
          frame: '708',
          y: 16.139680784646387
        },
        {
          frame: '714',
          y: 15.982753159815777
        },
        {
          frame: '720',
          y: 16.090493021681787
        }
      ],
      right_knee_angle: [
        {
          frame: '0',
          y: 0
        },
        {
          frame: '6',
          y: 0
        },
        {
          frame: '12',
          y: 0
        },
        {
          frame: '18',
          y: 0
        },
        {
          frame: '24',
          y: 2.562558733123139
        },
        {
          frame: '30',
          y: 0
        },
        {
          frame: '36',
          y: 0
        },
        {
          frame: '42',
          y: 0
        },
        {
          frame: '48',
          y: 0
        },
        {
          frame: '54',
          y: 2.562558733123139
        },
        {
          frame: '60',
          y: 2.562558733123139
        },
        {
          frame: '66',
          y: 2.562558733123139
        },
        {
          frame: '72',
          y: 2.562558733123139
        },
        {
          frame: '78',
          y: 2.562558733123139
        },
        {
          frame: '84',
          y: 2.562558733123139
        },
        {
          frame: '90',
          y: 2.562558733123139
        },
        {
          frame: '96',
          y: 2.562558733123139
        },
        {
          frame: '102',
          y: 5.731967965197727
        },
        {
          frame: '108',
          y: 9.24870479102892
        },
        {
          frame: '114',
          y: 12.312251505818914
        },
        {
          frame: '120',
          y: 14.983571086171084
        },
        {
          frame: '126',
          y: 15.420570792990242
        },
        {
          frame: '132',
          y: 14.53374690108497
        },
        {
          frame: '138',
          y: 14.303648721630555
        },
        {
          frame: '144',
          y: 14.983571086171084
        },
        {
          frame: '150',
          y: 19.265464565394517
        },
        {
          frame: '156',
          y: 32.11314324865789
        },
        {
          frame: '162',
          y: 48.3943462160835
        },
        {
          frame: '168',
          y: 43.19737408836376
        },
        {
          frame: '174',
          y: 25.04169519299524
        },
        {
          frame: '180',
          y: 14.53374690108497
        },
        {
          frame: '186',
          y: 14.069867747572134
        },
        {
          frame: '192',
          y: 15.203604093293013
        },
        {
          frame: '198',
          y: 15.845807549750827
        },
        {
          frame: '204',
          y: 15.203604093293013
        },
        {
          frame: '210',
          y: 14.303648721630555
        },
        {
          frame: '216',
          y: 14.760334581469492
        },
        {
          frame: '222',
          y: 15.420570792990242
        },
        {
          frame: '228',
          y: 14.983571086171084
        },
        {
          frame: '234',
          y: 14.53374690108497
        },
        {
          frame: '240',
          y: 14.53374690108497
        },
        {
          frame: '246',
          y: 14.069867747572134
        },
        {
          frame: '252',
          y: 14.069867747572134
        },
        {
          frame: '258',
          y: 14.069867747572134
        },
        {
          frame: '264',
          y: 13.832217183463564
        },
        {
          frame: '270',
          y: 13.832217183463564
        },
        {
          frame: '276',
          y: 13.590493966805916
        },
        {
          frame: '282',
          y: 13.832217183463564
        },
        {
          frame: '288',
          y: 13.590493966805916
        },
        {
          frame: '294',
          y: 13.093923320570502
        },
        {
          frame: '300',
          y: 12.838568140984057
        },
        {
          frame: '306',
          y: 12.57811865578259
        },
        {
          frame: '312',
          y: 12.312251505818914
        },
        {
          frame: '318',
          y: 12.040607741165621
        },
        {
          frame: '324',
          y: 12.040607741165621
        },
        {
          frame: '330',
          y: 10.579844096122503
        },
        {
          frame: '336',
          y: 7.692812451559879
        },
        {
          frame: '342',
          y: 6.279580641097028
        },
        {
          frame: '348',
          y: 8.50614695347707
        },
        {
          frame: '354',
          y: 9.24870479102892
        },
        {
          frame: '360',
          y: 8.109614455994183
        },
        {
          frame: '366',
          y: 8.109614455994183
        },
        {
          frame: '372',
          y: 8.109614455994183
        },
        {
          frame: '378',
          y: 9.24870479102892
        },
        {
          frame: '384',
          y: 10.579844096122503
        },
        {
          frame: '390',
          y: 16.05430807427423
        },
        {
          frame: '396',
          y: 12.838568140984057
        },
        {
          frame: '402',
          y: 6.279580641097028
        },
        {
          frame: '408',
          y: 3.6243074940079505
        },
        {
          frame: '414',
          y: 0
        },
        {
          frame: '420',
          y: 7.252246865059431
        },
        {
          frame: '426',
          y: 9.936367072140726
        },
        {
          frame: '432',
          y: 9.936367072140726
        },
        {
          frame: '438',
          y: 8.885124270228085
        },
        {
          frame: '444',
          y: 6.7832889062333575
        },
        {
          frame: '450',
          y: 6.279580641097028
        },
        {
          frame: '456',
          y: 2.562558733123139
        },
        {
          frame: '462',
          y: 6.7832889062333575
        },
        {
          frame: '468',
          y: 4.439222274842884
        },
        {
          frame: '474',
          y: 5.731967965197727
        },
        {
          frame: '480',
          y: 6.279580641097028
        },
        {
          frame: '486',
          y: 13.832217183463564
        },
        {
          frame: '492',
          y: 34.411508669769454
        },
        {
          frame: '498',
          y: 46.76439285101781
        },
        {
          frame: '504',
          y: 46.99987152372986
        },
        {
          frame: '510',
          y: 26.6197295443291
        },
        {
          frame: '516',
          y: 8.50614695347707
        },
        {
          frame: '522',
          y: 2.562558733123139
        },
        {
          frame: '528',
          y: 2.562558733123139
        },
        {
          frame: '534',
          y: 3.6243074940079505
        },
        {
          frame: '540',
          y: 2.562558733123139
        },
        {
          frame: '546',
          y: 0
        },
        {
          frame: '552',
          y: 0
        },
        {
          frame: '558',
          y: 2.562558733123139
        },
        {
          frame: '564',
          y: 3.6243074940079505
        },
        {
          frame: '570',
          y: 2.562558733123139
        },
        {
          frame: '576',
          y: 5.126400081947713
        },
        {
          frame: '582',
          y: 8.50614695347707
        },
        {
          frame: '588',
          y: 7.252246865059431
        },
        {
          frame: '594',
          y: 2.562558733123139
        },
        {
          frame: '600',
          y: 2.562558733123139
        },
        {
          frame: '606',
          y: 4.439222274842884
        },
        {
          frame: '612',
          y: 4.439222274842884
        },
        {
          frame: '618',
          y: 5.126400081947713
        },
        {
          frame: '624',
          y: 7.252246865059431
        },
        {
          frame: '630',
          y: 8.109614455994183
        },
        {
          frame: '636',
          y: 9.24870479102892
        },
        {
          frame: '642',
          y: 8.885124270228085
        },
        {
          frame: '648',
          y: 7.252246865059431
        },
        {
          frame: '654',
          y: 8.50614695347707
        },
        {
          frame: '660',
          y: 5.126400081947713
        },
        {
          frame: '666',
          y: 5.731967965197727
        },
        {
          frame: '672',
          y: 5.731967965197727
        },
        {
          frame: '678',
          y: 4.439222274842884
        },
        {
          frame: '684',
          y: 8.109614455994183
        },
        {
          frame: '690',
          y: 13.832217183463564
        },
        {
          frame: '696',
          y: 23.07391806563097
        },
        {
          frame: '702',
          y: 8.885124270228085
        },
        {
          frame: '708',
          y: 0
        },
        {
          frame: '714',
          y: 11.76278708549415
        },
        {
          frame: '720',
          y: 16.463595202270298
        }
      ],
      left_knee_angle: [
        {
          frame: '0',
          y: 4.439222274842884
        },
        {
          frame: '6',
          y: 3.6243074940079505
        },
        {
          frame: '12',
          y: 3.6243074940079505
        },
        {
          frame: '18',
          y: 3.6243074940079505
        },
        {
          frame: '24',
          y: 2.562558733123139
        },
        {
          frame: '30',
          y: 4.439222274842884
        },
        {
          frame: '36',
          y: 5.126400081947713
        },
        {
          frame: '42',
          y: 4.439222274842884
        },
        {
          frame: '48',
          y: 4.439222274842884
        },
        {
          frame: '54',
          y: 2.562558733123139
        },
        {
          frame: '60',
          y: 2.562558733123139
        },
        {
          frame: '66',
          y: 3.6243074940079505
        },
        {
          frame: '72',
          y: 2.562558733123139
        },
        {
          frame: '78',
          y: 3.6243074940079505
        },
        {
          frame: '84',
          y: 5.126400081947713
        },
        {
          frame: '90',
          y: 6.279580641097028
        },
        {
          frame: '96',
          y: 8.109614455994183
        },
        {
          frame: '102',
          y: 11.186763880959965
        },
        {
          frame: '108',
          y: 17.445987705778013
        },
        {
          frame: '114',
          y: 21.565185015242673
        },
        {
          frame: '120',
          y: 15.634598901019489
        },
        {
          frame: '126',
          y: 5.731967965197727
        },
        {
          frame: '132',
          y: 0
        },
        {
          frame: '138',
          y: 2.562558733123139
        },
        {
          frame: '144',
          y: 0
        },
        {
          frame: '150',
          y: 2.562558733123139
        },
        {
          frame: '156',
          y: 3.6243074940079505
        },
        {
          frame: '162',
          y: 5.126400081947713
        },
        {
          frame: '168',
          y: 6.7832889062333575
        },
        {
          frame: '174',
          y: 4.439222274842884
        },
        {
          frame: '180',
          y: 4.439222274842884
        },
        {
          frame: '186',
          y: 7.692812451559879
        },
        {
          frame: '192',
          y: 8.50614695347707
        },
        {
          frame: '198',
          y: 8.50614695347707
        },
        {
          frame: '204',
          y: 9.24870479102892
        },
        {
          frame: '210',
          y: 8.885124270228085
        },
        {
          frame: '216',
          y: 9.24870479102892
        },
        {
          frame: '222',
          y: 9.59863838343998
        },
        {
          frame: '228',
          y: 9.24870479102892
        },
        {
          frame: '234',
          y: 8.885124270228085
        },
        {
          frame: '240',
          y: 8.50614695347707
        },
        {
          frame: '246',
          y: 8.50614695347707
        },
        {
          frame: '252',
          y: 8.885124270228085
        },
        {
          frame: '258',
          y: 8.885124270228085
        },
        {
          frame: '264',
          y: 8.885124270228085
        },
        {
          frame: '270',
          y: 8.885124270228085
        },
        {
          frame: '276',
          y: 8.50614695347707
        },
        {
          frame: '282',
          y: 8.109614455994183
        },
        {
          frame: '288',
          y: 8.109614455994183
        },
        {
          frame: '294',
          y: 8.50614695347707
        },
        {
          frame: '300',
          y: 8.50614695347707
        },
        {
          frame: '306',
          y: 8.50614695347707
        },
        {
          frame: '312',
          y: 8.885124270228085
        },
        {
          frame: '318',
          y: 8.109614455994183
        },
        {
          frame: '324',
          y: 7.252246865059431
        },
        {
          frame: '330',
          y: 7.692812451559879
        },
        {
          frame: '336',
          y: 8.885124270228085
        },
        {
          frame: '342',
          y: 9.59863838343998
        },
        {
          frame: '348',
          y: 8.885124270228085
        },
        {
          frame: '354',
          y: 5.731967965197727
        },
        {
          frame: '360',
          y: 5.126400081947713
        },
        {
          frame: '366',
          y: 7.252246865059431
        },
        {
          frame: '372',
          y: 7.692812451559879
        },
        {
          frame: '378',
          y: 6.279580641097028
        },
        {
          frame: '384',
          y: 5.126400081947713
        },
        {
          frame: '390',
          y: 6.279580641097028
        },
        {
          frame: '396',
          y: 10.579844096122503
        },
        {
          frame: '402',
          y: 7.692812451559879
        },
        {
          frame: '408',
          y: 4.439222274842884
        },
        {
          frame: '414',
          y: 13.093923320570502
        },
        {
          frame: '420',
          y: 12.040607741165621
        },
        {
          frame: '426',
          y: 12.312251505818914
        },
        {
          frame: '432',
          y: 9.24870479102892
        },
        {
          frame: '438',
          y: 7.692812451559879
        },
        {
          frame: '444',
          y: 6.279580641097028
        },
        {
          frame: '450',
          y: 3.6243074940079505
        },
        {
          frame: '456',
          y: 12.040607741165621
        },
        {
          frame: '462',
          y: 19.948443588802704
        },
        {
          frame: '468',
          y: 34.10616554426597
        },
        {
          frame: '474',
          y: 26.103591118375956
        },
        {
          frame: '480',
          y: 11.76278708549415
        },
        {
          frame: '486',
          y: 12.312251505818914
        },
        {
          frame: '492',
          y: 15.845807549750827
        },
        {
          frame: '498',
          y: 21.4087629292759
        },
        {
          frame: '504',
          y: 18.558353699443757
        },
        {
          frame: '510',
          y: 12.040607741165621
        },
        {
          frame: '516',
          y: 9.59863838343998
        },
        {
          frame: '522',
          y: 12.312251505818914
        },
        {
          frame: '528',
          y: 12.57811865578259
        },
        {
          frame: '534',
          y: 11.478340954533579
        },
        {
          frame: '540',
          y: 10.579844096122503
        },
        {
          frame: '546',
          y: 10.579844096122503
        },
        {
          frame: '552',
          y: 10.579844096122503
        },
        {
          frame: '558',
          y: 11.478340954533579
        },
        {
          frame: '564',
          y: 11.76278708549415
        },
        {
          frame: '570',
          y: 8.885124270228085
        },
        {
          frame: '576',
          y: 8.50614695347707
        },
        {
          frame: '582',
          y: 3.6243074940079505
        },
        {
          frame: '588',
          y: 3.6243074940079505
        },
        {
          frame: '594',
          y: 3.6243074940079505
        },
        {
          frame: '600',
          y: 3.6243074940079505
        },
        {
          frame: '606',
          y: 4.439222274842884
        },
        {
          frame: '612',
          y: 5.731967965197727
        },
        {
          frame: '618',
          y: 6.7832889062333575
        },
        {
          frame: '624',
          y: 6.7832889062333575
        },
        {
          frame: '630',
          y: 8.50614695347707
        },
        {
          frame: '636',
          y: 9.24870479102892
        },
        {
          frame: '642',
          y: 9.936367072140726
        },
        {
          frame: '648',
          y: 9.24870479102892
        },
        {
          frame: '654',
          y: 8.109614455994183
        },
        {
          frame: '660',
          y: 7.692812451559879
        },
        {
          frame: '666',
          y: 6.279580641097028
        },
        {
          frame: '672',
          y: 6.7832889062333575
        },
        {
          frame: '678',
          y: 9.24870479102892
        },
        {
          frame: '684',
          y: 9.24870479102892
        },
        {
          frame: '690',
          y: 10.579844096122503
        },
        {
          frame: '696',
          y: 13.590493966805916
        },
        {
          frame: '702',
          y: 17.05962125685158
        },
        {
          frame: '708',
          y: 17.253853117357284
        },
        {
          frame: '714',
          y: 17.253853117357284
        },
        {
          frame: '720',
          y: 17.824235824643832
        }
      ],
      left_wrist_angle: [
        {
          frame: '0',
          y: 17.41060030796812
        },
        {
          frame: '6',
          y: 17.114339466261782
        },
        {
          frame: '12',
          y: 17.767577135729844
        },
        {
          frame: '18',
          y: 18.178337995422098
        },
        {
          frame: '24',
          y: 17.957912738200395
        },
        {
          frame: '30',
          y: 18.234403196307145
        },
        {
          frame: '36',
          y: 18.619723046361663
        },
        {
          frame: '42',
          y: 18.48132529180384
        },
        {
          frame: '48',
          y: 18.45933492787256
        },
        {
          frame: '54',
          y: 18.548758803959295
        },
        {
          frame: '60',
          y: 18.291127991341433
        },
        {
          frame: '66',
          y: 17.904822677476574
        },
        {
          frame: '72',
          y: 17.66437609010345
        },
        {
          frame: '78',
          y: 17.67341342497757
        },
        {
          frame: '84',
          y: 17.506120069516943
        },
        {
          frame: '90',
          y: 17.48393315063085
        },
        {
          frame: '96',
          y: 17.20818767863695
        },
        {
          frame: '102',
          y: 17.667992809666284
        },
        {
          frame: '108',
          y: 17.38959585176497
        },
        {
          frame: '114',
          y: 16.692173888052213
        },
        {
          frame: '120',
          y: 17.858016027820614
        },
        {
          frame: '126',
          y: 17.773097527954796
        },
        {
          frame: '132',
          y: 18.30349079609332
        },
        {
          frame: '138',
          y: 18.121390933534965
        },
        {
          frame: '144',
          y: 19.4737351467129
        },
        {
          frame: '150',
          y: 20.887494962585503
        },
        {
          frame: '156',
          y: 21.034626291756336
        },
        {
          frame: '162',
          y: 18.90748726900805
        },
        {
          frame: '168',
          y: 17.702531832164148
        },
        {
          frame: '174',
          y: 17.19679836387414
        },
        {
          frame: '180',
          y: 17.272145752976847
        },
        {
          frame: '186',
          y: 17.038287028581617
        },
        {
          frame: '192',
          y: 17.74656979764221
        },
        {
          frame: '198',
          y: 16.864478732387667
        },
        {
          frame: '204',
          y: 15.129862313512003
        },
        {
          frame: '210',
          y: 14.913561280663526
        },
        {
          frame: '216',
          y: 14.054351635703387
        },
        {
          frame: '222',
          y: 13.096447544458739
        },
        {
          frame: '228',
          y: 13.323673434472635
        },
        {
          frame: '234',
          y: 13.150987299120528
        },
        {
          frame: '240',
          y: 13.522115259544425
        },
        {
          frame: '246',
          y: 13.059412469626706
        },
        {
          frame: '252',
          y: 13.057067960820994
        },
        {
          frame: '258',
          y: 13.308974717239254
        },
        {
          frame: '264',
          y: 13.258812797463637
        },
        {
          frame: '270',
          y: 12.712257151954844
        },
        {
          frame: '276',
          y: 12.749130119127495
        },
        {
          frame: '282',
          y: 13.479613491359215
        },
        {
          frame: '288',
          y: 14.8967006894081
        },
        {
          frame: '294',
          y: 16.605362480323855
        },
        {
          frame: '300',
          y: 18.85247319722404
        },
        {
          frame: '306',
          y: 20.64900921795468
        },
        {
          frame: '312',
          y: 21.56209324111453
        },
        {
          frame: '318',
          y: 21.936023195515045
        },
        {
          frame: '324',
          y: 20.60343289691578
        },
        {
          frame: '330',
          y: 21.228197587949385
        },
        {
          frame: '336',
          y: 21.04069540394574
        },
        {
          frame: '342',
          y: 17.620108115209426
        },
        {
          frame: '348',
          y: 14.91542629146543
        },
        {
          frame: '354',
          y: 16.617943878596698
        },
        {
          frame: '360',
          y: 18.03256933169965
        },
        {
          frame: '366',
          y: 17.1560727998224
        },
        {
          frame: '372',
          y: 14.600273656549
        },
        {
          frame: '378',
          y: 13.702196333490786
        },
        {
          frame: '384',
          y: 14.021141666576316
        },
        {
          frame: '390',
          y: 15.407277469948355
        },
        {
          frame: '396',
          y: 16.172524277005465
        },
        {
          frame: '402',
          y: 22.58926099656472
        },
        {
          frame: '408',
          y: 29.531083181522376
        },
        {
          frame: '414',
          y: 37.964855755669376
        },
        {
          frame: '420',
          y: 22.963761250613544
        },
        {
          frame: '426',
          y: 26.33571296496305
        },
        {
          frame: '432',
          y: 22.607414055840284
        },
        {
          frame: '438',
          y: 24.881963315838107
        },
        {
          frame: '444',
          y: 33.29574982060274
        },
        {
          frame: '450',
          y: 29.511927436606204
        },
        {
          frame: '456',
          y: 21.09475881570381
        },
        {
          frame: '462',
          y: 18.4361089397522
        },
        {
          frame: '468',
          y: 14.382891732565989
        },
        {
          frame: '474',
          y: 13.234776447136664
        },
        {
          frame: '480',
          y: 12.609148148841419
        },
        {
          frame: '486',
          y: 13.957283664229358
        },
        {
          frame: '492',
          y: 13.755153575599302
        },
        {
          frame: '498',
          y: 16.146872507363494
        },
        {
          frame: '504',
          y: 17.498188467846685
        },
        {
          frame: '510',
          y: 20.128263727351097
        },
        {
          frame: '516',
          y: 19.21461204356345
        },
        {
          frame: '522',
          y: 17.884302347848905
        },
        {
          frame: '528',
          y: 18.01269799260731
        },
        {
          frame: '534',
          y: 18.129093823717106
        },
        {
          frame: '540',
          y: 17.89881811502303
        },
        {
          frame: '546',
          y: 17.601280575886914
        },
        {
          frame: '552',
          y: 18.019782545946725
        },
        {
          frame: '558',
          y: 18.017838047192154
        },
        {
          frame: '564',
          y: 18.722125917046807
        },
        {
          frame: '570',
          y: 19.64777428650108
        },
        {
          frame: '576',
          y: 22.342408122830147
        },
        {
          frame: '582',
          y: 23.20724614518429
        },
        {
          frame: '588',
          y: 21.087236644357652
        },
        {
          frame: '594',
          y: 19.88715154044132
        },
        {
          frame: '600',
          y: 20.111279491457523
        },
        {
          frame: '606',
          y: 21.232853673016557
        },
        {
          frame: '612',
          y: 21.689690598755266
        },
        {
          frame: '618',
          y: 20.24286668380254
        },
        {
          frame: '624',
          y: 19.290590234498403
        },
        {
          frame: '630',
          y: 21.45584779051244
        },
        {
          frame: '636',
          y: 21.07723216409948
        },
        {
          frame: '642',
          y: 20.35157264205429
        },
        {
          frame: '648',
          y: 21.02851377187805
        },
        {
          frame: '654',
          y: 21.27393987325157
        },
        {
          frame: '660',
          y: 20.101811846429342
        },
        {
          frame: '666',
          y: 19.859496273280193
        },
        {
          frame: '672',
          y: 20.64521073493368
        },
        {
          frame: '678',
          y: 21.35402368849616
        },
        {
          frame: '684',
          y: 20.893728992267995
        },
        {
          frame: '690',
          y: 17.98882936928684
        },
        {
          frame: '696',
          y: 14.942098947790646
        },
        {
          frame: '702',
          y: 15.641731474533257
        },
        {
          frame: '708',
          y: 16.39096026633309
        },
        {
          frame: '714',
          y: 17.629508644992796
        },
        {
          frame: '720',
          y: 18.717125111754072
        }
      ],
      right_wrist_angle: [
        {
          frame: '0',
          y: 25.258198967459403
        },
        {
          frame: '6',
          y: 24.64679966253287
        },
        {
          frame: '12',
          y: 24.570132750887
        },
        {
          frame: '18',
          y: 24.587759367588284
        },
        {
          frame: '24',
          y: 24.053642152305834
        },
        {
          frame: '30',
          y: 23.09709563024864
        },
        {
          frame: '36',
          y: 23.07621838011725
        },
        {
          frame: '42',
          y: 23.571524273200552
        },
        {
          frame: '48',
          y: 24.317121854111324
        },
        {
          frame: '54',
          y: 25.056728814737106
        },
        {
          frame: '60',
          y: 25.033293598852524
        },
        {
          frame: '66',
          y: 24.50878155458065
        },
        {
          frame: '72',
          y: 24.6014942043707
        },
        {
          frame: '78',
          y: 24.447356804126827
        },
        {
          frame: '84',
          y: 24.57419499594264
        },
        {
          frame: '90',
          y: 24.505506502026602
        },
        {
          frame: '96',
          y: 22.63869454887624
        },
        {
          frame: '102',
          y: 20.960573884485527
        },
        {
          frame: '108',
          y: 20.175893190442743
        },
        {
          frame: '114',
          y: 19.24238688429113
        },
        {
          frame: '120',
          y: 19.146140309574097
        },
        {
          frame: '126',
          y: 18.978085857901878
        },
        {
          frame: '132',
          y: 19.397214401121598
        },
        {
          frame: '138',
          y: 19.559305656971222
        },
        {
          frame: '144',
          y: 19.8974592923656
        },
        {
          frame: '150',
          y: 22.001382977276496
        },
        {
          frame: '156',
          y: 22.087004837739272
        },
        {
          frame: '162',
          y: 20.283221433598225
        },
        {
          frame: '168',
          y: 19.272804083017625
        },
        {
          frame: '174',
          y: 18.648218383986773
        },
        {
          frame: '180',
          y: 17.576552517053802
        },
        {
          frame: '186',
          y: 17.46950130326803
        },
        {
          frame: '192',
          y: 16.267728504963017
        },
        {
          frame: '198',
          y: 17.05291358815638
        },
        {
          frame: '204',
          y: 17.087445547625368
        },
        {
          frame: '210',
          y: 17.459168070271687
        },
        {
          frame: '216',
          y: 17.773620406998546
        },
        {
          frame: '222',
          y: 18.246911325310432
        },
        {
          frame: '228',
          y: 18.164000380143705
        },
        {
          frame: '234',
          y: 17.93006764816474
        },
        {
          frame: '240',
          y: 17.79861362042046
        },
        {
          frame: '246',
          y: 17.985139171910145
        },
        {
          frame: '252',
          y: 18.42497225463064
        },
        {
          frame: '258',
          y: 18.236114982440036
        },
        {
          frame: '264',
          y: 18.19177541322542
        },
        {
          frame: '270',
          y: 17.893312085668747
        },
        {
          frame: '276',
          y: 17.324889920587896
        },
        {
          frame: '282',
          y: 17.217441729804133
        },
        {
          frame: '288',
          y: 16.794458408032558
        },
        {
          frame: '294',
          y: 15.783866392683098
        },
        {
          frame: '300',
          y: 15.636257534821265
        },
        {
          frame: '306',
          y: 15.314898823872698
        },
        {
          frame: '312',
          y: 14.891815537316235
        },
        {
          frame: '318',
          y: 15.269413612307881
        },
        {
          frame: '324',
          y: 14.430190491552464
        },
        {
          frame: '330',
          y: 12.718574123844423
        },
        {
          frame: '336',
          y: 11.495189091119753
        },
        {
          frame: '342',
          y: 10.866016053543593
        },
        {
          frame: '348',
          y: 11.96111071019032
        },
        {
          frame: '354',
          y: 14.135505444786723
        },
        {
          frame: '360',
          y: 15.3437958216966
        },
        {
          frame: '366',
          y: 15.239219446003009
        },
        {
          frame: '372',
          y: 15.204297599348713
        },
        {
          frame: '378',
          y: 15.037849880747332
        },
        {
          frame: '384',
          y: 14.01722176975456
        },
        {
          frame: '390',
          y: 14.067594155311786
        },
        {
          frame: '396',
          y: 16.13493216506484
        },
        {
          frame: '402',
          y: 17.243160668579414
        },
        {
          frame: '408',
          y: 23.940606656566857
        },
        {
          frame: '414',
          y: 28.113777664809007
        },
        {
          frame: '420',
          y: 24.450048034137914
        },
        {
          frame: '426',
          y: 28.439332556754586
        },
        {
          frame: '432',
          y: 30.483722183622966
        },
        {
          frame: '438',
          y: 35.961055062450164
        },
        {
          frame: '444',
          y: 55.861696344056426
        },
        {
          frame: '450',
          y: 60.91834009083474
        },
        {
          frame: '456',
          y: 34.56376062416572
        },
        {
          frame: '462',
          y: 6.915751531360289
        },
        {
          frame: '468',
          y: 6.224700927798859
        },
        {
          frame: '474',
          y: 13.325376986949225
        },
        {
          frame: '480',
          y: 16.060860438984886
        },
        {
          frame: '486',
          y: 13.723895770474293
        },
        {
          frame: '492',
          y: 15.729414474755679
        },
        {
          frame: '498',
          y: 4.474336941802145
        },
        {
          frame: '504',
          y: 10.739921994583213
        },
        {
          frame: '510',
          y: 21.136068884703256
        },
        {
          frame: '516',
          y: 27.109943450368217
        },
        {
          frame: '522',
          y: 27.432862329541887
        },
        {
          frame: '528',
          y: 26.11567196000025
        },
        {
          frame: '534',
          y: 25.53007931696742
        },
        {
          frame: '540',
          y: 26.738825653166987
        },
        {
          frame: '546',
          y: 27.120688016526138
        },
        {
          frame: '552',
          y: 26.66234008811555
        },
        {
          frame: '558',
          y: 25.36136799980251
        },
        {
          frame: '564',
          y: 24.88523289915801
        },
        {
          frame: '570',
          y: 25.463504544371585
        },
        {
          frame: '576',
          y: 23.71100101987059
        },
        {
          frame: '582',
          y: 10.52556970474268
        },
        {
          frame: '588',
          y: 13.417639816470745
        },
        {
          frame: '594',
          y: 8.307825138550125
        },
        {
          frame: '600',
          y: 6.629179829105625
        },
        {
          frame: '606',
          y: 9.097830574177914
        },
        {
          frame: '612',
          y: 9.362010154716593
        },
        {
          frame: '618',
          y: 11.561557293604556
        },
        {
          frame: '624',
          y: 12.897237410900361
        },
        {
          frame: '630',
          y: 44.24678557168067
        },
        {
          frame: '636',
          y: 33.82297265332158
        },
        {
          frame: '642',
          y: 29.629867095320904
        },
        {
          frame: '648',
          y: 35.800646277967004
        },
        {
          frame: '654',
          y: 37.52841666639214
        },
        {
          frame: '660',
          y: 32.512243112792646
        },
        {
          frame: '666',
          y: 23.842284917906316
        },
        {
          frame: '672',
          y: 21.754311869653677
        },
        {
          frame: '678',
          y: 22.620620290372127
        },
        {
          frame: '684',
          y: 21.15869653507388
        },
        {
          frame: '690',
          y: 15.562716571659815
        },
        {
          frame: '696',
          y: 14.704427610657804
        },
        {
          frame: '702',
          y: 1.360649612567966
        },
        {
          frame: '708',
          y: 1.6495641268841292
        },
        {
          frame: '714',
          y: 23.775554125759616
        },
        {
          frame: '720',
          y: 29.81885725854531
        }
      ]
    },
    selectedJoint: 'left_upper_arm_angle',
    xLabels: [
      '0',
      '6',
      '12',
      '18',
      '24',
      '30',
      '36',
      '42',
      '48',
      '54',
      '60',
      '66',
      '72',
      '78',
      '84',
      '90',
      '96',
      '102',
      '108',
      '114',
      '120',
      '126',
      '132',
      '138',
      '144',
      '150',
      '156',
      '162',
      '168',
      '174',
      '180',
      '186',
      '192',
      '198',
      '204',
      '210',
      '216',
      '222',
      '228',
      '234',
      '240',
      '246',
      '252',
      '258',
      '264',
      '270',
      '276',
      '282',
      '288',
      '294',
      '300',
      '306',
      '312',
      '318',
      '324',
      '330',
      '336',
      '342',
      '348',
      '354',
      '360',
      '366',
      '372',
      '378',
      '384',
      '390',
      '396',
      '402',
      '408',
      '414',
      '420',
      '426',
      '432',
      '438',
      '444',
      '450',
      '456',
      '462',
      '468',
      '474',
      '480',
      '486',
      '492',
      '498',
      '504',
      '510',
      '516',
      '522',
      '528',
      '534',
      '540',
      '546',
      '552',
      '558',
      '564',
      '570',
      '576',
      '582',
      '588',
      '594',
      '600',
      '606',
      '612',
      '618',
      '624',
      '630',
      '636',
      '642',
      '648',
      '654',
      '660',
      '666',
      '672',
      '678',
      '684',
      '690',
      '696',
      '702',
      '708',
      '714',
      '720'
    ]
  }
}
