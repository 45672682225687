// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY || '',
  authDomain: 'kaizen-workflow.firebaseapp.com',
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID || '',
  storageBucket: 'kaizen-workflow.appspot.com',
  messagingSenderId: '550295434963',
  appId: import.meta.env.VITE_FIREBASE_APP_ID || '',
  measurementId: 'G-CKSVRNXWT3'
}

const app = initializeApp(firebaseConfig)
