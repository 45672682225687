<template>
  <a-alert
    v-if="errorMessage"
    type="error"
    :message="errorMessage"
    closable
    style="position: absolute; right: 1em"
  />

  <a-modal
    id="workRegions-confirm-modal"
    v-model:open="showConfirmModal"
    title="Confirmation"
    :cancel-button-props="{
      style: { marginLeft: 'auto' }
    }"
  >
    <p>This will reset all the previous regions. Do you want to proceed?</p>
    <template #footer>
      <a-space class="d-flex w-100">
        <a-button @click="showConfirmModal = false"> No </a-button>
        <a-button
          type="primary"
          :loading="resetLoading"
          @click="handleResetRegionCount(tempRegionsCount)"
        >
          Yes
        </a-button>
      </a-space>
    </template>
  </a-modal>

  <div v-if="loading && !isDemoOngoing" class="center p-2">
    <a-spin size="large" />
  </div>

  <div v-else class="py-2 px-4 multi-regions-container">
    <a-row id="sd-multipleRegionWorkArea" class="w-100" :gutter="[16, 16]">
      <a-col span="24" class="d-flex flex-column">
        <a-typography-text class="text-center">
          Please provide the (i) number of work regions, and for each region (ii) four corner
          points, (iii) dimensions of two sides, and (iv) its usual operator location.
        </a-typography-text>
      </a-col>

      <a-col span="13">
        <div class="content-wrapper">
          <div class="content-body">
            <div class="work-area-wrapper border">
              <div
                style="width: 640px"
                class="mb-2 d-flex align-items-center justify-content-between"
              >
                <a-space>
                  <a-tooltip title="Start Over">
                    <a-button
                      shape="circle"
                      class="center-btn"
                      :disabled="isNoObject || processesInProgress"
                      @click="handleClickStartOver"
                    >
                      <i class="bi-arrow-repeat" style="font-size: 16px"></i>
                    </a-button>
                  </a-tooltip>

                  <a-tooltip title="Redo">
                    <a-button
                      shape="circle"
                      class="center-btn mx-2"
                      :disabled="hasNoPointToRedo"
                      @click="handleClickRedo"
                    >
                      <i class="bi-arrow-90deg-left" style="font-size: 16px"></i>
                    </a-button>
                  </a-tooltip>

                  <a-tooltip title="Undo">
                    <a-button
                      shape="circle"
                      class="center-btn"
                      :disabled="disableUndo"
                      @click="handleClickUndo"
                    >
                      <i class="bi-arrow-90deg-right" style="font-size: 16px"></i>
                    </a-button>
                  </a-tooltip>
                </a-space>
              </div>

              <!-- Video Wrapper -->
              <div class="work-area" ref="videoWrapper">
                <video
                  crossorigin="anonymous"
                  disablePictureInPicture
                  muted
                  ref="video"
                  :src="!isDemoOngoing ? masterCycleFileUrl : stationDesignDummyObject.url"
                  @loadedmetadata="handleGetDuration"
                  @click="addPoint"
                  style="
                    height: 480px;
                    width: 640px;
                    object-fit: fill;
                    border-radius: 5px;
                    border: 1px solid lightgray;
                  "
                ></video>

                <!-- Work area points -->
                <div
                  v-if="!isDemoOngoing"
                  v-for="(region, key) in workAreaPoints"
                  :key="key"
                  :ref="key"
                >
                  <span
                    v-for="(point, id) in region"
                    :key="id"
                    :ref="id"
                    class="point"
                    :style="{ left: point.x + 'px', top: point.y + 'px' }"
                    @touchstart="handleMouseDown(id, 'workArea', key)"
                    @mousedown.prevent="handleMouseDown(id, 'workArea', key)"
                  >
                    <!-- <span class="text-white">{{ point.x + ', ' + point.y }}</span> -->
                  </span>
                </div>

                <div
                  v-else
                  v-for="(region, key) in stationDesignDummyObject.workAreaPoints"
                  :key="key + ''"
                  :ref="key"
                >
                  <span
                    v-for="(point, id) in region"
                    :key="id"
                    :ref="id"
                    class="point"
                    :style="{ left: point.x + 'px', top: point.y + 'px' }"
                  ></span>
                </div>

                <!-- Work area lines -->
                <div v-for="(region, key, regionNo) in regionLines" :key="key" :ref="region.name">
                  <div
                    v-for="line in region"
                    :key="line.id"
                    :id="line.id"
                    class="line"
                    :class="{
                      line_hover: isCell && regionNo > 0 ? false : true,
                      'selected-line': isLineSelected(line.id)
                    }"
                    :style="{
                      left: line.x <= 5 ? 5 : line.x + 'px',
                      top: line.y + 'px',
                      width: line.width + 'px',
                      background: line.color || 'black',
                      transform: `rotate(${line.angle}deg)`
                    }"
                    @click.prevent="handleSelectLine(line.id, key, regionNo)"
                  ></div>
                </div>

                <!-- Worker point -->
                <div
                  v-if="!isDemoOngoing"
                  v-for="(operator, key) in operatorMarker"
                  :key="key"
                  :ref="key"
                >
                  <div
                    class="operator_marker"
                    :style="{
                      left: operator.x + 'px',
                      top: operator.y + 'px'
                    }"
                    @mousedown.prevent="handleMarkerMouseDown(key)"
                    @touchstart.prevent="handleMarkerMouseDown(key)"
                    @mouseup="removeListeners"
                  >
                    <img
                      crossorigin="anonymous"
                      :src="workerTopViewIcon"
                      alt=""
                      width="30"
                      class="marker_icon"
                    />
                  </div>
                </div>

                <div
                  v-else
                  v-for="(operator, key) in stationDesignDummyObject.operatorMarker"
                  :key="key + '1'"
                  :ref="key"
                >
                  <div
                    class="operator_marker"
                    :style="{
                      left: operator.x + 'px',
                      top: operator.y + 'px'
                    }"
                  >
                    <img
                      crossorigin="anonymous"
                      :src="workerTopViewIcon"
                      alt=""
                      width="30"
                      class="marker_icon"
                    />
                  </div>
                </div>
              </div>
              <!-- Slider -->
              <div class="slider-wrapper" :style="{ width: stageConfig.width + 'px' }">
                <a-slider
                  class="w-100"
                  v-model:value="currentSliderVal"
                  :min="0"
                  :max="totalFrames"
                  :tipFormatter="(val) => format(val)"
                />
              </div>
            </div>
            <a-typography-text class="text-left" style="font-size: small">
              Use slider to seek the video.
            </a-typography-text>
          </div>
        </div>
      </a-col>

      <a-col span="11">
        <a-table
          bordered
          class="ant-table-striped border rounded work-regions-table"
          :dataSource="isDemoOngoing ? stationDesignDummyObject.workRegionsData : workRegionsData"
          :columns="tableColumns"
          :pagination="false"
          :scroll="{ x: true }"
        >
          <template #title>
            <a-space class="d-flex justify-content-between">
              <a-space style="display: flex">
                <a-typography-title class="m-0" :level="5"
                  >{{ isCell ? 'Work Regions:' : 'Work Areas:' }}
                </a-typography-title>
                <a-select
                  style="width: 120px"
                  placeholder="Select Regions"
                  :value="noOfWorkRegions"
                  :disabled="isDemoOngoing || processesInProgress"
                  @change="handleChangeWorkRegionCount"
                  :options="workRegionsOptions"
                />
              </a-space>

              <a-popover arrow hover placement="leftBottom">
                <template #content>
                  <div
                    style="max-width: 580px"
                    class="mb-2"
                    v-for="obj in Object.entries(outlineGuidelines)"
                    :key="obj[0]"
                  >
                    <strong>{{ obj[0] }}</strong>
                    <p style="margin-bottom: 4px" v-for="(text, i) in obj[1]" :key="'text-' + i">
                      &bull; {{ text }}
                    </p>
                  </div>
                </template>
                <span class="info-circle">
                  <InfoCircleOutlined class="text-primary" style="font-size: 20px" />
                </span>
              </a-popover>
            </a-space>
          </template>

          <template #headerCell="{ column }">
            <template v-if="column.dataIndex === 'lineDistance'">
              <span class="d-flex justify-content-center align-items-center">
                {{ column.title }}
                <a-popover arrow hover placement="bottom">
                  <template #content>
                    <div
                      style="max-width: 400px"
                      class="d-flex flex-column justify-content-center align-items-center"
                    >
                      <span>
                        Label two perpendicular dimensions for each work region (benchtop) by
                        clicking the corresponding line.
                      </span>
                      <img
                        crossorigin="anonymous"
                        :src="LineDimensionTooltipImg"
                        alt=""
                        width="250"
                      />
                    </div>
                  </template>
                  <span>
                    <InfoCircleOutlined
                      class="text-primary ms-1"
                      style="font-size: 16px; vertical-align: middle"
                    />
                  </span>
                </a-popover>
              </span>
            </template>
          </template>

          <template #bodyCell="{ record, column, index }">
            <span v-if="column.dataIndex === 'index'">{{ index + 1 }}</span>
            <span class="center-btn" v-if="column.dataIndex === 'isMarking'">
              <a-button
                shape="circle"
                class="center-btn"
                :disabled="isDemoOngoing"
                :type="record.isMarking ? 'primary' : 'default'"
                @click="handleInitMarkRegion(index)"
              >
                <EditOutlined />
              </a-button>
            </span>
            <span class="center-btn" v-if="column.dataIndex === 'loc_operator'">
              <a-button
                shape="circle"
                class="center-btn"
                :type="record.loc_operator ? 'primary' : 'default'"
                :disabled="
                  (workAreaPoints[record.key] &&
                    Object.keys(workAreaPoints[record.key])?.length !== 4) ||
                  isDemoOngoing
                "
                @click="handleInitMarkOperator(index)"
              >
                <UserOutlined />
              </a-button>
            </span>
            <span v-if="column.dataIndex === 'color'">
              <input
                type="color"
                :value="record.color"
                @change="(e) => handleColorChange(e, record)"
              />
            </span>
            <span v-if="column.dataIndex === 'width'">
              <a-input
                v-if="isCell ? index === 0 : true"
                placeholder="width"
                name="width"
                :value="record.width"
                :disabled="!isSelectedLineInRegion(record.name) || processesInProgress"
                @change="(e) => handleInputLineDistance(e, record.name, 0)"
              />
            </span>
            <span v-if="column.dataIndex === 'length'">
              <a-input
                v-if="isCell ? index === 0 : true"
                placeholder="length"
                name="length"
                :value="record.length"
                :disabled="!isSelectedLineInRegion(record.name) || processesInProgress"
                @change="(e) => handleInputLineDistance(e, record.name, 1)"
              />
            </span>
          </template>
        </a-table>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { uuid } from 'vue-uuid'
import { mapState, mapActions } from 'pinia'
import { useStationStore } from 'src/stores/station'
import { useUserStore } from 'src/stores/user'
import { useOperatorTrackingStore } from 'src/stores/operatorTracking'
import { getIntervalTime } from 'src/utils/outline'
import { stationOutlineGuidelines, cellOutlineGuidelines } from '../config'
import { useToast } from 'vue-toastification'
import { colors } from '../config'
import { UserOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons-vue'
import eventBus from 'src/utils/eventBus'
import workerTopViewIcon from 'src/assets/worker-top-view-icon.png'
import stationOutlineColumns, { cellOutlineColumns } from 'src/config/multi-regions-table-config'
import LineDimensionTooltipImg from 'src/assets/img/line-dimension-tooltip.png'

export default {
  components: {
    UserOutlined,
    EditOutlined,
    InfoCircleOutlined
  },
  inject: ['nextTab', 'showError'],
  setup() {
    return {
      toast: useToast(),
      workerTopViewIcon,
      LineDimensionTooltipImg
    }
  },

  data() {
    return {
      currentSliderVal: 0,
      stageConfig: { width: 640, height: 480 },
      recentPoints: [],
      isStarted: false,
      workAreaPoints: {}, // work area points
      pointToMove: null,
      videoDuration: 0,
      handlers: [],
      operatorMarker: {}, // operator state
      isMarkOperator: false,
      isAssemblyArea: false,
      errorMessage: '',
      loading: true,
      workRegionsData: [], // table variable
      selectedRegion: null,
      deleteRegionIds: new Set(),
      showConfirmModal: false,
      tempRegionsCount: null,
      prevIdToRegionsMap: null,
      regionsKeys: [],
      operatorUndoIndex: 0,
      recentSelectedLine: null,
      regionLines: {}, // assesmbly area lines
      scaleCoordinatesFields: ['width', 'widthLine', 'length', 'lengthLine'],
      resetLoading: false
    }
  },
  computed: {
    ...mapState(useUserStore, ['isDemoOngoing']),
    ...mapState(useStationStore, [
      'stationDesignDummyObject',
      'newStudy',
      'studyVideoUrl',
      'workRegionList',
      'noOfWorkRegions',
      'scaleCoordinate',
      'handTrackingStatus',
      'operatorTrackingStatus',
      'homographyStatus',
      'suggestionProcessInProgress',
      'masterCycleFileUrl',
      'studyFilesObject',
      'masterCycleVideoId',
      'isCell'
    ]),

    videoFPS() {
      if (!this.masterCycleVideoId || !this.studyFilesObject) return
      const fps = this.studyFilesObject[this.masterCycleVideoId]?.fps
      return fps || 30
    },

    tableColumns() {
      return this.isCell ? cellOutlineColumns : stationOutlineColumns
    },

    outlineGuidelines() {
      return this.isCell ? cellOutlineGuidelines : stationOutlineGuidelines
    },

    totalFrames() {
      return this.videoDuration ? this.videoFPS * this.videoDuration : 0
    },

    workRegionsOptions() {
      return [...Array(10)].map((_, i) => ({
        label: i + 1,
        value: i + 1
      }))
    },

    disableUndo() {
      const workerPointsLen = Object.keys(this.workAreaPoints).length
      return (
        !workerPointsLen ||
        (this.isAssemblyArea && workerPointsLen === 0) ||
        this.regionsKeys.length === 0
      )
    },

    isNoObject() {
      const workAreaPoints = Object.keys(this.workAreaPoints)
      return workAreaPoints.length === 0
    },

    hasNoPointToRedo() {
      return this.recentPoints.length === 0
    },

    isPolygonsClosed() {
      if (!this.workAreaPoints || !Object.keys(this.workAreaPoints)?.length) return false
      const regionPoints = Object.entries(this.workAreaPoints)
      return (
        regionPoints.length === this.workRegionsData.length &&
        regionPoints.every(([, obj]) => Object.keys(obj).length === 4)
      )
    },

    isRegionsCountUpdated() {
      if (!this.prevIdToRegionsMap || !Object.keys(this.prevIdToRegionsMap)?.length) return true
      const prevRegions = Object.values(this.prevIdToRegionsMap)
      const currRegions = Object.keys(this.regionLines)
      return prevRegions.length !== currRegions.length
    },

    isAllRegionLinesCompleted() {
      return this.workRegionsData.every((obj) =>
        this.scaleCoordinatesFields.every(
          (prop) =>
            Object.prototype.hasOwnProperty.call(obj, prop) &&
            obj[prop] !== '' &&
            obj[prop] !== null &&
            obj[prop] !== undefined
        )
      )
    },

    isCellRegionLinesMarked() {
      const firstRegion = this.workRegionsData?.[0]
      if (!firstRegion) return false
      return this.scaleCoordinatesFields.every(
        (prop) =>
          Object.prototype.hasOwnProperty.call(firstRegion, prop) &&
          firstRegion[prop] !== '' &&
          firstRegion[prop] !== null &&
          firstRegion[prop] !== undefined
      )
    },

    outlineCompleted() {
      const completed =
        this.isPolygonsClosed &&
        Object.keys(this.operatorMarker)?.length === this.workRegionsData?.length &&
        this.isAllRegionLinesCompleted

      const cellCompleted =
        this.isPolygonsClosed &&
        Object.keys(this.operatorMarker)?.length === this.workRegionsData?.length &&
        this.isCellRegionLinesMarked

      return this.isCell ? cellCompleted : completed
    },

    processesInProgress() {
      return this.suggestionProcessInProgress
    }
  },

  watch: {
    noOfWorkRegions(count, oldCount) {
      if (!count || (!oldCount && this.newStudy.station.no_of_work_regions)) return
      this.populateTableData()
    },

    currentSliderVal(frame) {
      if (this.$refs.video) this.$refs.video.currentTime = frame / this.videoFPS
    },

    workRegionList(regions) {
      if (!regions.length) return
      this.renderAnnotations()
    },

    outlineCompleted(completed) {
      this.setOutlineComplete(completed)
    }
  },

  methods: {
    ...mapActions(useStationStore, [
      'updateStudy',
      'fetchWorkRegions',
      'addWorkRegions',
      'updateWorkRegion',
      'deleteRegion',
      'setWorkRegionsCount',
      'setWorkRegionsList',
      'updateStation',
      'setRegionsLoading',
      'setOutlineComplete',
      'runImprovementSuggestions'
    ]),
    ...mapActions(useOperatorTrackingStore, ['startOperatorTracking']),

    renderAnnotations() {
      this.setRegionIdToObjectMap()
      this.setWorkAreaCords()
      this.setWorkerCords()
      this.updateRegionLines()
      this.setListDataInTable()
    },

    setRegionIdToObjectMap() {
      if (!this.workRegionList?.length) return
      this.prevIdToRegionsMap = this.workRegionList?.reduce((res, el) => {
        res[el.id] = el
        return res
      }, {})
    },

    handleChangeWorkRegionCount(val) {
      if (!this.workRegionList.length) this.setWorkRegionsCount(val)
      else {
        this.tempRegionsCount = val
        this.showConfirmModal = true
      }
    },

    async handleResetRegionCount(count) {
      this.resetLoading = true
      await this.handleClickStartOver()
      this.setWorkRegionsCount(count)
      this.resetLoading = false
      this.showConfirmModal = false
    },

    setListDataInTable() {
      if (!this.workRegionList?.length) return
      const obj = {
        color: '#000000',
        isMarking: false,
        loc_operator: false
      }
      this.workRegionsData = this.workRegionList.map((reg, i) => ({
        ...obj,
        key: i,
        id: reg.id,
        name: reg.name,
        color: reg.color || colors[i],
        width: reg.scale_cordinates.width?.line_distance || null,
        length: reg.scale_cordinates.length?.line_distance || null,
        widthLine: this.getSelectedLine(reg.scale_cordinates.width?.line),
        lengthLine: this.getSelectedLine(reg.scale_cordinates.length?.line),
        isMarking:
          reg['work_area_cordinates'] && Object.keys(reg['work_area_cordinates'])?.length
            ? true
            : false,
        loc_operator:
          reg['worker_cordinates'] && Object.keys(reg['worker_cordinates'])?.length ? true : false
      }))
    },

    populateTableData() {
      const obj = { color: '#000000', isMarking: false, loc_operator: false }
      const oldCount = this.workRegionsData.length
      if (oldCount == this.noOfWorkRegions) return
      const count = this.noOfWorkRegions - oldCount
      let data = []
      // when reduce work regions
      if (count < 0) {
        const remainig = [...this.workRegionsData.slice(count)]
        this.workRegionsData = this.workRegionsData.slice(0, count)
        remainig?.forEach((region) => {
          if (this.operatorMarker[region.name]) delete this.operatorMarker[region.name]
          if (this.workAreaPoints[region.name]) {
            delete this.workAreaPoints[region.name]
            this.updateRegionLines()
          }
        })
      } else {
        data = [...Array(count)].map((_, i) => ({
          ...obj,
          key: oldCount + i,
          name: uuid.v4(),
          color: colors[oldCount + i],
          width: null,
          length: null,
          widthLine: null,
          lengthLine: null
        }))
        this.workRegionsData = this.workRegionsData.concat(...data)
      }
    },

    isEqual(num1, num2, epsilon = 1e-7) {
      return Math.abs(num1 - num2) < epsilon
    },

    isUpdatedObject(value, newValue) {
      if (!value || !newValue) return false
      for (let key in value) {
        if (
          Object.prototype.hasOwnProperty.call(value, key) &&
          Object.prototype.hasOwnProperty.call(newValue, key)
        ) {
          if (!this.isEqual(value[key], newValue[key])) {
            return true
          }
        }
      }
      return false
    },

    updateRegionsData(index, updatedObj) {
      const temp = this.workRegionsData
      temp[index] = { ...temp[index], ...updatedObj }
      this.workRegionsData = temp
    },

    // formated time for slider
    format(val) {
      const time = val / this.videoFPS
      const formattedTime = getIntervalTime(time * 1000)
      return `${formattedTime}`
    },

    normalize(obj) {
      const { width: normX, height: normY } = this.stageConfig
      return {
        ...obj,
        x: obj.x / normX,
        y: obj.y / normY
      }
    },

    scale(obj) {
      const { width: normX, height: normY } = this.stageConfig
      return {
        ...obj,
        x: obj.x * normX,
        y: obj.y * normY
      }
    },

    handleGetDuration() {
      this.videoDuration = parseInt(this.$refs.video?.duration, 10)
      this.setWorkAreaCords()
      this.setWorkerCords()
    },

    getCoordinates(e) {
      if (e.touches)
        return {
          clientX: e.touches[0].clientX,
          clientY: e.touches[0].clientY
        }
      return { clientX: e.clientX, clientY: e.clientY }
    },

    getDistance(x1, y1, x2, y2) {
      return parseInt(Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2)), 10)
    },

    isPointOutOfBound(clientX, clientY, top, right, bottom, left) {
      return clientX > right - 2 || clientX - 2 < left || clientY - 2 < top || clientY > bottom - 2
    },

    isWorkerOutOfBound(clientX, clientY, top, right, bottom, left) {
      return (
        clientX > right - 21 || clientX - 8 < left || clientY - 5 < top || clientY > bottom - 16
      )
    },

    isFalseValue(value) {
      const falseValues = [null, undefined, '', '0', 0]
      return falseValues.includes(value) || value <= 0 || Number.isNaN(Number(value))
    },

    handleInitMarkRegion(index) {
      const currentWorkRegionData = this.workRegionsData[index]
      const inCompleteRegions = this.workRegionsData.filter((region) => {
        if (region.isMarking) {
          if (!this.workAreaPoints[region.name]) return true
          return Object.keys(this.workAreaPoints[region.name])?.length !== 4
        }
      })
      if (inCompleteRegions?.length) {
        return this.showError('Please complete marking the region first!')
      }

      const incompleteLineDimensions = this.workRegionsData.filter((region, regionNo) => {
        return this.isCell && regionNo > 0
          ? false
          : region?.isMarking && this.scaleCoordinatesFields.some((field) => !region[field])
      })
      if (incompleteLineDimensions?.length) {
        return this.showError(
          'Please complete adding both the line dimensions of the region first!'
        )
      }

      // checking for all regions
      if (!currentWorkRegionData || currentWorkRegionData?.isMarking) return
      this.selectedRegion = currentWorkRegionData.name
      currentWorkRegionData.isMarking = true
      // remove the old deleted points of the selected region (once redraw)
      this.handleRemoveRegionPreviousPoints()
      this.handleInitAssemblyArea()
    },

    handleInitAssemblyArea() {
      this.isStarted = true
      this.isAssemblyArea = true
    },

    handleInitMarkOperator(index) {
      const key = this.workRegionsData[index]?.name
      if (
        !this.workAreaPoints[key] ||
        (this.selectedRegion &&
          this.workAreaPoints[this.selectedRegion] &&
          Object.keys(this.workAreaPoints[this.selectedRegion])?.length !== 4) ||
        (this.workAreaPoints[key] && Object.keys(this.workAreaPoints[key])?.length !== 4)
      ) {
        return this.showError('Please complete marking assembly region first!')
      }
      if (!this.workRegionsData[index] || this.workRegionsData[index]?.loc_operator) return
      this.selectedRegion = key
      this.workRegionsData[index].loc_operator = true
      this.handleInitLocateOperator()
    },

    handleInitLocateOperator() {
      this.isAssemblyArea = false
      const { width, height } = this.stageConfig
      this.operatorMarker[this.selectedRegion] = { x: width / 2, y: height / 2 }
    },

    handleColorChange(e, record) {
      record.color = e.target.value
      this.updateRegionLines()
    },

    addPoint(e) {
      if (this.isAssemblyArea && this.selectedRegion) {
        // remove the old deleted points of the selected region (once redraw) -> old drawn points
        if (this.recentPoints.length) this.handleRemoveRegionPreviousPoints()
        this.addWorkAreaPoint(e)
      }
    },

    addWorkAreaPoint(e) {
      if (!this.masterCycleFileUrl || !this.isAssemblyArea) return
      if (!this.isStarted) return
      if (!this.selectedRegion) return
      const key = this.selectedRegion
      let { offsetX, offsetY } = e

      this.workAreaPoints[key] = {
        ...this.workAreaPoints[key],
        [uuid.v4()]: { x: offsetX, y: offsetY }
      }

      const region = this.workAreaPoints[key] || {}
      const numOfWorkAreaPoints = Object.keys(region).length
      if (numOfWorkAreaPoints === 4 && this.isAssemblyArea) {
        this.isStarted = false
        this.isAssemblyArea = false
        this.selectedRegion = null
        this.updateRegionLines()
        return
      }
      if (!this.regionsKeys.includes(key)) {
        this.regionsKeys.push(key)
      }
      this.updateRegionLines()
    },

    updateWorkAreaPoints(clientX, clientY, left, top) {
      const { pointId, region } = this.pointToMove
      const temp = { ...this.workAreaPoints }
      temp[region][pointId] = { x: clientX - left, y: clientY - top }
      this.workAreaPoints = temp
      this.isAssemblyArea = true
      // const workRegionData = this.workRegionsData.find((data) => data.name == region)
      // this.scaleCoordinatesFields.forEach((field) => {
      //   workRegionData[field] = null
      // })
      // this.recentSelectedLine = null
      this.updateRegionLines()
    },

    isLineSelected(line) {
      if (this.recentSelectedLine == line) return true
      const lineInData = this.workRegionsData.filter(
        (r) => r.widthLine == line || r.lengthLine == line
      )
      if (lineInData?.length) return true
      return false
    },

    updateRegionLines() {
      if (this.isDemoOngoing) return this.stationDesignDummyObject.regionLines

      if (!this.workAreaPoints || !Object.keys(this.workAreaPoints).length) return {}

      const lines = {}

      for (const region in this.workAreaPoints) {
        const points = this.workAreaPoints[region] ? Object.values(this.workAreaPoints[region]) : []
        if (points.length < 2) continue

        const workRegion = this.workRegionsData?.length
          ? this.workRegionsData.find((r) => r.name === region)
          : this.workRegionList.find((r) => r.name === region)

        const color = workRegion?.color ? workRegion?.color : '#000'

        const temp = []
        const prevRegion = this.regionLines[region]
        for (let i = 1; i < points.length; i++) {
          const { x, y } = points[i - 1]
          const { x: currX, y: currY } = points[i]
          const angle = parseInt((Math.atan2(y - currY, x - currX) * 180) / Math.PI + 180)
          const width = this.getDistance(x, y, currX, currY)

          let tempId =
            prevRegion?.length && prevRegion[i - 1]?.id ? prevRegion[i - 1].id : uuid.v4()

          temp.push({
            id: tempId,
            x,
            y,
            color,
            angle,
            width: width + 1,
            index: i
          })
        }

        if (points.length === 4) {
          const firstPoint = points.at(0)
          const lastPoint = points.at(-1)
          const _angle = parseInt(
            (Math.atan2(lastPoint.y - firstPoint.y, lastPoint.x - firstPoint.x) * 180) / Math.PI +
              180
          )
          const _width = this.getDistance(lastPoint.x, lastPoint.y, firstPoint.x, firstPoint.y)
          let tempId = prevRegion?.length && prevRegion[3]?.id ? prevRegion[3]?.id : uuid.v4()
          temp.push({
            color,
            id: tempId,
            x: lastPoint.x,
            y: lastPoint.y,
            width: _width + 1,
            angle: _angle,
            index: 4
          })
        }
        lines[region] = temp
      }
      this.regionLines = { ...lines }
    },

    async handleClickStartOver(deleteRegions = true) {
      if (deleteRegions) {
        await this.handleDeleteAllRegions()
      }
      this.isStarted = false
      this.isAssemblyArea = false
      this.isMarkOperator = false
      this.selectedRegion = null
      this.workRegionsData = []
      this.setWorkRegionsList([])
      this.workAreaPoints = {}
      this.operatorMarker = {}
      this.recentPoints = []
      this.regionsKeys = []
      this.prevIdToRegionsMap = {}
      this.deleteRegionIds = new Set()
      this.regionLines = {}
    },

    handleClickUndo() {
      if (!this.regionsKeys.length) return

      let key = 'workAreaPoints'
      const temp = { ...this[key] }
      let regionKey = this.regionsKeys.at(-1)
      if (!regionKey) return

      this.isStarted = true
      this.isAssemblyArea = true
      this.selectedRegion = regionKey

      const keyToRemove = Object.keys(temp[regionKey]).at(-1)
      if (!keyToRemove) return
      this.recentPoints.push([regionKey, temp[regionKey][keyToRemove]])
      delete temp[regionKey][keyToRemove]
      this[key] = temp
      this.updateRegionLines()

      const index = this.workRegionsData.findIndex((val) => val.name == regionKey)
      this.scaleCoordinatesFields.forEach((field) => {
        this.workRegionsData[index][field] = null
      })
      this.recentSelectedLine = null
      if (!Object.keys(temp[regionKey])?.length) {
        this.workRegionsData[index].isMarking = false
        this.isStarted = false
        this.isAssemblyArea = false
        this.selectedRegion = null
        this.regionsKeys.pop()
      }

      if (
        this.workRegionsData[index]?.loc_operator &&
        !Object.keys(temp[regionKey])?.length &&
        this.operatorMarker[regionKey]
      ) {
        this.operatorUndoIndex = this.recentPoints.findIndex((rp) => rp[0] === regionKey)
        this.recentPoints.splice(this.operatorUndoIndex, 0, [
          regionKey,
          this.operatorMarker[regionKey],
          true
        ])
        delete this.operatorMarker[regionKey]
        this.isMarkOperator = false
        this.workRegionsData[index].loc_operator = false
        this.selectedRegion = null
        return
      }
    },

    handleClickRedo() {
      const pointToReAdd = this.recentPoints.pop()
      if (!pointToReAdd) {
        this.isStarted = false
        return
      }

      const [region, { x, y }, isOperator = false] = pointToReAdd
      this.selectedRegion = region
      this.isStarted = true
      this.isAssemblyArea = true

      const regionPoints = this.workAreaPoints[region] || {}
      const numOfWorkAreaPoints = Object.keys(regionPoints).length
      const index = this.workRegionsData.findIndex((val) => val.name == region)
      this.scaleCoordinatesFields.forEach((field) => {
        this.workRegionsData[index][field] = null
      })
      this.recentSelectedLine = null
      if (numOfWorkAreaPoints > 0) {
        this.workRegionsData[index].isMarking = true
      }

      if (numOfWorkAreaPoints === 4) {
        if (isOperator) {
          this.operatorMarker[region] = { x, y }
          this.workRegionsData[index].loc_operator = true
          this.isStarted = false
          this.isAssemblyArea = false
        }
      } else {
        this.addWorkAreaPoint({ offsetX: x, offsetY: y })
      }
    },

    handleRemoveRegionPreviousPoints() {
      this.recentPoints = []
      return
    },

    getPoints(points) {
      points = Object.values(points)

      let topLeftPointIndex = -1
      let shortestDistance = Number.MAX_SAFE_INTEGER

      points.forEach((p, i) => {
        const distance = this.getDistance(p.x, p.y, 640, 0)
        if (distance < shortestDistance) {
          shortestDistance = distance
          topLeftPointIndex = i
        }
      })
      const pointsArr = [...points.slice(topLeftPointIndex), ...points.slice(0, topLeftPointIndex)]
      return pointsArr.map((p) => this.normalize(p))
    },

    isSelectedLineInRegion(regionId) {
      const lines = this.regionLines?.[regionId]
      if (!lines) {
        return false
      }
      if (this.recentSelectedLine)
        return lines?.filter((line) => line.id == this.recentSelectedLine)?.length
    },

    handleInputLineDistance(e, region, key) {
      const { value } = e.target
      e.target.value = ''

      if (!this.recentSelectedLine) return
      if (Number.isNaN(Number(value))) {
        e.target.value = ''
        return
      }

      const regionData = this.workRegionsData.find((r) => r.name === region)
      if (!regionData) return
      if (key) {
        if (regionData?.widthLine == this.recentSelectedLine) {
          regionData.length = ''
          this.showError('Please select second line')
          return
        }
      } else {
        if (regionData?.lengthLine == this.recentSelectedLine) {
          regionData.width = ''
          this.showError('Please select second line')
          return
        }
      }

      const selectedLine = value.length ? this.recentSelectedLine : null
      if (key) {
        regionData.length = value
        regionData.lengthLine = selectedLine
      } else {
        regionData.width = value
        regionData.widthLine = selectedLine
      }
    },

    getSelectedLineCoordinates(line, region) {
      const { width: normX, height: normY } = this.stageConfig
      const lines = [...Object.values(this.regionLines[region])].flat()
      const index = lines.findIndex((el) => el.id === line)
      if (index < 0) {
        return
      }
      return [lines[index], lines[index + 1] ? lines[index + 1] : lines[0]].map(({ x, y }) => ({
        x: x / normX,
        y: y / normY
      }))
    },

    getSelectedLine(line) {
      const lines = [...Object.values(this.regionLines)].flat()
      const { x, y } = line?.[0] || {}
      const { width, height } = this.stageConfig
      const temp = lines.find((el) => el.x === x * width && el.y === y * height)
      return temp?.id
    },

    doLinesSharePoint(line1, line2) {
      function arePointsEqual(point1, point2) {
        return point1.x === point2.x && point1.y === point2.y
      }
      for (let point1 of line1) {
        for (let point2 of line2) {
          if (arePointsEqual(point1, point2)) {
            return true
          }
        }
      }
      return false
    },

    handleSelectLine(lineId, regionKey, regionNo) {
      if (this.processesInProgress) return
      if (this.isCell && regionNo > 0) return

      const workRegionData = this.workRegionsData.find((data) => data.name == regionKey)
      if (workRegionData) {
        if (lineId == workRegionData.widthLine) {
          this.recentSelectedLine = null
          workRegionData.widthLine = null
          workRegionData.width = null
          return
        }
        if (lineId == workRegionData.lengthLine) {
          this.recentSelectedLine = null
          workRegionData.lengthLine = null
          workRegionData.length = null
          return
        }
        if (workRegionData.widthLine && workRegionData.lengthLine) return
      }

      if (this.recentSelectedLine === lineId) {
        this.recentSelectedLine = null
      } else {
        // if any one line is selected
        if (
          (!workRegionData?.widthLine && workRegionData?.lengthLine) ||
          (workRegionData?.widthLine && !workRegionData?.lengthLine)
        ) {
          const previousLine = workRegionData?.widthLine
            ? workRegionData?.widthLine
            : workRegionData?.lengthLine

          const areLinesPerpendicular = this.doLinesSharePoint(
            this.getSelectedLineCoordinates(previousLine, regionKey),
            this.getSelectedLineCoordinates(lineId, regionKey)
          )
          if (!areLinesPerpendicular) {
            this.showError('Lines should be perpendicular')
            return
          }
        }
        this.recentSelectedLine = lineId
      }
    },

    setHandlers() {
      this.handlers = [
        {
          event: 'mousemove',
          handler: this.handleMouseMove
        },
        {
          event: 'mouseup',
          handler: this.removeListeners
        },
        {
          event: 'touchmove',
          handler: this.handleMouseMove
        },
        {
          event: 'touchend',
          handler: this.removeListeners
        }
      ]
    },

    removeListeners() {
      this.handlers.forEach(({ event, handler }) => {
        document.documentElement.removeEventListener(event, handler)
      })
      if (this.isMarkOperator) this.isMarkOperator = false
      this.pointToMove = null
    },

    handleMouseDown(pointId, key, region) {
      if (this.processesInProgress) return
      this.pointToMove = { pointId, key, region }
      this.handlers.forEach(({ event, handler }) => {
        document.documentElement.addEventListener(event, handler)
      })
    },

    handleMouseMove(e) {
      const { clientX, clientY } = this.getCoordinates(e)

      const { top, right, bottom, left } = this.$refs.video.getBoundingClientRect()

      if (this.pointToMove) {
        if (this.isPointOutOfBound(clientX, clientY, top, right, bottom, left)) return
        if (this.pointToMove.key === 'workArea')
          this.updateWorkAreaPoints(clientX, clientY, left, top)
      } else if (this.isMarkOperator) {
        if (this.isWorkerOutOfBound(clientX, clientY, top, right, bottom, left)) return
        this.operatorMarker[this.selectedRegion] = { x: clientX - left - 10, y: clientY - top - 10 }
      }
    },

    validateRequirments() {
      const regionArray = this.isCell ? this.workRegionsData.slice(0, 1) : this.workRegionsData
      const requirements = [
        {
          condition: !this.isPolygonsClosed,
          errorMessage: 'Please complete outlining the work regions!'
        },
        {
          condition: this.isCell ? !this.isCellRegionLinesMarked : !this.isAllRegionLinesCompleted,
          errorMessage: 'Please complete all regions'
        },
        {
          condition: regionArray.some(
            (r) => this.isFalseValue(r.width) || this.isFalseValue(r.length)
          ),
          errorMessage: 'Width and length values are not defined or invalid!'
        },
        {
          condition: Object.keys(this.operatorMarker)?.length !== this.workRegionsData?.length,
          errorMessage: 'Please mark the operators location!'
        }
      ]

      const index = requirements.findIndex((req) => req.condition === true)
      if (index >= 0) {
        this.showError(requirements[index].errorMessage)
        return 0
      }
      return 1
    },

    async handleSaveAnnotations() {
      // todo: error handling
      this.setRegionsLoading(true)
      // Update
      const updatedPayload = this.getUpdatedRegions()
      if (updatedPayload?.length)
        await Promise.all(updatedPayload.map(({ id, ...obj }) => this.updateWorkRegion(id, obj)))
      // Add
      const payload = this.getCreatePayload()
      if (Object.keys(payload)?.length) {
        await this.addWorkRegions(payload)
      }
      // Update work regions count
      if (this.isRegionsCountUpdated) {
        await this.updateStation(this.newStudy?.station.id, {
          no_of_work_regions: Object.keys(this.regionLines)?.length
        })
      }
      this.setRegionsLoading(false)
      return 1
    },

    getCreatePayload() {
      let payload = {}
      const { workAreaPoints, operatorMarker, getPoints, normalize } = this

      this.workRegionsData.forEach((r, i) => {
        if (r.id) return
        const key = r.name
        const region = workAreaPoints[key]
        const obj = {
          color: r.color,
          work_area_cordinates: getPoints(region),
          worker_cordinates: normalize(operatorMarker[key]),
          scale_cordinates:
            this.isCell && i > 0
              ? {}
              : {
                  length: {
                    line: this.getSelectedLineCoordinates(r.lengthLine, r.name),
                    line_distance: r.length
                  },
                  width: {
                    line: this.getSelectedLineCoordinates(r.widthLine, r.name),
                    line_distance: r.width
                  }
                }
        }
        payload[i] = obj
      })
      return payload
    },

    getUpdatedRegions() {
      let updatePayload = []
      const { isUpdatedObject, workAreaPoints, operatorMarker, normalize } = this
      this.workRegionsData.forEach((r, regionNo) => {
        const oldObj = r.id ? this.prevIdToRegionsMap[r.id] : null
        if (!oldObj) return
        let obj = { id: r.id }
        const key = r.name
        let isLengthLineUpdated = false
        let isWidthLineUpdated = false
        const { work_area_cordinates, worker_cordinates, scale_cordinates } = oldObj
        const normAreaPoints = workAreaPoints[key].map((p) => normalize(p))

        const normOptPoints = normalize(operatorMarker[key])

        isLengthLineUpdated =
          this.isCell && regionNo > 0
            ? false
            : this.isUpdatedObject(
                scale_cordinates?.length?.line[0],
                this.getSelectedLineCoordinates(r.lengthLine, r.name)[0]
              )

        isWidthLineUpdated =
          this.isCell && regionNo > 0
            ? false
            : this.isUpdatedObject(
                scale_cordinates?.width?.line[0],
                this.getSelectedLineCoordinates(r.widthLine, r.name)[0]
              )

        if (
          (!work_area_cordinates.length && normAreaPoints.length) ||
          work_area_cordinates.some((p, ix) => isUpdatedObject(p, normAreaPoints[ix]))
        ) {
          obj['work_area_cordinates'] = normAreaPoints
        }

        if (
          (!Object.keys(worker_cordinates)?.length && Object.keys(normOptPoints)?.length) ||
          isUpdatedObject(worker_cordinates, normOptPoints)
        ) {
          obj['worker_cordinates'] = normOptPoints
        }

        // send complete json if any scale cordinates field updated
        if (
          this.isCell && regionNo > 0
            ? false
            : isLengthLineUpdated ||
              scale_cordinates?.length?.line_distance != Number(r.length) ||
              isWidthLineUpdated ||
              scale_cordinates?.width?.line_distance != Number(r.width)
        ) {
          if (!obj['scale_cordinates']) obj['scale_cordinates'] = {}
          obj['scale_cordinates']['length'] = {
            line: this.getSelectedLineCoordinates(r.lengthLine, r.name),
            line_distance: Number(r.length)
          }
          obj['scale_cordinates']['width'] = {
            line: this.getSelectedLineCoordinates(r.widthLine, r.name),
            line_distance: Number(r.width)
          }
        }
        if (r.color !== oldObj.color) obj['color'] = r.color
        if (Object.keys(obj).filter((key) => key !== 'id')?.length) updatePayload.push(obj)
      })
      return updatePayload
    },

    async handleDeleteAllRegions() {
      this.workRegionList?.forEach((r) => (r.id ? this.deleteRegionIds.add(r.id) : null))
      const removedCycles = [...this.deleteRegionIds]
      if (!removedCycles?.length) return
      await Promise.all(
        removedCycles.map(async (regionId) => {
          return await this.deleteRegion(regionId)
        })
      )
      await this.updateStation(this.newStudy?.station.id, {
        no_of_work_regions: 0
      })
      this.prevIdToRegionsMap = {}
    },

    handleMarkerMouseDown(key) {
      if (this.processesInProgress) return
      this.selectedRegion = key
      this.isMarkOperator = true
      this.handlers.forEach(({ event, handler }) => {
        document.documentElement.addEventListener(event, handler)
      })
    },

    setWorkAreaCords() {
      if (!this.workRegionList?.length) return
      this.workAreaPoints = this.workRegionList.reduce((res, el) => {
        if (el['work_area_cordinates']?.length)
          res[el.name] = el['work_area_cordinates'].map((p) => this.scale(p))
        return res
      }, {})
    },

    setWorkerCords() {
      if (!this.workRegionList?.length) return
      this.operatorMarker = this.workRegionList.reduce((res, el) => {
        if (el['worker_cordinates']?.x && el['worker_cordinates']?.y)
          res[el.name] = this.scale(el['worker_cordinates'])
        return res
      }, {})
    },

    async runStationModels(updatedData) {
      const handTrackingStatus = this.handTrackingStatus
      const homographyStatus = this.homographyStatus
      // default check status of both if null then run suggestion service
      if (!handTrackingStatus.name && !homographyStatus.name) {
        await this.runImprovementSuggestions()
      }
      // old and new outline are different (Both handTracking & Homography run)
      else if (
        updatedData?.length ||
        !this.prevIdToRegionsMap ||
        !Object.keys(this.prevIdToRegionsMap)?.length
      ) {
        await this.runImprovementSuggestions()
      }
    },

    async runCellModels(updatedData) {
      const operatorTrackingStatus = this.operatorTrackingStatus
      // default check status if null then run suggestion service
      if (!operatorTrackingStatus.name) {
        await this.startOperatorTracking()
      }
      // old and new outline are different
      else if (
        updatedData?.length ||
        !this.prevIdToRegionsMap ||
        !Object.keys(this.prevIdToRegionsMap)?.length
      ) {
        await this.startOperatorTracking()
      }
    },

    async handleCheckServices() {
      const isValid = this.validateRequirments()
      if (!isValid) return
      const updatedData = this.getUpdatedRegions()
      console.log('updated Data: ', updatedData)
      const isSaved = await this.handleSaveAnnotations()
      if (this.isCell) await this.runCellModels(updatedData)
      else await this.runStationModels(updatedData)
      this.nextTab()
    }
  },

  async created() {
    if (this.isDemoOngoing) return
    eventBus.$on('outline-next-page', this.handleCheckServices)
    if (!this.workRegionList.length) await this.fetchWorkRegions()
    this.loading = false
  },

  mounted() {
    if (this.workRegionList?.length) this.renderAnnotations()
    this.setHandlers()
    setTimeout(() => {
      this.$refs.video?.load()
    }, 1000)
  },

  beforeUnmount() {
    this.setOutlineComplete(false)
    this.handleClickStartOver(false)
    eventBus.$off('outline-next-page', this.handleCheckServices)
    this.removeListeners()
  }
}
</script>
<style scoped>
.center-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.multi-regions-container {
  /* max-width: 1024px; */
  /* background: coral; */
}

#frame-slider {
  width: 100%;
}
.content-wrapper {
  /* padding: 20px; */
  display: flex;
  /* background-color: pink; */
  justify-content: center;
  align-items: center;
  /* max-width: ; */
}
.content-body {
  /* padding: 20px; */
  max-width: 660px;
  display: flex;
  /* max-width: ; */
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  gap: 0.5em;
}

.work-area {
  position: relative;
}

.work-area-wrapper {
  background: #fff;
  padding: 10px;
  /* border: 1px solid #f0f0f0; */
  border-radius: 5px;
}

/* .work-area-wrapper:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  } */

.point {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: #f8f9fa;
  border: 1px solid;
  z-index: 1;
  cursor: move;
  transform: translate(-50%, -50%);
}

.line {
  position: absolute;
  height: 2px;
  background: black;
  transform-origin: top left;
}

.line_hover:hover {
  height: 4px;
  cursor: pointer;
}
.selected-line {
  background: #1890ff !important;
  height: 4px !important;
}
.operator_marker {
  position: absolute;
  z-index: 1;
  cursor: move;
}
.marker_icon {
  background: white;
  border-radius: 50%;
  padding: 2px;
}

.slider-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-circle {
  height: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}
</style>
<style>
.work-regions-table.ant-table-wrapper .ant-table-tbody > tr > td,
.work-regions-table.ant-table-wrapper .ant-table-thead > tr > td {
  padding: 16px 6px;
}
</style>
