export default [
  {
    path: '/login',
    name: 'Login',
    component: () => import('src/components/Auth/AppLogin.vue')
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('src/components/Auth/AppSignup.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('src/components/Auth/AppForgotPassword.vue')
  },
  {
    path: '/reset-password/:token',
    name: 'Reset Password',
    component: () => import('src/components/Auth/ResetPassword.vue'),
  },
]
